import { GameType, VideoType } from 'src/constants/types'
import { generateStandardAudio, standardAudioFormReqs } from './standard'
import { FormReqs, InputType } from './types'
import { formatAssetToString, removeExtension } from './utils'

export const videoDuetFormReqs: FormReqs = {
  ...standardAudioFormReqs,
  teacherVideo: {
    label: 'Teacher video',
    inputType: InputType.VIDEO,
  },
}

export const generateVideoDuetJson = (formState: Record<any, any>) => {
  const teacherVideo = formatAssetToString(formState.teacherVideo[0])
  const audio = generateStandardAudio(formState)
  const assetMap = {
    [teacherVideo]: removeExtension(formState.teacherVideo[0].streamingUrl),
  }

  return {
    gameType: GameType.VIDEO_DUET,
    hasReward: false,
    teacherVideo,
    videoType: VideoType.STREAMING,
    assetMap,
    audio,
    videos: {
      mp4s: [teacherVideo],
    },
  }
}
