import { InputType, FormReqs } from './types'
import {
  combineSearchTerms,
  formatAssetToString,
  formatAssetsToStrings,
  getExtension,
  partitionImageTypes,
  removeExtension,
} from './utils'

export const tapCheckCircleChoicesFormReqs: FormReqs = {
  background: {
    label: 'Background image',
    inputType: InputType.IMAGE,
    default: ['image/background_sky.svg'],
  },
  backgroundTopLeft: {
    label: 'Background top left image',
    inputType: InputType.IMAGE,
    default: ['image/cloud.svg'],
  },
  backgroundBottomRight: {
    label: 'Background bottom right image',
    inputType: InputType.IMAGE,
    default: ['image/hot_air_balloon.svg'],
  },
  introAudio: {
    label: 'Intro audio',
    inputType: InputType.AUDIO,
    default: ['audio/boing.mp3'],
  },
  outroAudio: {
    label: 'Outro audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_celebration_cheer.mp3'],
  },
  correctAudio: {
    label: 'Correct audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_ting.mp3'],
  },
  incorrectAudio: {
    label: 'Incorrect audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_incorrect_bloop.mp3'],
  },
  unit1Image: {
    label: 'Unit 1 image',
    inputType: InputType.IMAGE,
  },
  unit1PromptAudio: {
    label: 'Unit 1 prompt audio',
    inputType: InputType.AUDIO,
    default: ['audio/th_where_is_the_phrase.mp3'],
  },
  unit1AcceptAudio: {
    label: 'Unit 1 accept audio',
    inputType: InputType.AUDIO,
    default: ['audio/en_here_is_the_word.mp3'],
  },
  unit1RejectAudio: {
    label: 'Unit 1 reject Audio',
    inputType: InputType.AUDIO,
    default: ['audio/th_no_this_is_the_phrase.mp3'],
  },
  unit1SkillMeasurements: {
    label: 'Unit 1 skill measurements',
    inputType: InputType.SKILL_MEASUREMENTS,
  },
  unit2Image: {
    label: 'Unit 2 image',
    inputType: InputType.IMAGE,
  },
  unit2PromptAudio: {
    label: 'Unit 2 prompt audio',
    inputType: InputType.AUDIO,
    default: ['audio/th_where_is_the_phrase.mp3'],
  },
  unit2AcceptAudio: {
    label: 'Unit 2 accept audio',
    inputType: InputType.AUDIO,
    default: ['audio/en_here_is_the_word.mp3'],
  },
  unit2RejectAudio: {
    label: 'Unit 2 reject Audio',
    inputType: InputType.AUDIO,
    default: ['audio/th_no_this_is_the_phrase.mp3'],
  },
  unit2SkillMeasurements: {
    label: 'Unit 2 skill measurements',
    inputType: InputType.SKILL_MEASUREMENTS,
  },
  unit3Image: {
    label: 'Unit 3 image',
    inputType: InputType.IMAGE,
  },
  unit3PromptAudio: {
    label: 'Unit 3 prompt audio',
    inputType: InputType.AUDIO,
    default: ['audio/th_where_is_the_phrase.mp3'],
  },
  unit3AcceptAudio: {
    label: 'Unit 3 accept audio',
    inputType: InputType.AUDIO,
    default: ['audio/en_here_is_the_word.mp3'],
  },
  unit3RejectAudio: {
    label: 'Unit 3 reject Audio',
    inputType: InputType.AUDIO,
    default: ['audio/th_no_this_is_the_phrase.mp3'],
  },
  unit3SkillMeasurements: {
    label: 'Unit 3 skill measurements',
    inputType: InputType.SKILL_MEASUREMENTS,
  },
  unit4Image: {
    label: 'Unit 4 image',
    inputType: InputType.IMAGE,
  },
  unit4PromptAudio: {
    label: 'Unit 4 prompt audio',
    inputType: InputType.AUDIO,
    default: ['audio/th_where_is_the_phrase.mp3'],
  },
  unit4AcceptAudio: {
    label: 'Unit 4 accept audio',
    inputType: InputType.AUDIO,
    default: ['audio/en_here_is_the_word.mp3'],
  },
  unit4RejectAudio: {
    label: 'Unit 4 reject Audio',
    inputType: InputType.AUDIO,
    default: ['audio/th_no_this_is_the_phrase.mp3'],
  },
  unit4SkillMeasurements: {
    label: 'Unit 4 skill measurements',
    inputType: InputType.SKILL_MEASUREMENTS,
  },
}

export const tapCheckCircleChoicesGenerateJson = (
  formState: Record<keyof typeof tapCheckCircleChoicesFormReqs, any>,
) => {
  const unitImages = [
    formState.unit1Image[0],
    formState.unit2Image[0],
    formState.unit3Image[0],
    formState.unit4Image[0],
  ]
  const [images, jsons] = partitionImageTypes(unitImages)
  return {
    gameType: 'CHECK',
    hasReward: false,
    repeatTappableQuestion: true,
    autoScaleJsonAssets: true,
    units: [
      {
        name: removeExtension(formState.unit1Image[0].name),
        image: formatAssetToString(formState.unit1Image[0]),
        type: getExtension(formState.unit1Image[0].name),
        prompt: combineSearchTerms(formState.unit1PromptAudio),
        accept: combineSearchTerms(formState.unit1AcceptAudio),
        reject: combineSearchTerms(formState.unit1RejectAudio),
        skillMeasurements: [formState.unit1SkillMeasurements],
      },
      {
        name: removeExtension(formState.unit2Image[0].name),
        image: formatAssetToString(formState.unit2Image[0]),
        type: getExtension(formState.unit2Image[0].name),
        prompt: combineSearchTerms(formState.unit2PromptAudio),
        accept: combineSearchTerms(formState.unit2AcceptAudio),
        reject: combineSearchTerms(formState.unit2RejectAudio),
        skillMeasurements: [formState.unit2SkillMeasurements],
      },
      {
        name: removeExtension(formState.unit3Image[0].name),
        image: formatAssetToString(formState.unit3Image[0]),
        type: getExtension(formState.unit3Image[0].name),
        prompt: combineSearchTerms(formState.unit3PromptAudio),
        accept: combineSearchTerms(formState.unit3AcceptAudio),
        reject: combineSearchTerms(formState.unit3RejectAudio),
        skillMeasurements: [formState.unit3SkillMeasurements],
      },
      {
        name: removeExtension(formState.unit4Image[0].name),
        image: formatAssetToString(formState.unit4Image[0]),
        type: getExtension(formState.unit4Image[0].name),
        prompt: combineSearchTerms(formState.unit4PromptAudio),
        accept: combineSearchTerms(formState.unit4AcceptAudio),
        reject: combineSearchTerms(formState.unit4RejectAudio),
        skillMeasurements: [formState.unit4SkillMeasurements],
      },
    ],
    background: {
      main: formatAssetToString(formState.background[0]),
      topLeft: formatAssetToString(formState.backgroundTopLeft[0]),
      bottomRight: formatAssetToString(formState.backgroundBottomRight[0]),
    },
    audio: {
      correct_audio: formatAssetsToStrings(formState.correctAudio),
      incorrect_audio: formatAssetsToStrings(formState.incorrectAudio),
      intro_audio: formatAssetsToStrings(formState.introAudio),
      outro_audio: formatAssetsToStrings(formState.outroAudio),
      [combineSearchTerms(formState.unit1PromptAudio)]: formatAssetsToStrings(
        formState.unit1PromptAudio,
      ),
      [combineSearchTerms(formState.unit1AcceptAudio)]: formatAssetsToStrings(
        formState.unit1AcceptAudio,
      ),
      [combineSearchTerms(formState.unit1RejectAudio)]: formatAssetsToStrings(
        formState.unit1RejectAudio,
      ),
      [combineSearchTerms(formState.unit2PromptAudio)]: formatAssetsToStrings(
        formState.unit2PromptAudio,
      ),
      [combineSearchTerms(formState.unit2AcceptAudio)]: formatAssetsToStrings(
        formState.unit2AcceptAudio,
      ),
      [combineSearchTerms(formState.unit2RejectAudio)]: formatAssetsToStrings(
        formState.unit2RejectAudio,
      ),
      [combineSearchTerms(formState.unit3PromptAudio)]: formatAssetsToStrings(
        formState.unit3PromptAudio,
      ),
      [combineSearchTerms(formState.unit3AcceptAudio)]: formatAssetsToStrings(
        formState.unit3AcceptAudio,
      ),
      [combineSearchTerms(formState.unit3RejectAudio)]: formatAssetsToStrings(
        formState.unit3RejectAudio,
      ),
      [combineSearchTerms(formState.unit4PromptAudio)]: formatAssetsToStrings(
        formState.unit4PromptAudio,
      ),
      [combineSearchTerms(formState.unit4AcceptAudio)]: formatAssetsToStrings(
        formState.unit4AcceptAudio,
      ),
      [combineSearchTerms(formState.unit4RejectAudio)]: formatAssetsToStrings(
        formState.unit4RejectAudio,
      ),
    },
    images: {
      svgs: [
        formatAssetToString(formState.background[0]),
        formatAssetToString(formState.backgroundTopLeft[0]),
        formatAssetToString(formState.backgroundBottomRight[0]),
        ...images,
      ],
    },
    animations: {
      jsons,
    },
  }
}
