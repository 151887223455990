/* eslint-disable comma-dangle */
/* eslint-disable jsx-quotes */
import React, { useEffect, useState } from 'react'
import Select, { ValueType } from 'react-select'
import { Button, Col, Form, Row } from 'react-bootstrap'

import { AssetType } from 'src/constants/types'

type TypeOption = {
  value: AssetType
  label: AssetType
}

const getTypeOptions = (): Array<TypeOption> =>
  Object.values(AssetType).map((type) => ({
    value: type,
    label: type,
  }))

type Props = {
  assetTypeFilter: Array<AssetType>
  resetTable: () => void
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  setAssetTypeFilter: (types: Array<AssetType>) => void
}

export default ({
  assetTypeFilter,
  resetTable,
  searchTerm,
  setSearchTerm,
  setAssetTypeFilter,
}: Props) => {
  const [selectedTypes, setSelectedTypes] = useState<
    ValueType<TypeOption, true>
  >([])

  const onSelectTypeChange = (types: ValueType<TypeOption, true>) => {
    setSelectedTypes(types)
    setAssetTypeFilter(types ? types.map((type: TypeOption) => type.value) : [])
  }

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    if (assetTypeFilter.length === 0) {
      setSelectedTypes([])
    }
  }, [assetTypeFilter])

  return (
    <Row
      style={{
        marginBottom: '1rem',
        justifyContent: 'flex-end',
      }}
    >
      <Col>
        <Button variant="outline-secondary" onClick={resetTable}>
          Reload Table
        </Button>
      </Col>
      <Col md="auto">
        <Form inline>
          <Form.Label>Asset Type: &nbsp;</Form.Label>
          <Select
            clearable
            searchable
            isMulti
            placeholder="All"
            styles={{
              container: (provided) => ({
                ...provided,
                width: 'auto',
                minWidth: 200,
              }),
            }}
            value={selectedTypes}
            options={getTypeOptions()}
            onChange={onSelectTypeChange}
          />
        </Form>
      </Col>
      <Col md="auto">
        <Form inline>
          <Form.Label>Search: &nbsp;</Form.Label>
          <Form.Control
            type="text"
            placeholder="Search Term"
            value={searchTerm}
            onChange={onSearchChange}
          />
        </Form>
      </Col>
    </Row>
  )
}
