import React from 'react'
import { Asset, AssetType } from 'src/constants/types'
import { AutoSuggestFormGroup } from 'src/forms/AutoSuggestFormGroup'
import { InputConfig } from 'src/gameSchemas/types'

interface VideoFormGroupProps {
  formId: string
  inputConfig: InputConfig
  suggestions: Asset[]
  getSuggestions: ({
    query,
    assetTypes,
  }: {
    query: string
    assetTypes: AssetType[]
  }) => Promise<void>
  onSelect: (image: Asset[]) => void
  value: Asset[] | null
}

const VideoFormGroup = (props: VideoFormGroupProps) => {
  const { formId, inputConfig, suggestions, getSuggestions, onSelect, value } =
    props
  return (
    <AutoSuggestFormGroup
      maxTags={1}
      controlId={formId}
      labelColSize={2}
      inputColSize={10}
      label={inputConfig.label}
      assetTypes={[AssetType.VIDEO]}
      suggestions={suggestions}
      onFetchSuggestions={({ query, assetTypes }) =>
        getSuggestions({
          query,
          assetTypes,
        })
      }
      onSelectedSuggestion={onSelect}
      selectedAssets={value}
    />
  )
}

export default VideoFormGroup
