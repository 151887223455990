/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Autosuggest, { ChangeEvent } from 'react-autosuggest'
import TagsInput from 'react-tagsinput'
import ComponentIds from 'src/constants/componentIds'
import { Asset, AssetType } from 'src/constants/types'
import styled from 'styled-components'

const InputLayout = styled.div`
  display: flex;
  flex-direction: row;
`

const Tag = styled.span`
  white-space: nowrap;
`

const AutoSuggestWrapper = styled.div`
  position: relative;

  &.react-autosuggest__container {
    position: absolute;
    bottom: 0;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    left: 0;
    width: 280px;
    border: 1px solid #aaa;
    background-color: white;
    font-size: 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

  .react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
  }

  .react-autosuggest__section-container--first {
    border-top: 0;
  }
`

const AutoSuggestTagsInput = ({
  id,
  assetTypes,
  maxTags,
  selectedAssets,
  suggestions,
  onSelectedSuggestion,
  onFetchSuggestions,
  placeholder,
}: {
  id: string
  assetTypes: AssetType[]
  selectedAssets: Asset[] | null
  suggestions: Asset[]
  onSelectedSuggestion: (suggestion: Asset[]) => void
  onFetchSuggestions: ({
    query,
    assetTypes,
  }: {
    query: string
    assetTypes: AssetType[]
  }) => void
  maxTags?: number
  placeholder?: string
}) => {
  const [tags, setTags] = useState<string[]>([])

  const handleTagsUpdate = (values: string[]) => {
    setTags([...values])
  }

  const handleOnChange = (
    e: React.ChangeEvent<any>,
    params: ChangeEvent,
    props: Omit<TagsInput.RenderInputProps<any>, 'addTag'>,
  ) => {
    if (params.method === 'enter') {
      e.preventDefault()
    } else {
      props.onChange(e)
    }
  }

  return (
    <AutoSuggestWrapper data-testid={ComponentIds.FORM_AUTOSUGGESTINPUT}>
      <TagsInput
        value={tags}
        onChange={handleTagsUpdate}
        renderLayout={(
          tagElements: React.ReactElement[],
          inputElement: React.ReactElement,
        ) => (
          <InputLayout>
            {tagElements}
            {inputElement}
          </InputLayout>
        )}
        renderTag={(props) => {
          const {
            tag,
            key,
            disabled,
            onRemove,
            classNameRemove,
            getTagDisplayValue,
            ...other
          } = props

          const onRemoveTag = () => {
            const selected = selectedAssets
            if (selected && selected.length > 0) {
              selected.splice(key, 1)
              onSelectedSuggestion(selected)
            }
            onRemove(key)
          }

          // TODO: default styling from library, make this nicer
          return (
            <Tag key={key} {...other}>
              {getTagDisplayValue(tag)}
              {!disabled && (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  type="button"
                  className={classNameRemove}
                  onClick={() => onRemoveTag()}
                />
              )}
            </Tag>
          )
        }}
        maxTags={maxTags}
        renderInput={({ addTag, ...props }) => (
          <Autosuggest
            multiSection={false}
            ref={props.ref}
            suggestions={suggestions}
            shouldRenderSuggestions={(inputValue) =>
              Boolean(inputValue && inputValue.trim().length > 0)
            }
            getSuggestionValue={(suggestion) => suggestion.name}
            renderSuggestion={(suggestion) => <div>{suggestion.name}</div>}
            inputProps={{
              ...props,
              id,
              placeholder: placeholder || props.placeholder,
              onChange: (e, params) => handleOnChange(e, params, props),
            }}
            onSuggestionSelected={(e, { suggestion }) => {
              // Need to call addTag here before any early return
              // Otherwise it errors due to maxTags logic within the component
              addTag(suggestion.name)

              if (
                selectedAssets &&
                maxTags &&
                selectedAssets.length >= maxTags
              ) {
                return
              }

              if (!suggestion.url) {
                // show error
                return
              }

              if (selectedAssets) {
                onSelectedSuggestion([...selectedAssets, suggestion])
              } else {
                onSelectedSuggestion([suggestion])
              }
              addTag(suggestion.name)
            }}
            onSuggestionsFetchRequested={({ value }) => {
              onFetchSuggestions({
                query: value,
                assetTypes,
              })
            }}
            onSuggestionsClearRequested={() => {}}
          />
        )}
      />
    </AutoSuggestWrapper>
  )
}

export default AutoSuggestTagsInput
