import React from 'react'

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <div className="logo" />
    <p>&copy; Lingumi 2020</p>
  </footer>
)

export default Footer
