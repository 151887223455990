import React, { FormEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'

import Heading from 'src/components/Heading'
import { get, post } from 'src/api/api'
import { useAuth0 } from '@auth0/auth0-react'
import { BASE_URL, CHINA_BASE_URL } from 'src/constants/env'
import _ from 'lodash'

const StyledButton = styled(Button)`
  display: block;
  margin-bottom: 8px;
`

export enum LessonTestStatus {
  RELEASE_CANDIDATE = 'RELEASE_CANDIDATE',
  STABLE = 'STABLE',
}

const DeployLessonForm = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [internalName, setInternalName] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDeployed, setIsDeployed] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [dots, setDots] = useState<number>(0)

  useEffect(() => {
    // Loading dots animation
    const interval = setInterval(() => {
      setDots((currDots) => {
        if (currDots >= 3) {
          return 0
        }
        return currDots + 1
      })
    }, 200)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleSubmit = async (e: FormEvent) => {
    setIsDeployed(false)
    setIsError(false)
    setIsLoading(true)
    e.preventDefault()
    try {
      const token = await getAccessTokenSilently()

      const result = await get(
        token,
        `${BASE_URL}/test-curriculum/lesson?internal_name=${internalName}`,
      )
      if (!result) {
        setIsError(true)
        setIsLoading(false)
        return
      }
      const createdChina = await post(
        token,
        `${CHINA_BASE_URL}/lessons/create`,
        {
          internalName: result.internalName,
          status: result.status,
          courseId: result.courseId,
          lessonIndex: result.lessonIndex,
          config: result.config,
        },
      )

      // If we fail to deploy lesson in china, fail out and don't try global
      if (!createdChina) {
        setIsError(true)
        setIsLoading(false)
        return
      }
      const createdGlobal = await post(token, `${BASE_URL}/lessons/create`, {
        internalName: result.internalName,
        status: result.status,
        courseId: result.courseId,
        lessonIndex: result.lessonIndex,
        config: result.config,
      })

      if (!createdGlobal) {
        setIsError(true)
        setIsLoading(false)
        return
      }

      setIsLoading(false)
      setIsDeployed(true)
    } catch (e) {
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Heading as="h2">Deploy lesson</Heading>
      <p>
        Please make sure your lesson has the following properties in in the
        &apos;Lesson Builder&apos; Airtable:
      </p>
      <ul>
        <li>
          <b>lesson number</b> (is it the first lesson in the course or the 2nd,
          3rd, 7th?)
        </li>
        <li>
          <b>status</b> (is it a new stable version of the lesson, or a release
          candidate to be tested?)
          <ul>
            <li>
              If testing the new lesson against an existing lesson in an
              existing course, set status to RELEASE_CANDIDATE in Airtable
            </li>
            <li>
              If no lesson exists in this course + lesson index, then set status
              to STABLE in Airtable
            </li>
          </ul>
        </li>
        <li>
          <b>course</b> (which course do we want the course in?)
        </li>
      </ul>
      <Col>
        <Form.Group as={Row} controlId="internal-name">
          <Form.Label column sm="4">
            Lesson internal name
          </Form.Label>
          <Form.Control
            placeholder="Enter the internal name for the lesson you want to deploy"
            onChange={(e) => setInternalName(e.target.value)}
            value={internalName}
            name="internal-name"
          />
        </Form.Group>
      </Col>
      <StyledButton type="submit" disabled={!internalName}>
        Deploy lesson
      </StyledButton>
      {isLoading &&
        `Deploying (may take up to 30 seconds)${_.repeat('.', dots)}`}
      {isDeployed && 'Successfully deployed!'}
      {isError &&
        'Uh oh, there was an error! Double check your data in airtable or tell a dev 😅'}
    </Form>
  )
}

const DeployLesson = () => (
  <Container>
    <DeployLessonForm />
  </Container>
)

export default DeployLesson
