import { Asset, GameType } from 'src/constants/types'
import {
  generateStandardAssetCacheOutput,
  generateStandardJson,
} from './standard'
import { FormReqs, InputType } from './types'
import {
  formatAssetForOutput,
  formatAssetsToStrings,
  formatAssetToString,
} from './utils'

export const zipperPhonicsFormReqs: FormReqs = {
  background: {
    label: 'Background image',
    inputType: InputType.IMAGE,
    default: ['image/background-pattern-blue.svg'],
  },
  variation: {
    label: 'Variation (Does the game use letter assets or unit assets?)',
    inputType: InputType.SELECT,
    options: [
      { label: 'Letters', value: 'LETTERS' },
      { label: 'Units', value: 'UNITS' },
    ],
  },
  questions: {
    label: 'Unit',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      quantity: 2,
      maximum: 5,
      childConfig: {
        inactiveUnit: {
          label: 'Inactive unit',
          inputType: InputType.IMAGE,
        },
        activeUnit: {
          label: 'Active unit',
          inputType: InputType.IMAGE,
        },
        unitAudio: {
          label: 'Unit Audio',
          inputType: InputType.AUDIO,
        },
      },
    },
  },
  finalUnit: {
    label: 'Final Unit',
    inputType: InputType.GROUP,
    groupInputConfig: {
      childConfig: {
        unit: {
          label: 'Unit',
          inputType: InputType.IMAGE,
        },
        unitAudio: {
          label: 'Unit Audio',
          inputType: InputType.AUDIO,
        },
        unitSoundEffect: {
          label: 'Unit Sound Effect',
          inputType: InputType.AUDIO,
        },
      },
    },
  },
  audio: {
    label: 'Game Audio',
    inputType: InputType.GROUP,
    groupInputConfig: {
      childConfig: {
        intro_audio: {
          label: 'Intro audio',
          inputType: InputType.AUDIO,
          default: ['audio/sfx_incorrect_bloop.mp3'],
        },
        outro_audio: {
          label: 'Outro audio',
          inputType: InputType.AUDIO,
          default: ['audio/sfx_celebration_cheer.mp3'],
        },
      },
    },
  },
}

export const generateZipperPhonicsJson = (formState: Record<any, any>) => {
  const questionAudioFiles: Record<string, string[]> = {}

  const standardJson = generateStandardJson(formState)

  const assets = generateStandardAssetCacheOutput(
    zipperPhonicsFormReqs,
    formState,
  )

  const questions = Object.values(formState.questions).map(
    (question: any, index) => {
      const activeUnitAsset = formatAssetForOutput(question.activeUnit[0])
      const inactiveUnitAsset = formatAssetForOutput(question.inactiveUnit[0])

      const questionIndex = index + 1
      const unitAudio = `unit_audio_${questionIndex}`
      questionAudioFiles[unitAudio] = formatAssetsToStrings(question.unitAudio)

      return { activeUnitAsset, inactiveUnitAsset, unitAudio }
    },
  )

  const generalAudioMap: any = {}
  Object.keys(formState.audio.audio1).forEach((audioKey) => {
    const audio: Asset[] = formState.audio.audio1[audioKey]
    generalAudioMap[audioKey] = formatAssetsToStrings(audio)
  })

  const finalUnitAudio = formatAssetToString(
    formState.finalUnit.finalUnit1.unitAudio[0],
  )
  const finalSoundEffect = formatAssetToString(
    formState.finalUnit.finalUnit1.unitSoundEffect[0],
  )
  const finalUnit = formatAssetForOutput(formState.finalUnit.finalUnit1.unit[0])

  return {
    gameType: GameType.ZIPPER_PHONICS,
    ...standardJson,
    variation: formState.variation,
    questions,
    finalUnit: {
      unit: finalUnit,
      audio: 'final_unit_audio',
      soundEffect: 'final_unit_sound_effect',
    },
    audio: {
      ...generalAudioMap,
      ...questionAudioFiles,
      final_unit_audio: [finalUnitAudio],
      final_unit_sound_effect: [finalSoundEffect],
    },
    ...assets,
  }
}
