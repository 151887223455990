import React, { PropsWithChildren } from 'react'
import ComponentIds from 'src/constants/componentIds'
import styled from 'styled-components'

interface Props {
  as: 'h1' | 'h2' | 'h3'
}

const fontSize = {
  h1: '22px',
  h2: '18px',
  h3: '14px',
}

const StyledHeading = styled.h1`
  ${(props: Props) => `font-size: ${fontSize[props.as]}`};
  font-weight: 600;
`

const Heading = (props: PropsWithChildren<Props>) => (
  <StyledHeading as={props.as} data-testid={ComponentIds.COMPONENT_HEADING}>
    {props.children}
  </StyledHeading>
)

export default Heading
