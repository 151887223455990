import { LessonOverviewConfig } from 'src/views/create-lesson/types'
import {
  Asset,
  assetFileType,
  AssetType,
  GameSetup,
  GameType,
  gameTypeToName,
  LessonOverviewMap,
  SentenceConfig,
  SkillMeasurementMap,
  UnitMap,
  WordConfig,
} from '../../constants/types'

const formatAudioName = (assets: Asset[]) =>
  assets.map((asset) => asset.searchTerm).join(' ')

export const formatAssetName = (name: string) =>
  name.replace(/.svg|.mp3|.json/g, '')

export const formatAudioMap = (units: UnitMap, gameSetup: GameSetup) => {
  const audio: { [key: string]: string[] } = {}

  Object.keys(units).forEach((key) => {
    const unit = units[Number(key)]
    audio[`${formatAudioName(unit.acceptPhrase)}`] = getAudioAssetPathList(
      unit.acceptPhrase,
    )
    audio[`${formatAudioName(unit.rejectPhrase)}`] = getAudioAssetPathList(
      unit.rejectPhrase,
    )
    audio[`${formatAudioName(unit.promptPhrase)}`] = getAudioAssetPathList(
      unit.promptPhrase,
    )
  })

  audio.correct_audio = getAudioAssetPathList([gameSetup.acceptSound!])
  audio.incorrect_audio = getAudioAssetPathList([gameSetup.rejectSound!])
  audio.intro_audio = getAudioAssetPathList([gameSetup.introSound!])
  audio.outro_audio = getAudioAssetPathList([gameSetup.outroSound!])

  return audio
}

export const getSvgAndJsonLists = (
  units: UnitMap,
  gameSetup: GameSetup,
): { svgs: string[]; jsons: string[] } => {
  const svgs: string[] = []
  const jsons: string[] = []

  // Add unit images or animations
  Object.keys(units).forEach((key) => {
    const unit = units[Number(key)]
    if (unit.image[0].type === AssetType.IMAGE) {
      svgs.push(`image/${formatAssetName(unit.image[0].name)}`)
    }

    if (unit.image[0].type === AssetType.ANIMATION) {
      jsons.push(`animation/${formatAssetName(unit.image[0].name)}`)
    }
  })

  // Add background images
  svgs.push(`image/${formatAssetName(gameSetup.gameCanvas!.name)}`)
  svgs.push(`image/${formatAssetName(gameSetup.topLeftImage!.name)}`)
  svgs.push(`image/${formatAssetName(gameSetup.bottomRightImage!.name)}`)

  return { svgs, jsons }
}

const getAudioAssetPathList = (assets: Asset[], shouldKeepExt?: boolean) => {
  if (shouldKeepExt) {
    return assets.map((asset) => `audio/${asset.name}`)
  }
  return assets.map((asset) => `audio/${formatAssetName(asset.name)}`)
}

export const getGameNameFromType = (gameType: string) => {
  if (Object.keys(gameTypeToName).includes(gameType)) {
    return gameTypeToName[gameType as keyof typeof gameTypeToName]
  }

  const gameTypeFormatted =
    gameType.substring(0, 1).toUpperCase() + gameType.substring(1).toLowerCase()

  return gameTypeFormatted.replace(/_/g, '-')
}

export const formatBackgroundImage = (asset?: Asset) => {
  if (!asset) {
    return ''
  }
  const imageName = asset.name.replace(/.svg|.json/g, '')
  return `image/${imageName}`
}

export const formatSkillMeasurements = (measurements: SkillMeasurementMap) =>
  Object.keys(measurements).map((k: string) => {
    const key = Number(k)
    const measurement = measurements[key]

    return {
      skill: measurement.skill,
      measurementType: measurement.type,
    }
  })

export const generateRVGJson = (
  units: UnitMap,
  gameSetup: GameSetup | null,
) => {
  if (gameSetup && gameSetup.gameType === GameType.CIRCLE_CHOICES) {
    return {
      gameType: 'CHECK',
      hasReward: false,
      repeatTappableQuestion: true,
      units: Object.keys(units).map((key: string) => {
        const unit = units[Number(key)]
        return {
          name: formatAssetName(unit.image[0].name),
          image: `${unit.image[0].type}/${formatAssetName(unit.image[0].name)}`,
          type: assetFileType[unit.image[0].type as AssetType],
          prompt: formatAudioName(unit.promptPhrase),
          accept: formatAudioName(unit.acceptPhrase),
          reject: formatAudioName(unit.rejectPhrase),
          skillMeasurements: formatSkillMeasurements(unit.skillMeasurements),
        }
      }),
      background: {
        main: formatBackgroundImage(gameSetup.gameCanvas),
        topLeft: formatBackgroundImage(gameSetup.topLeftImage),
        bottomRight: formatBackgroundImage(gameSetup.bottomRightImage),
      },
      audio: formatAudioMap(units, gameSetup),
      images: {
        svgs: getSvgAndJsonLists(units, gameSetup).svgs,
      },
      animations: {
        jsons: getSvgAndJsonLists(units, gameSetup).jsons,
      },
    }
  }

  throw new Error('unexpected game type!')
}

export const getDefaultFourUnits = (firstUnitNumber: number): UnitMap => {
  // Number must start 1, 5, 9 etc to only allow units 1-4, 5-8 etc
  if ((firstUnitNumber - 1) % 4 !== 0) {
    return {}
  }

  const total = firstUnitNumber + 4
  let units = {}
  for (let step = firstUnitNumber; step < total; step++) {
    units = {
      ...units,
      ...{
        [step]: {
          image: [],
          promptPhrase: [],
          acceptPhrase: [],
          rejectPhrase: [],
          correctAnswer: null,
          skillMeasurements: {
            1: {
              skill: null,
              type: null,
            },
          },
        },
      },
    }
  }
  return units
}

export const getDefaultOneUnit = (unitNumber: number): UnitMap => ({
  [unitNumber]: {
    image: [],
    promptPhrase: [],
    acceptPhrase: [],
    rejectPhrase: [],
    correctAnswer: null,
    skillMeasurements: {
      1: {
        skill: null,
        type: null,
      },
    },
    fullMatch: null,
  },
})

const getImageAssetPath = (imageAsset: Asset) => `image/${imageAsset.name}`

const getWordConfigs = (words: LessonOverviewMap): WordConfig[] =>
  Object.keys(words).map((wordKey) => {
    const word = words[Number(wordKey)]
    const imageAsset = word.imageAsset![0]
    return {
      text: word.text,
      audioUrls: getAudioAssetPathList(word.audioAssets, true),
      imageUrl: getImageAssetPath(imageAsset),
    }
  })

const getSentenceConfigs = (sentences: LessonOverviewMap): SentenceConfig[] =>
  Object.keys(sentences).map((sentenceKey) => {
    const sentence = sentences[Number(sentenceKey)]
    return {
      text: sentence.text,
      audioUrls: getAudioAssetPathList(sentence.audioAssets, true),
    }
  })

export const generateLessonOverviewJson = ({
  words,
  sentences,
}: {
  words: LessonOverviewMap
  sentences: LessonOverviewMap
}): LessonOverviewConfig => ({
  words: getWordConfigs(words),
  sentences: getSentenceConfigs(sentences),
})
