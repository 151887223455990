/* eslint-disable comma-dangle */
/* eslint-disable jsx-quotes */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import {
  useTable,
  useSortBy,
  usePagination,
  useAsyncDebounce,
} from 'react-table'

import { Asset, AssetType } from '../../constants/types'
import { FetchData } from '../Assets'
import Body from './Body'
import Footer from './Footer'
import Header from './Header'

const FIRST_PAGE = 0
const DEFAULT_PAGE_SIZE = 10

interface TableProps {
  columns: { Header: string; accessor: keyof Asset }[]
  data: Asset[]
  fetchData: FetchData
  loading: boolean
  controlledPageCount: number
}

export default ({
  columns,
  data,
  fetchData,
  loading,
  controlledPageCount,
}: TableProps) => {
  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    prepareRow,
    previousPage,
    setPageSize,
    state: { pageSize, pageIndex },
  } = useTable<Asset>(
    {
      columns,
      data,
      initialState: {
        pageIndex: FIRST_PAGE,
        pageSize: DEFAULT_PAGE_SIZE,
        sortBy: [{ id: 'createdAt', desc: true }],
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
    },
    useSortBy,
    usePagination,
  )

  const [searchTerm, setSearchTerm] = useState('')
  const [assetTypeFilter, setAssetTypeFilter] = useState<Array<AssetType>>([])

  const debouncedFetch = useAsyncDebounce(fetchData, 250)

  const onReset = () => {
    gotoPage(FIRST_PAGE)
    setPageSize(DEFAULT_PAGE_SIZE)
    setSearchTerm('')
    setAssetTypeFilter([])
  }

  useEffect(() => {
    debouncedFetch(pageSize, pageIndex, searchTerm, assetTypeFilter)
  }, [pageIndex, pageSize, searchTerm, assetTypeFilter])

  useEffect(() => {
    // If the pageCount changes due to a search, and we're on a higher
    // pageIndex than is now available, reset to the first page.
    if (pageIndex + 1 > pageCount) {
      gotoPage(FIRST_PAGE)
    }
  }, [pageCount, pageIndex])

  return (
    <Container>
      <Header
        assetTypeFilter={assetTypeFilter}
        resetTable={onReset}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setAssetTypeFilter={setAssetTypeFilter}
      />
      <Body
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        loading={loading}
        page={page}
        prepareRow={prepareRow}
      />
      <Footer
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        previousPage={previousPage}
        setPageSize={setPageSize}
      />
    </Container>
  )
}
