/** Represents Assets name and type only */
export interface IAsset {
  name: string
  type: AssetType
}

/** Represents an Asset record from db */
export interface Asset extends IAsset {
  id: number
  tags: Tag[]
  file?: File
  url?: string
  uuid?: string
  searchTerm: string
  createdAt?: Date
  streamingVideoId?: string
  streamingUrl?: string
}

export interface Tag {
  id: string
  name: string
}

export enum AssetType {
  AUDIO = 'audio',
  IMAGE = 'image',
  ANIMATION = 'animation',
  VIDEO = 'video',
}

export enum AssetFileType {
  MP3 = 'mp3',
  SVG = 'svg',
  WEBP = 'webp',
  JSON = 'json',
  MP4 = 'mp4',
}

// Note this is no longer necessarily correct for AssetType.IMAGE which can now be 'svg' or 'webp'
export const assetFileType: { [key in AssetType]: string } = {
  [AssetType.AUDIO]: 'mp3',
  [AssetType.IMAGE]: 'svg',
  [AssetType.ANIMATION]: 'json',
  [AssetType.VIDEO]: 'mp4',
}

export enum UnitType {
  UNIT_IMAGE = 'unitImage',
  PROMPT_PHRASE = 'promptPhrase',
  ACCEPT_PHRASE = 'acceptPhrase',
  REJECT_PHRASE = 'rejectPhrase',
  CORRECT_ANSWER = 'correctAnswer',
  SKILL_MEASUREMENTS = 'skillMeasurements',
}

export enum ActivityType {
  RVG = 'rvg',
  VIDEO = 'video',
}

export enum GameType {
  CIRCLE_CHOICES = 'CIRCLE_CHOICES',
  LOAD_VEHICLES = 'LOAD_VEHICLES',
  TAP_REVEAL = 'TAP_REVEAL',
  LABEL_TRANSFORM = 'LABEL_TRANSFORM',
  TAP_VIDEO = 'TAP_VIDEO',
  SMART_CONVERSATION = 'SMART_CONVERSATION',
  TAP_SPEAK = 'TAP_SPEAK',
  THIS_OR_THAT_DRAG_CHECK = 'THIS_OR_THAT_DRAG_CHECK',
  CARD_FLIP_VIDEO_CHECK = 'CARD_FLIP_VIDEO_CHECK',
  CARD_FLIP_DRAG_CHECK = 'CARD_FLIP_DRAG_CHECK',
  PRONUNCIATION_SCORE_SPEAK = 'PRONUNCIATION_SCORE_SPEAK',
  CATCH_DRAG_LABEL = 'CATCH_DRAG_LABEL',
  VIDEO_DUET = 'VIDEO_DUET',
  WHACK_A_MOLE = 'WHACK_A_MOLE',
  INTERACTIVE_STORY_DRAG = 'INTERACTIVE_STORY_DRAG',
  ZIPPER_PHONICS = 'ZIPPER_PHONICS',
  SEGMENT_DRAG_DROP = 'SEGMENT_DRAG_DROP',
  TRACING_PHONICS = 'TRACING_PHONICS',
  CROSS_THE_BRIDGE = 'CROSS_THE_BRIDGE',
}

/* A way to override the name of certain games
(any game not in this list will automatically be formatted) */
export const gameTypeToName = {
  [GameType.CIRCLE_CHOICES]: 'Circle-choices-tap-check',
  [GameType.LOAD_VEHICLES]: 'Load-vehicles-drag-label',
  [GameType.TAP_REVEAL]: 'Hide-and-reveal-tap-label',
  [GameType.LABEL_TRANSFORM]: 'Transform-drag-label',
  [GameType.TAP_VIDEO]: 'Interactive-video-tap-label-or-check',
  [GameType.TAP_SPEAK]: 'Four-cards-tap-speak',
  [GameType.CARD_FLIP_DRAG_CHECK]:
    'Card-flip-drag-check (WIP - DO NOT USE IN LIVE LESSONS)',
  [GameType.INTERACTIVE_STORY_DRAG]: 'Drag-drop-interactive-story-drag',
  [GameType.CROSS_THE_BRIDGE]: 'Cross-the-bridge',
}

export enum SkillMeasurementType {
  EXPOSED_TO = 'EXPOSED_TO',
  SELECTED = 'SELECTED',
  COPIED = 'COPIED',
  RESPONDED_WITH = 'RESPONDED_WITH',
}

export interface SkillMeasurement {
  type: SkillMeasurementType | null
  skill: string | null
}

export interface SkillMeasurementMap {
  [key: number]: SkillMeasurement
}

export interface Unit {
  image: Asset[]
  promptPhrase: Asset[]
  acceptPhrase: Asset[]
  rejectPhrase: Asset[]
  correctAnswer: boolean | null
  fullMatch: string | null
  skillMeasurements: SkillMeasurementMap
}

export interface UnitMap {
  [key: number]: Unit
}

// This needs to match the SpeechRecognitionType defined in the lingumi-app and on lingumi-learning
export enum SpeechRecognitionType {
  WORD = 'WORD',
  PHONEME = 'PHONEME',
}

export interface GameSetup {
  gameType?: GameType
  gameCanvas?: Asset
  topLeftImage?: Asset
  bottomRightImage?: Asset
  introSound?: Asset
  outroSound?: Asset
  acceptSound?: Asset
  rejectSound?: Asset
}

export enum LessonOverviewAssetType {
  SENTENCE = 'sentence',
  WORD = 'word',
}

export interface LessonOverviewAssets {
  type: LessonOverviewAssetType
  text: string
  audioAssets: Asset[]
  imageAsset?: Asset[]
}

export interface LessonOverviewMap {
  [key: number]: LessonOverviewAssets
}

export interface WordConfig {
  text: string
  audioUrls: string[]
  imageUrl: string
}

export interface SentenceConfig {
  text: string
  audioUrls: string[]
}

export enum VideoType {
  AMS = 'AMS',
  LEGACY = 'LEGACY',
  STREAMING = 'STREAMING',
}
