import { Asset } from 'src/constants/types'
import {
  renderDateCell,
  renderFileCell,
  RenderFunction,
  renderTagsCell,
} from './cellRenderers'

type Column = {
  Header: string
  accessor: keyof Asset
  sortType?: string
  Cell?: RenderFunction
  disableSortBy?: boolean
}

const columns: Column[] = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Added',
    accessor: 'createdAt',
    sortType: 'datetime',
    Cell: renderDateCell,
  },
  {
    Header: 'File',
    accessor: 'url',
    disableSortBy: true,
    Cell: renderFileCell,
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Tags',
    accessor: 'tags',
    Cell: renderTagsCell,
  },
  {
    Header: 'Search Term',
    accessor: 'searchTerm',
  },
]

export default columns
