import { GameType, VideoType } from 'src/constants/types'
import {
  generateStandardJson,
  localeInput,
  speechRecognitionTypeInput,
  standardFormReqs,
} from './standard'
import { InputType } from './types'
import {
  formatAssetForOutput,
  formatAssetsToStrings,
  formatAssetToString,
  partitionImageTypesIncludingWebp,
  removeExtension,
} from './utils'

export const getPronunciationScoreSpeakFormReqs = {
  locale: localeInput,
  speechRecognitionType: speechRecognitionTypeInput,
  ...standardFormReqs,
  background: {
    label: 'Background image',
    inputType: InputType.IMAGE,
    default: ['image/background-pattern-blue.svg'],
  },
  questions: {
    label: 'Unit to be scored',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      childConfig: {
        unit: {
          label: 'Image',
          inputType: InputType.IMAGE,
        },
        requiredAnswer: {
          label: 'Required answer',
          inputType: InputType.TEXT_INPUT,
        },
        teacherVideo: {
          label: 'Teacher video',
          inputType: InputType.VIDEO,
        },
        timeoutInSeconds: {
          label: 'Timeout Time (in seconds)',
          inputType: InputType.NUMBER,
          default: ['4'],
        },
      },
      quantity: 1,
      maximum: 1,
    },
  },
  errorVideo: {
    label: 'Error video',
    inputType: InputType.VIDEO,
  },
  correctAudio: {
    label: 'Correct audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_ting.mp3'],
  },
  incorrectAudio: {
    label: 'Incorrect audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_incorrect_bloop.mp3'],
  },
}

export const generatePronunciationScoreSpeakJson = (
  formState: Record<any, any>,
) => {
  const standardConfig = generateStandardJson(formState)

  const questions = Object.values(formState.questions).map((question: any) => ({
    unit: formatAssetForOutput(question.unit[0]),
    requiredAnswer: question.requiredAnswer,
    timeoutInSeconds: question.timeoutInSeconds,
    teacherVideo: formatAssetToString(question.teacherVideo[0]),
  }))

  const [unitSvgsAssets, unitJsonsAssets, _, unitWebpsAssets] =
    partitionImageTypesIncludingWebp(
      Object.values(formState.questions)
        .map((question: any) => question.unit[0])
        .flat(),
    )

  const teacherVideos = Object.values(formState.questions).map(
    (unitData: any) => unitData.teacherVideo[0],
  )

  const unitSvgs = formatAssetsToStrings(unitSvgsAssets)
  const unitJsons = formatAssetsToStrings(unitJsonsAssets)
  const webps = formatAssetsToStrings(unitWebpsAssets)
  const mp4s = formatAssetsToStrings([
    ...teacherVideos,
    formState.errorVideo[0],
  ])

  const allVideoFiles = [formState.errorVideo[0], ...teacherVideos]

  const assetMap = allVideoFiles.reduce(
    (acc, v) => ({
      ...acc,
      [formatAssetToString(v)]: removeExtension(v.streamingUrl),
    }),
    {},
  )

  return {
    locale: formState.locale || 'en-US',
    speechRecognitionType: formState.speechRecognitionType,
    gameType: GameType.PRONUNCIATION_SCORE_SPEAK,
    videoType: VideoType.STREAMING,
    ...standardConfig,
    assetMap,
    questions,
    errorVideoUrl: formatAssetToString(formState.errorVideo[0]),
    audio: {
      ...standardConfig.audio,
      correct_audio: formatAssetsToStrings(formState.correctAudio),
      incorrect_audio: formatAssetsToStrings(formState.incorrectAudio),
    },
    images: {
      svgs: [...unitSvgs, ...standardConfig.images.svgs],
      webps,
    },
    animations: {
      jsons: unitJsons,
    },
    videos: { mp4s },
  }
}
