import React from 'react'
import AudioPlayer from 'src/components/AudioPlayer'
import Heading from 'src/components/Heading'
import { Asset } from 'src/constants/types'

export const TitledAudioPlayer = ({
  title,
  assets,
}: {
  title: string
  assets: Array<Asset | undefined>
}) => {
  if (!assets || assets.length === 0 || assets.some((a) => a === undefined)) {
    return null
  }

  return (
    <div style={{ marginBottom: '8px' }}>
      <Heading as="h3">{title}</Heading>
      <AudioPlayer
        audioUrls={assets.map((asset) => asset!.url!)}
        width="300px"
        height="34px"
      />
    </div>
  )
}
