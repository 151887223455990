import { GameType, VideoType } from 'src/constants/types'
import {
  correctAudioInput,
  generateStandardAssetCacheOutput,
  generateStandardAudio,
  generateStandardJson,
  incorrectAudioInput,
  standardFormReqs,
} from './standard'
import { FormReqs, InputType } from './types'
import { formatAssetForOutput, removeExtension } from './utils'

export const dragDropInteractiveStoryFormReqs: FormReqs = {
  ...standardFormReqs,
  correct_audio: correctAudioInput,
  incorrect_audio: incorrectAudioInput,
  introVideoUrl: {
    label: 'Intro video',
    inputType: InputType.VIDEO,
  },
  idleVideoUrl: {
    label: 'Idle video',
    inputType: InputType.VIDEO,
  },
  outroVideoUrl: {
    label: 'Outro video',
    inputType: InputType.VIDEO,
  },
  dropzone: {
    label: 'Dropzone',
    inputType: InputType.SELECT,
    options: [
      { label: 'Left', value: 'LEFT' },
      { label: 'Bottom right', value: 'BOTTOM_RIGHT' },
    ],
  },
  unit: {
    label: 'Unit',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      childConfig: {
        name: { label: 'Name (Unique)', inputType: InputType.TEXT_INPUT },
        image: {
          label: 'Image',
          inputType: InputType.IMAGE,
        },
        reactionVideo: {
          label: 'Reaction video',
          inputType: InputType.VIDEO,
        },
      },
      quantity: 1,
      maximum: 6,
    },
  },
}

export const generateDragDropInteractiveStoryJson = (
  formState: Record<any, any>,
) => {
  const standardConfig = generateStandardJson(formState)
  const audio = generateStandardAudio(formState)

  const units: any[] = []

  const unitsAssetMap: any = {}

  Object.keys(formState.unit).forEach((unitKey) => {
    const unit = formState.unit[unitKey]

    const reactionVideoName = `${unit.name}ReactionVideo`
    units.push({
      ...formatAssetForOutput(unit.image[0], unit.name),
      reactionVideoUrl: reactionVideoName,
    })

    unitsAssetMap[reactionVideoName] = removeExtension(
      unit.reactionVideo[0].streamingUrl,
    )
  })

  const assets = generateStandardAssetCacheOutput(
    dragDropInteractiveStoryFormReqs,
    formState,
  )

  const newAssetMap = { ...assets.assetMap, ...unitsAssetMap }

  assets.assetMap = newAssetMap

  return {
    gameType: GameType.INTERACTIVE_STORY_DRAG,
    ...standardConfig,
    hasReward: false,
    videoType: VideoType.STREAMING,
    activeDropzone: formState.dropzone,
    units,
    audio,
    ...assets,
  }
}
