import React from 'react'
import { UnitMap, GameSetup } from 'src/constants/types'
import { getDefaultFourUnits, getDefaultOneUnit } from 'src/views/utils/helpers'

export interface RvgConfigContextType {
  units: UnitMap
  setUnits: React.Dispatch<React.SetStateAction<UnitMap>>
  addMoreUnits: (numberToAdd: 1 | 4) => void
  gameSetup: GameSetup | null
  setGameSetup: React.Dispatch<React.SetStateAction<GameSetup | null>>
}

export const RvgConfigContext =
  React.createContext<RvgConfigContextType | null>(null)

export default ({ children }: React.PropsWithChildren<any>) => {
  const [units, setUnits] = React.useState<UnitMap>(getDefaultFourUnits(1))
  const [gameSetup, setGameSetup] = React.useState<GameSetup | null>(null)

  const addMoreUnits = (numberToAdd: 1 | 4) => {
    const lastUnitNumber = Object.keys(units).length
    const newUnits =
      numberToAdd === 4
        ? getDefaultFourUnits(lastUnitNumber + 1)
        : getDefaultOneUnit(lastUnitNumber + 1)

    setUnits({ ...units, ...newUnits })
  }

  const store: RvgConfigContextType = {
    units,
    setUnits,
    addMoreUnits,
    gameSetup,
    setGameSetup,
  }

  return (
    <RvgConfigContext.Provider value={store}>
      {children}
    </RvgConfigContext.Provider>
  )
}
