import _ from 'lodash'
import { InputType, FormReqs } from './types'
import {
  combineSearchTerms,
  formatAssetToString,
  formatAssetsToStrings,
  getExtension,
  partitionImageTypes,
  removeExtension,
} from './utils'

export const loadVehiclesFormReqs: FormReqs = {
  unit1Image: {
    label: 'Unit 1 image',
    inputType: InputType.IMAGE,
  },
  unit1OnDragAudio: {
    label: 'Unit 1 Audio',
    inputType: InputType.AUDIO,
  },
  unit1SkillMeasurements: {
    label: 'Unit 1 skill measurements',
    inputType: InputType.SKILL_MEASUREMENTS,
  },
  unit2Image: {
    label: 'Unit 2 image',
    inputType: InputType.IMAGE,
  },
  unit2OnDragAudio: {
    label: 'Unit 2 Audio',
    inputType: InputType.AUDIO,
  },
  unit2SkillMeasurements: {
    label: 'Unit 2 skill measurements',
    inputType: InputType.SKILL_MEASUREMENTS,
  },
  unit3Image: {
    label: 'Unit 3 image',
    inputType: InputType.IMAGE,
  },
  unit3OnDragAudio: {
    label: 'Unit 3 Audio',
    inputType: InputType.AUDIO,
  },
  unit3SkillMeasurements: {
    label: 'Unit 3 skill measurements',
    inputType: InputType.SKILL_MEASUREMENTS,
  },
  unit4Image: {
    label: 'Unit 4 image',
    inputType: InputType.IMAGE,
  },
  unit4OnDragAudio: {
    label: 'Unit 4 Audio',
    inputType: InputType.AUDIO,
  },
  unit4SkillMeasurements: {
    label: 'Unit 4 skill measurements',
    inputType: InputType.SKILL_MEASUREMENTS,
  },
}

export const loadVehiclesGenerateJson = (
  formState: Record<keyof typeof loadVehiclesFormReqs, any>,
) => {
  const unitImages = [
    formState.unit1Image[0],
    formState.unit2Image[0],
    formState.unit3Image[0],
    formState.unit4Image[0],
  ]
  const [images, jsons] = partitionImageTypes(unitImages)
  return {
    gameType: 'LOAD_VEHICLES',
    hasReward: false,
    repeatTappableQuestion: true,
    autoScaleJsonAssets: true,
    units: [
      {
        name: removeExtension(formState.unit1Image[0].name),
        image: formatAssetToString(formState.unit1Image[0]),
        type: getExtension(formState.unit1Image[0].name),
        onDragAudio: combineSearchTerms(formState.unit1OnDragAudio),
        skillMeasurements: [formState.unit1SkillMeasurements],
      },
      {
        name: removeExtension(formState.unit2Image[0].name),
        image: formatAssetToString(formState.unit2Image[0]),
        type: getExtension(formState.unit2Image[0].name),
        onDragAudio: combineSearchTerms(formState.unit2OnDragAudio),
        skillMeasurements: [formState.unit2SkillMeasurements],
      },
      {
        name: removeExtension(formState.unit3Image[0].name),
        image: formatAssetToString(formState.unit3Image[0]),
        type: getExtension(formState.unit3Image[0].name),
        onDragAudio: combineSearchTerms(formState.unit3OnDragAudio),
        skillMeasurements: [formState.unit3SkillMeasurements],
      },
      {
        name: removeExtension(formState.unit4Image[0].name),
        image: formatAssetToString(formState.unit4Image[0]),
        type: getExtension(formState.unit4Image[0].name),
        onDragAudio: combineSearchTerms(formState.unit4OnDragAudio),
        skillMeasurements: [formState.unit4SkillMeasurements],
      },
    ],
    background: {
      main: 'image/city_background',
    },
    audio: {
      [combineSearchTerms(formState.unit1OnDragAudio)]: formatAssetsToStrings(
        formState.unit1OnDragAudio,
      ),
      [combineSearchTerms(formState.unit2OnDragAudio)]: formatAssetsToStrings(
        formState.unit2OnDragAudio,
      ),
      [combineSearchTerms(formState.unit3OnDragAudio)]: formatAssetsToStrings(
        formState.unit3OnDragAudio,
      ),
      [combineSearchTerms(formState.unit4OnDragAudio)]: formatAssetsToStrings(
        formState.unit4OnDragAudio,
      ),
      correct_audio: ['audio/sfx_short_ting'],
      intro_audio: ['audio/sfx_car_drive_and_beep'],
      outro_audio: ['audio/sfx_celebration_cheer'],
      sfx_engine_idle: ['audio/sfx_engine_idle'],
      sfx_car_driving_away: ['audio/sfx_sfx_car_driving_away'],
    },
    images: {
      svgs: [...images, 'image/city_background'],
    },
    animations: {
      jsons,
    },
  }
}
