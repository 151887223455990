/* eslint-disable jsx-quotes */
import React from 'react'
import { Col, Form, Pagination, Row } from 'react-bootstrap'

type Props = {
  canNextPage: boolean
  canPreviousPage: boolean
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void
  nextPage: () => void
  pageCount: number
  pageIndex: number
  pageSize: number
  previousPage: () => void
  setPageSize: (pageSize: number) => void
}

const getPageSizeOptions = () =>
  [10, 25, 50, 100].map((pageSize) => (
    <option key={pageSize} value={pageSize}>
      {pageSize}
    </option>
  ))

export default ({
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageCount,
  pageIndex,
  pageSize,
  previousPage,
  setPageSize,
}: Props) => (
  <Row>
    <Col>
      <Pagination>
        <Pagination.First
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        />
        <Pagination.Prev
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        />

        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
        <Pagination.Last
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        />
      </Pagination>
    </Col>
    <Col className="text-center align-content-center">
      <span>
        Page&nbsp;
        <strong>
          {pageIndex + 1} of {pageCount}
        </strong>
      </span>
    </Col>
    <Col>
      <Form inline className="justify-content-end">
        <Form.Label>Assets per Page: &nbsp;</Form.Label>
        <Form.Control
          as="select"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
        >
          {getPageSizeOptions()}
        </Form.Control>
      </Form>
    </Col>
  </Row>
)
