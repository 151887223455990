import {
  cardInput,
  generateStandardJson,
  localeInput,
  speechRecognitionTypeInput,
  standardFormReqs,
} from './standard'
import { FormReqs, InputType } from './types'
import {
  formatAssetForOutput,
  formatAssetsToStrings,
  formatAssetToString,
  partitionImageTypesIncludingWebp,
} from './utils'

export const videoPracticeCardFlipFormReqs: FormReqs = {
  locale: localeInput,
  speechRecognitionType: speechRecognitionTypeInput,
  ...standardFormReqs,
  background: {
    label: 'Background',
    inputType: InputType.IMAGE,
    default: ['image/ui_video_practice_background_blue.svg'],
  },
  unit: {
    label: 'Card',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      childConfig: {
        image: { label: 'Unit', inputType: InputType.IMAGE },
        requiredAnswer: { label: 'Answer', inputType: InputType.TEXT_INPUT },
        timeoutInSeconds: {
          label: 'Time between card flip and game end (seconds)',
          inputType: InputType.NUMBER,
          default: ['5'],
        },
      },
      quantity: 1,
      maximum: 25,
    },
  },
  card: cardInput,
  character: {
    label: 'Character',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      childConfig: {
        character: {
          label: 'Character',
          inputType: InputType.SELECT,
          options: [
            { label: 'Peek', value: 'peek' },
            { label: 'Yum', value: 'yum' },
            { label: 'Hush', value: 'hush' },
            { label: 'Boo', value: 'boo' },
          ],
        },
      },
      quantity: 2,
      maximum: 2,
    },
  },
}

export const generateJsonVideoPracticeCardFlip = (
  formState: Record<string, any>,
) => {
  const standardConfig = generateStandardJson(formState)

  const questions = Object.values(formState.unit).map((unit: any) => ({
    unit: formatAssetForOutput(unit.image[0]),
    requiredAnswer: unit.requiredAnswer,
    timeoutInSeconds: Number(unit.timeoutInSeconds),
  }))

  const [unitSvgsAssets, unitJsonsAssets] = partitionImageTypesIncludingWebp(
    Object.values(formState.unit)
      .map((unit: any) => unit.image[0])
      .flat(),
  )

  const unitSvgs = formatAssetsToStrings(unitSvgsAssets)
  const unitJsons = formatAssetsToStrings(unitJsonsAssets)

  const card = {
    backFace: formatAssetToString(formState.card.card1.backFace[0]),
    frontFace: formatAssetToString(formState.card.card1.frontFace[0]),
  }

  const characterCelebrations = Object.values(formState.character).map(
    (character: any) => {
      switch (character.character) {
        case 'peek':
          return 'animation/anim_peek_dancing_shimmy'
        case 'yum':
          return 'animation/anim_yum_dancing_shimmy'
        case 'hush':
          return 'animation/anim_hush_dancing_shimmy'
        case 'boo':
          return 'animation/anim_boo_dancing_shimmy'
        default:
          return 'animation/anim_peek_dancing_shimmy'
      }
    },
  )

  const svgs = [
    formatAssetToString(formState.background[0]),
    card.backFace,
    card.frontFace,
    ...unitSvgs,
  ]

  const jsons = [...characterCelebrations, ...unitJsons]

  return {
    locale: formState.locale || 'en-US',
    speechRecognitionType: formState.speechRecognitionType,
    gameType: 'CARD_FLIP_VIDEO_CHECK',
    ...standardConfig,
    hasReward: false,
    questions,
    card,
    characterCelebrations,
    images: {
      svgs,
    },
    animations: {
      jsons,
    },
  }
}
