import { Asset } from 'src/constants/types'
import {
  cardInput,
  correctAudioInput,
  generateStandardJson,
  incorrectAudioInput,
  standardFormReqs,
} from './standard'
import { FormReqs, InputType } from './types'
import {
  formatAssetForOutput,
  formatAssetsToStrings,
  formatAssetToString,
  partitionImageTypesIncludingWebp,
} from './utils'

export const cardFlipDragCheckFormReqs: FormReqs = {
  ...standardFormReqs,
  correct_audio: correctAudioInput,
  incorrect_audio: incorrectAudioInput,
  card: cardInput,
  question: {
    label: 'Question',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      quantity: 4,
      maximum: 4,
      childConfig: {
        unit: { label: 'Unit', inputType: InputType.IMAGE },
        prompt: {
          label: 'Prompt',
          inputType: InputType.AUDIO,
        },
        accept: {
          label: 'Accept',
          inputType: InputType.AUDIO,
        },
        reject: {
          label: 'Reject',
          inputType: InputType.AUDIO,
        },
      },
    },
  },
}

export const generateJsonCardFlipDragCheck = (
  formState: Record<string, any>,
) => {
  const standardConfig = generateStandardJson(formState)

  const card = {
    backFace: formatAssetToString(formState.card.card1.backFace[0]),
    frontFace: formatAssetToString(formState.card.card1.frontFace[0]),
  }

  const unitAssets: Asset[] = []

  const questionAudioFiles: Record<string, string[]> = {}

  const questions = Object.values(formState.question).map(
    (question: any, index) => {
      const unit = formatAssetForOutput(question.unit[0])

      unitAssets.push(question.unit[0])

      const questionIndex = index + 1

      const prompt = `prompt_audio_${questionIndex}`
      const accept = `accept_audio_${questionIndex}`
      const reject = `reject_audio_${questionIndex}`

      questionAudioFiles[prompt] = formatAssetsToStrings(question.prompt)
      questionAudioFiles[accept] = formatAssetsToStrings(question.accept)
      questionAudioFiles[reject] = formatAssetsToStrings(question.reject)

      return { unit, prompt, accept, reject }
    },
  )

  const [unitSvgsAssets, unitJsonsAssets] =
    partitionImageTypesIncludingWebp(unitAssets)

  const unitSvgs = formatAssetsToStrings(unitSvgsAssets)
  const unitJsons = formatAssetsToStrings(unitJsonsAssets)

  const svgs = [
    ...standardConfig.images.svgs,
    card.backFace,
    card.frontFace,
    ...unitSvgs,
  ]

  const jsons = [...unitJsons]

  const animations =
    jsons.length > 0
      ? {
          animations: {
            jsons,
          },
        }
      : {}

  return {
    gameType: 'CARD_FLIP_DRAG_CHECK',
    ...standardConfig,
    hasReward: false,
    card,
    questions,
    audio: {
      ...standardConfig.audio,
      ...questionAudioFiles,
    },
    images: {
      ...standardConfig.images,
      svgs,
    },
    ...animations,
  }
}
