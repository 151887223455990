import React, { useContext } from 'react'
import { AssetType, GameSetup, GameType } from 'src/constants/types'
import { Col, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'
import Heading from 'src/components/Heading'
import Line from 'src/components/Line'
import { AutoSuggestFormGroup } from 'src/forms/AutoSuggestFormGroup'

import { useSuggestions } from '../utils/hooks'
import { RvgConfigContext, RvgConfigContextType } from './context'
import { getGameNameFromType } from '../utils/helpers'

export const GameTypeSelect = (props: {
  gameSetup: GameSetup | null
  setGameSetup: React.Dispatch<React.SetStateAction<GameSetup | null>>
}) => {
  const handleGameTypeChange = (e: React.ChangeEvent<any>) => {
    const { value } = e.target
    props.setGameSetup({
      ...props.gameSetup,
      gameType: value,
    })
  }
  return (
    <Form.Group as={Row} controlId="gameType">
      <Form.Label column sm="4">
        Game type
      </Form.Label>
      <Col sm="8">
        <Form.Control
          as="select"
          onChange={handleGameTypeChange}
          value={props.gameSetup ? props.gameSetup.gameType : ''}
          name="gameType"
        >
          <option value="">--Choose an option--</option>
          {Object.values(GameType).map((gameType) => (
            <option value={gameType}>
              {getGameNameFromType(gameType as GameType)}
            </option>
          ))}
        </Form.Control>
      </Col>
    </Form.Group>
  )
}

const FormGroup = ({
  type,
}: {
  type: 'GAME_SETUP' | 'GAME_SOUNDS' | 'GAME_INFO'
}) => {
  const { suggestions, getSuggestions } = useSuggestions()
  const { gameSetup, setGameSetup } = useContext(
    RvgConfigContext,
  ) as RvgConfigContextType

  switch (type) {
    case 'GAME_INFO':
      return (
        <GameTypeSelect gameSetup={gameSetup} setGameSetup={setGameSetup} />
      )
    case 'GAME_SETUP':
      return (
        <>
          <AutoSuggestFormGroup
            controlId="addCanvas"
            label="Game canvas"
            assetTypes={[AssetType.IMAGE]}
            suggestions={suggestions}
            onFetchSuggestions={({ query, assetTypes }) =>
              getSuggestions({
                query,
                assetTypes,
              })
            }
            onSelectedSuggestion={(asset) => {
              setGameSetup({
                ...gameSetup,
                gameCanvas: asset[0],
              })
            }}
            selectedAssets={
              gameSetup && gameSetup.gameCanvas ? [gameSetup.gameCanvas] : []
            }
            maxTags={1}
          />
          <AutoSuggestFormGroup
            controlId="addTopLeft"
            label="Top left image"
            assetTypes={[AssetType.IMAGE]}
            suggestions={suggestions}
            onFetchSuggestions={({ query, assetTypes }) =>
              getSuggestions({
                query,
                assetTypes,
              })
            }
            onSelectedSuggestion={(asset) => {
              setGameSetup({
                ...gameSetup,
                topLeftImage: asset[0],
              })
            }}
            selectedAssets={
              gameSetup && gameSetup.topLeftImage
                ? [gameSetup.topLeftImage]
                : []
            }
            maxTags={1}
          />
          <AutoSuggestFormGroup
            controlId="addBottomRight"
            label="Bottom right image"
            assetTypes={[AssetType.IMAGE]}
            suggestions={suggestions}
            onFetchSuggestions={({ query, assetTypes }) =>
              getSuggestions({
                query,
                assetTypes,
              })
            }
            onSelectedSuggestion={(asset) => {
              setGameSetup({
                ...gameSetup,
                bottomRightImage: asset[0],
              })
            }}
            selectedAssets={
              gameSetup && gameSetup.bottomRightImage
                ? [gameSetup.bottomRightImage]
                : []
            }
            maxTags={1}
          />
        </>
      )
    case 'GAME_SOUNDS':
      return (
        <>
          <AutoSuggestFormGroup
            controlId="addIntroSound"
            label="Intro sound"
            assetTypes={[AssetType.AUDIO]}
            suggestions={suggestions}
            onFetchSuggestions={({ query, assetTypes }) =>
              getSuggestions({
                query,
                assetTypes,
              })
            }
            onSelectedSuggestion={(asset) => {
              setGameSetup({
                ...gameSetup,
                introSound: asset[0],
              })
            }}
            selectedAssets={
              gameSetup && gameSetup.introSound ? [gameSetup.introSound] : []
            }
            maxTags={1}
          />
          <AutoSuggestFormGroup
            controlId="addOutroSound"
            label="Outro sound"
            assetTypes={[AssetType.AUDIO]}
            suggestions={suggestions}
            onFetchSuggestions={({ query, assetTypes }) =>
              getSuggestions({
                query,
                assetTypes,
              })
            }
            onSelectedSuggestion={(asset) => {
              setGameSetup({
                ...gameSetup,
                outroSound: asset[0],
              })
            }}
            selectedAssets={
              gameSetup && gameSetup.outroSound ? [gameSetup.outroSound] : []
            }
            maxTags={1}
          />
          <AutoSuggestFormGroup
            controlId="addAcceptSound"
            label="Accept sound"
            assetTypes={[AssetType.AUDIO]}
            suggestions={suggestions}
            onFetchSuggestions={({ query, assetTypes }) =>
              getSuggestions({
                query,
                assetTypes,
              })
            }
            onSelectedSuggestion={(asset) => {
              setGameSetup({
                ...gameSetup,
                acceptSound: asset[0],
              })
            }}
            selectedAssets={
              gameSetup && gameSetup.acceptSound ? [gameSetup.acceptSound] : []
            }
            maxTags={1}
          />
          <AutoSuggestFormGroup
            controlId="addRejectSound"
            label="Reject sound"
            assetTypes={[AssetType.AUDIO]}
            suggestions={suggestions}
            onFetchSuggestions={({ query, assetTypes }) =>
              getSuggestions({
                query,
                assetTypes,
              })
            }
            onSelectedSuggestion={(asset) => {
              setGameSetup({
                ...gameSetup,
                rejectSound: asset[0],
              })
            }}
            selectedAssets={
              gameSetup && gameSetup.rejectSound ? [gameSetup.rejectSound] : []
            }
            maxTags={1}
          />
        </>
      )
    default:
      return <></>
  }
}

const FormContainer = styled.div`
  margin-bottom: 16px;

  .react-tagsinput {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
`

const GameSetupForm = () => (
  <FormContainer>
    <Heading as="h2">Set up game info</Heading>
    <Line />
    <FormGroup type="GAME_INFO" />
  </FormContainer>
)

export default GameSetupForm
