import _ from 'lodash'
import { InputType, FormReqs } from './types'
import {
  formatAssetToString,
  formatAssetsToStrings,
  getExtension,
  partitionImageTypes,
  removeExtension,
} from './utils'

export const labelTransformFormReqs: FormReqs = {
  background: {
    label: 'Background image',
    inputType: InputType.IMAGE,
    default: ['image/background_outside_park_with_path.svg'],
  },
  unitToTransform: {
    label: 'Character',
    inputType: InputType.GROUP,
    groupInputConfig: {
      childConfig: {
        introState: {
          label: 'Intro state',
          inputType: InputType.IMAGE,
          default: ['animation/anim_boo_fall_and_land.json'],
        },
        idleState: {
          label: 'Idle state',
          inputType: InputType.IMAGE,
          default: ['animation/anim_boo_idle.json'],
        },
        receiveState: {
          label: 'Receive state (while item is being dragged)',
          inputType: InputType.IMAGE,
          default: ['animation/anim_boo_shaking_hands_over_eyes.json'],
        },
        preTransformState: {
          label: 'Pre-transform state (when item is dropped)',
          inputType: InputType.IMAGE,
          default: ['animation/anim_boo_shocked.json'],
        },
        postTransformState: {
          label:
            'Post-transform state (after tranforming back into original state)',
          inputType: InputType.IMAGE,
          default: ['animation/anim_boo_wipe_sweat_from_head.json'],
        },
        outroState: {
          label: 'Outro state (after all items completed)',
          inputType: InputType.IMAGE,
          default: ['animation/anim_boo_jump_and_celebrate.json'],
        },
      },
    },
  },

  unit: {
    label: 'Unit',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      childConfig: {
        image: {
          label: 'Image',
          inputType: InputType.IMAGE,
        },
        acceptAudio: {
          label: 'Audio',
          inputType: InputType.AUDIO,
        },
        transformedImage: {
          label: 'Transformed image',
          inputType: InputType.IMAGE,
        },
      },
      quantity: 2,
      maximum: 4,
    },
  },
  correct_audio: {
    label: 'Correct audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_ting.mp3'],
  },
  intro_audio: {
    label: 'Intro audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_incorrect_bloop.mp3'],
  },
  outro_audio: {
    label: 'Outro audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_celebration_cheer.mp3'],
  },
  sfx_bloop: {
    label: 'On drop audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_incorrect_bloop.mp3'],
  },
  sfx_character_intro: {
    label: 'Character intro audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_thud.mp3', 'audio/sfx_character_hey.mp3'],
  },
  sfx_post_transform: {
    label: 'Post-transform character audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_character_phew.mp3'],
  },
  sfx_character_outro: {
    label: 'Character outro audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_character_woohoo.mp3'],
  },
}

export const labelTransformGenerateJson = (
  formState: Record<keyof typeof labelTransformFormReqs, any>,
) => {
  const unitImages = Object.values(formState.unit).map(
    (unit: any) => unit.image[0],
  )

  const unitTransformedImages = Object.keys(formState.unit).map((unitKey) => {
    const unit = formState.unit[unitKey]
    return unit.transformedImage[0]
  })

  const characterStateImages = Object.keys(
    formState.unitToTransform.unitToTransform1,
  ).map((key) => {
    const unitToTransformStateImage =
      formState.unitToTransform.unitToTransform1[key]
    return unitToTransformStateImage[0]
  })

  const unitToTransform: any = {}

  Object.keys(formState.unitToTransform.unitToTransform1).forEach((key) => {
    const characterState = formState.unitToTransform.unitToTransform1[key]
    unitToTransform[key] = {
      name: removeExtension(characterState[0].name),
      image: formatAssetToString(characterState[0]),
      type: getExtension(characterState[0].name),
    }
  })

  const allImages = [
    ...unitImages,
    ...unitTransformedImages,
    ...characterStateImages,
  ]
  const [images, jsons] = partitionImageTypes(allImages)

  const unitAudioFileMap: any = {}
  Object.keys(formState.unit).forEach((unitKey, index) => {
    const unit = formState.unit[unitKey]

    unitAudioFileMap[`unit${index + 1}AcceptAudio`] = formatAssetsToStrings(
      unit.acceptAudio,
    )
  })

  const units = Object.keys(formState.unit).map((unitKey, index) => {
    const unit = formState.unit[unitKey]
    const unitImage = unit.image[0]
    const unitTransformedImage = unit.transformedImage[0]
    return {
      unit: {
        name: removeExtension(unitImage.name),
        image: formatAssetToString(unitImage),
        type: getExtension(unitImage.name),
        accept: `unit${index + 1}AcceptAudio`,
      },
      transformReplacement: {
        name: removeExtension(unitTransformedImage.name),
        image: formatAssetToString(unitTransformedImage),
        type: getExtension(unitTransformedImage.name),
      },
    }
  })

  return {
    gameType: 'LABEL_TRANSFORM',
    hasReward: false,
    repeatTappableQuestion: true,
    autoScaleJsonAssets: true,
    units,
    unitToTransform,
    background: {
      main: formatAssetToString(formState.background[0]),
    },
    audio: {
      ...unitAudioFileMap,
      correct_audio: formatAssetsToStrings(formState.correct_audio),
      intro_audio: formatAssetsToStrings(formState.intro_audio),
      outro_audio: formatAssetsToStrings(formState.outro_audio),
      sfx_bloop: formatAssetsToStrings(formState.sfx_bloop),
      sfx_character_intro: formatAssetsToStrings(formState.sfx_character_intro),
      sfx_post_transform: formatAssetsToStrings(formState.sfx_post_transform),
      sfx_character_outro: formatAssetsToStrings(formState.sfx_character_outro),
      sfx_chaotic_drum: ['audio/sfx_chaotic_drum'],
    },
    images: {
      svgs: [formatAssetToString(formState.background[0]), ...images],
    },
    animations: {
      jsons,
    },
  }
}
