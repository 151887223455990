import React from 'react'
import Heading from 'src/components/Heading'
import Line from 'src/components/Line'
import { Asset, AssetType } from 'src/constants/types'
import { InputConfig } from 'src/gameSchemas/types'
import InputConfigFormGroup from './InputConfigFormGroup'

interface RepeatableFormGroupProps {
  config: Record<string, InputConfig>
  localFormState: Record<string, any>
  onUpdate: (childState: Record<string, any>) => void
  index: number
  header: string
  repeatableKey: string
  /** If this repeatable group is within another repeatable group,
   * what is the index of that repeatable group? */
  repeatableGroupIndices?: number[]
  suggestions: Asset[]
  getSuggestions: ({
    query,
    assetTypes,
  }: {
    query: string
    assetTypes: AssetType[]
  }) => Promise<void>
  setFormState: React.Dispatch<React.SetStateAction<{}>>
  includeLine: boolean
  headingLevel: 'h1' | 'h2' | 'h3'
}

const RepeatableFormGroup = (props: RepeatableFormGroupProps) => {
  const {
    config,
    localFormState,
    index,
    header,
    repeatableKey,
    repeatableGroupIndices,
    onUpdate,
    suggestions,
    getSuggestions,
    setFormState,
    includeLine,
    headingLevel,
  } = props

  return (
    <>
      <Heading as={headingLevel}>{header}</Heading>
      {includeLine && <Line />}
      {Object.keys(config).map((childKey) => {
        const compositeParentKey = `${repeatableKey}${index}`
        const childConfig = config[childKey]

        const childState = localFormState[compositeParentKey] || {}

        const childValue = childState[childKey] || ''

        return (
          <InputConfigFormGroup
            key={childKey}
            formId={childKey}
            formState={childState}
            inputConfig={childConfig}
            repeatableGroupIndices={repeatableGroupIndices}
            value={childValue}
            onChange={(
              value:
                | string
                | number
                | Asset[]
                | Record<string, any>
                | undefined,
            ) => {
              const updatedState = {
                ...childState,
                [childKey]: value,
              }
              onUpdate(updatedState)
            }}
            suggestions={suggestions}
            getSuggestions={getSuggestions}
            setFormState={setFormState}
          />
        )
      })}
    </>
  )
}

export default RepeatableFormGroup
