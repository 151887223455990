import React from 'react'
import Lottie from 'react-lottie'
import Heading from 'src/components/Heading'
import ComponentIds from 'src/constants/componentIds'
import { Asset, AssetType } from 'src/constants/types'

export const TitledImagePreview = ({
  title,
  asset,
  width = 80,
  height = 80,
}: {
  title: string
  asset?: Asset
  width?: number
  height?: number
}) => {
  if (!asset || !asset.url) {
    return null
  }

  let displayAsset = (
    <img
      alt=""
      style={{ width, height }}
      key={asset.uuid}
      src={asset.url}
      data-testid={ComponentIds.COMPONENT_IMAGE}
    />
  )

  if (asset.type === AssetType.ANIMATION) {
    displayAsset = (
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
          // @ts-ignore
          path: asset.url!.replace(/^http:\/\//i, 'https://'),
        }}
        height={100}
        width={100}
      />
    )
  }

  return (
    <div style={{ marginBottom: '16px' }}>
      <Heading as="h3">{title}</Heading>
      {displayAsset}
    </div>
  )
}
