import React from 'react'
import { Table } from 'react-bootstrap'
import {
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  TablePropGetter,
  TableProps,
} from 'react-table'

import { Loading } from 'src/components'
import { Asset } from 'src/constants/types'

const getSortIcon = (column: HeaderGroup<Asset>) => {
  if (column.canSort) {
    if (!column.isSorted) return ' –'
    return column.isSortedDesc ? ' ▼' : ' ▲'
  }
  return ''
}

type Props = {
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<Asset> | undefined,
  ) => TableBodyProps
  getTableProps: (propGetter?: TablePropGetter<Asset> | undefined) => TableProps
  headerGroups: HeaderGroup<Asset>[]
  loading: boolean
  page: Row<Asset>[]
  prepareRow: (row: Row<Asset>) => void
}

export default ({
  getTableBodyProps,
  getTableProps,
  headerGroups,
  loading,
  page,
  prepareRow,
}: Props) => (
  <Table
    style={{ tableLayout: 'fixed' }}
    striped
    bordered
    hover
    {...getTableProps()}
  >
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              style={column.Header === 'File' ? { width: '350px' } : undefined}
            >
              {column.render('Header')}
              <span>{getSortIcon(column)}</span>
            </th>
          ))}
        </tr>
      ))}
    </thead>
    <tbody {...getTableBodyProps()}>
      {loading ? (
        <tr>
          <td
            style={{ textAlign: 'center' }}
            colSpan={headerGroups[0].headers.length}
          >
            <Loading />
          </td>
        </tr>
      ) : (
        page.map((row) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  style={{
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    overflowWrap: 'break-word',
                  }}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          )
        })
      )}
    </tbody>
  </Table>
)
