import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Assets from '../tables/Assets'
import AddAssetForm from '../forms/AddAssetForm'

const Home = () => (
  <Container>
    <h1 style={{ textAlign: 'center' }}>Lingumi Learning Tools</h1>
    <hr />
    <Row>
      <h2>Add asset</h2>
      <AddAssetForm onSubmit={() => {}} />
    </Row>
    <hr />
    <Assets />
  </Container>
)

export default Home
