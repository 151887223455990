import { Asset, AssetType, GameType, IAsset } from 'src/constants/types'
import {
  InputConfig,
  InputType,
  LingumiCharacters,
} from 'src/gameSchemas/types'
import GameSchema from './gameSchema'
import {
  correctAudioInput,
  incorrectAudioInput,
  introAudioInput,
  outroAudioInput,
} from './standard'
import {
  formatAssetForOutput,
  formatAssetToString,
  removeExtension,
} from './utils'

interface UnitWithAudio {
  image: Asset[]
  audio: Asset[]
}

interface DropZone {
  preselected: string
  acceptedUnits: Record<string, { name: string }>
}

export interface CrossTheBridgeQuestion {
  unit: Record<string, UnitWithAudio>
  dropzones: Record<string, DropZone>
  character: LingumiCharacters
  endUnit: Record<string, UnitWithAudio>
}

enum RewardBox {
  TREASURE_CHEST = 'Treasure Chest',
}

const formatUnitName = (unit: UnitWithAudio, index: number) =>
  `unit${index + 1}_${removeExtension(unit.image[0].name)}`

export const getUnitNames = (
  formState: any,
  repeatableGroupIndices: number[],
) => {
  const questions = formState.questions as Record<
    string,
    CrossTheBridgeQuestion
  >

  const questionIndex = repeatableGroupIndices[0] - 1

  if (!Object.values(questions)[questionIndex]) {
    return []
  }

  const units = Object.values(questions)[questionIndex].unit

  if (!units) {
    return []
  }

  const unitNames: { label: string; value: string }[] = []

  Object.values(units).forEach((unit: any, index: number) => {
    if (unit.image && unit.image[0]) {
      const unitImageName = formatUnitName(unit, index)
      unitNames.push({
        label: removeExtension(unit.image[0].name),
        value: unitImageName,
      })
    }
  })

  return unitNames
}

export const getCrossTheBridgeFormReqs = (
  formState: Record<string, any>,
): Record<string, InputConfig> => ({
  background: {
    label: 'Background image',
    inputType: InputType.IMAGE,
    default: ['image/sky_and_waves_background.svg'],
  },
  platformLeft: {
    label: 'Platform left image',
    inputType: InputType.IMAGE,
    default: ['image/cliff_edge_platform_left.svg'],
  },
  platformRight: {
    label: 'Platform right image',
    inputType: InputType.IMAGE,
    default: ['image/cliff_edge_platform_right.svg'],
  },

  questions: {
    label: 'Question',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      quantity: 1,
      maximum: 1,
      childConfig: {
        unit: {
          label: 'Unit',
          inputType: InputType.REPEATABLE_GROUP,
          repeatableInputConfig: {
            quantity: 2,
            maximum: 5,
            childConfig: {
              image: {
                label: 'Image',
                inputType: InputType.ASSET,
                description:
                  'Displayed on tiles that user must drag into correct dropzone. (Assets must be 512x512 in order to be displayed at a correct size)',
              },
              audio: {
                label: 'Audio',
                inputType: InputType.AUDIO,
              },
            },
          },
        },
        dropzones: {
          label: 'Dropzone',
          inputType: InputType.REPEATABLE_GROUP,
          repeatableInputConfig: {
            quantity: 2,
            maximum: 5,
            childConfig: {
              preselected: {
                label: 'Preselected',
                inputType: InputType.SELECT,
                options: (repeatableGroupIndices) => [
                  { label: 'No', value: 'no' },
                  ...getUnitNames(formState, repeatableGroupIndices),
                ],
                default: ['no'],
              },
              acceptedUnits: {
                label: 'Accepted unit',
                inputType: InputType.REPEATABLE_GROUP,
                repeatableInputConfig: {
                  quantity: 1,
                  maximum: 5,
                  childConfig: {
                    name: {
                      label: 'Unit',
                      inputType: InputType.SELECT,
                      options: (repeatableGroupIndices) =>
                        getUnitNames(formState, repeatableGroupIndices),
                    },
                  },
                },
              },
            },
          },
        },
        character: {
          label: 'Character',
          inputType: InputType.SELECT,
          default: [LingumiCharacters.BOO],
          options: [
            { label: LingumiCharacters.BOO, value: LingumiCharacters.BOO },
            { label: LingumiCharacters.PEEK, value: LingumiCharacters.PEEK },
            { label: LingumiCharacters.YUM, value: LingumiCharacters.YUM },
            { label: LingumiCharacters.HUSH, value: LingumiCharacters.HUSH },
          ],
        },
        treasureChest: {
          label: 'Reward container',
          description:
            'Animated object on far platform which character wants to get to, e.g. a treasure chest. This object contains the reward which is then shown to the user.',
          inputType: InputType.SELECT,
          default: [RewardBox.TREASURE_CHEST],
          options: [
            {
              label: RewardBox.TREASURE_CHEST,
              value: RewardBox.TREASURE_CHEST,
            },
          ],
        },
        endUnit: {
          label: 'Reward (End Unit)',
          inputType: InputType.GROUP,
          groupInputConfig: {
            childConfig: {
              image: { label: 'Image', inputType: InputType.ASSET },
              audio: { label: 'Audio', inputType: InputType.AUDIO },
            },
          },
        },
      },
    },
  },
  intro_audio: introAudioInput,
  outro_audio: outroAudioInput,
  correct_audio: correctAudioInput,
  incorrect_audio: incorrectAudioInput,
})

export interface CrossTheBridgeFormState {
  background: Asset[]
  // eslint-disable-next-line camelcase
  intro_audio: Asset[]
  // eslint-disable-next-line camelcase
  outro_audio: Asset[]
  // eslint-disable-next-line camelcase
  correct_audio: Asset[]
  // eslint-disable-next-line camelcase
  incorrect_audio: Asset[]

  questions: Record<string, CrossTheBridgeQuestion>
  platformLeft: Asset[]
  platformRight: Asset[]
}

export const generateCrossTheBridgeJson = (
  formState: CrossTheBridgeFormState,
) => {
  const gameSchema = new GameSchema(GameType.CROSS_THE_BRIDGE, formState)
  gameSchema.addAsset(formState.platformLeft[0])
  gameSchema.addAsset(formState.platformRight[0])
  gameSchema.addAudioFromString('drop_audio', ['audio/sfx_drop'])

  const questions = Object.values(formState.questions).map(
    (question: CrossTheBridgeQuestion, questionIndex: number) => {
      const units = Object.values(question.unit).map(
        (unit: UnitWithAudio, index: number) => {
          /* Create a unit name which is unique for this set of units
          (this allows the same asset to be used multiple times if the teacher so wishes) */
          const unitName = formatUnitName(unit, index)

          const unitAsset = formatAssetForOutput(unit.image[0], unitName)

          const audioName = `question${questionIndex + 1}_unit${
            index + 1
          }_audio`

          gameSchema.addAsset(unit.image[0])
          gameSchema.addAudioFromAssets(audioName, unit.audio)

          return { ...unitAsset, audio: audioName }
        },
      )

      const dropzones = Object.values(question.dropzones).map(
        (dropzone: DropZone) => {
          const acceptedUnits = Object.values(dropzone.acceptedUnits)

          return {
            accepted: acceptedUnits,
            itemName:
              dropzone.preselected === 'no' ? undefined : dropzone.preselected,
          }
        },
      )

      const chosenCharacter = question.character.toLowerCase()

      const idleAsset: IAsset = {
        name: `anim_${chosenCharacter}_idle_512x512.json`,
        type: AssetType.ANIMATION,
      }

      const walkingAsset: IAsset = {
        name: `anim_${chosenCharacter}_walking_right_512x512.json`,
        type: AssetType.ANIMATION,
      }

      const celebrateAsset: IAsset = {
        name: `anim_${chosenCharacter}_jump_and_celebrate_512x512.json`,
        type: AssetType.ANIMATION,
      }

      const character = {
        idleState: formatAssetToString(idleAsset),
        walkingRightState: formatAssetToString(walkingAsset),
        celebrateState: formatAssetToString(celebrateAsset),
      }

      gameSchema.addAsset(idleAsset)
      gameSchema.addAsset(walkingAsset)
      gameSchema.addAsset(celebrateAsset)

      const treasureChestIdleAsset: IAsset = {
        name: 'anim_treasure_chest_idle.json',
        type: AssetType.ANIMATION,
      }

      const treasureChestOpeningState: IAsset = {
        name: 'anim_treasure_chest_opening.json',
        type: AssetType.ANIMATION,
      }

      const treasureChestShakingState: IAsset = {
        name: 'anim_treasure_chest_shaking.json',
        type: AssetType.ANIMATION,
      }

      gameSchema.addAsset(treasureChestIdleAsset)
      gameSchema.addAsset(treasureChestShakingState)
      gameSchema.addAsset(treasureChestOpeningState)

      const treasureChest = {
        idleState: formatAssetToString(treasureChestIdleAsset),
        openingState: formatAssetToString(treasureChestOpeningState),
        shakingState: formatAssetToString(treasureChestShakingState),
      }

      const endUnitAsset = formatAssetForOutput(
        question.endUnit.endUnit1.image[0],
      )

      gameSchema.addAsset(question.endUnit.endUnit1.image[0])

      const endUnitAudio = `end_unit_audio_${questionIndex + 1}`

      gameSchema.addAudioFromAssets(
        endUnitAudio,
        question.endUnit.endUnit1.audio,
      )

      return {
        units,
        dropzones,
        character,
        treasureChest,
        endUnit: {
          ...endUnitAsset,
          audio: endUnitAudio,
        },
      }
    },
  )

  return gameSchema.generate({
    questions,
    platformLeft: formatAssetForOutput(formState.platformLeft[0]),
    platformRight: formatAssetForOutput(formState.platformRight[0]),
  })
}
