import { useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'
import { get } from 'src/api/api'
import { BASE_URL } from 'src/constants/env'
import { Asset, AssetType } from 'src/constants/types'
import { keysToCamelCase } from 'src/utils'

type AssetSearchResult = Omit<Asset, 'file'>

export const useSuggestions = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [suggestions, setSuggestions] = useState<Asset[]>([])

  async function getSuggestions({
    query,
    assetTypes,
  }: {
    query: string
    assetTypes: AssetType[]
  }) {
    // TODO: debounce & don't search if value hasn't changed
    const token = await getAccessTokenSilently()
    const searchTermQuery = encodeURIComponent(query)
    const assetTypesQuery = assetTypes.reduce(
      (query, type) => `${query}&type=${type}`,
      '',
    )

    const result = await get(
      token,
      `${BASE_URL}/assets/search?query=${searchTermQuery}${assetTypesQuery}`,
    )

    let convertedResults: AssetSearchResult[] = []
    if (result.length) {
      convertedResults = result.map(
        (asset: AssetSearchResult) =>
          keysToCamelCase(asset) as AssetSearchResult,
      )
    }

    setSuggestions(convertedResults)
  }

  return { getSuggestions, suggestions }
}
