import React from 'react'
import { Asset, AssetType } from 'src/constants/types'
import { Col, Form, Row } from 'react-bootstrap'
import AutoSuggestTagsInput from 'src/components/AutoSuggestTagsInput'

interface Props {
  controlId: string
  label: string
  assetTypes: AssetType[]
  suggestions: Asset[]
  onFetchSuggestions: ({
    query,
    assetTypes,
  }: {
    query: string
    assetTypes: AssetType[]
  }) => void
  onSelectedSuggestion: (assets: Asset[]) => void
  selectedAssets: Asset[] | null
  maxTags?: number
  placeholder?: string
  labelColSize?: number
  inputColSize?: number
}

export const AutoSuggestFormGroup = ({
  controlId,
  label,
  assetTypes,
  suggestions,
  onFetchSuggestions,
  onSelectedSuggestion,
  selectedAssets,
  maxTags,
  placeholder,
  labelColSize,
  inputColSize,
}: Props) => (
  <Form.Group as={Row} controlId={controlId}>
    <Form.Label column sm={labelColSize || '4'}>
      {label}
    </Form.Label>
    <Col sm={inputColSize || '8'}>
      <AutoSuggestTagsInput
        id={controlId}
        maxTags={maxTags}
        assetTypes={assetTypes}
        suggestions={suggestions}
        onFetchSuggestions={onFetchSuggestions}
        onSelectedSuggestion={onSelectedSuggestion}
        selectedAssets={selectedAssets}
        placeholder={placeholder || `Add ${assetTypes[0]}`}
      />
    </Col>
  </Form.Group>
)
