import React from 'react'
import { Form } from 'react-bootstrap'

import { InputConfig, InputType } from 'src/gameSchemas/types'
import { getAssetFromString } from 'src/gameSchemas/utils'

interface DefaultCheckboxProps {
  inputConfig: InputConfig
  onUpdate: (value: Record<string, any> | undefined) => void
}

const DefaultCheckbox = (props: DefaultCheckboxProps) => {
  if (!props.inputConfig.default) {
    throw new Error('Tried to use DefaultCheckbox with no default provided')
  }
  return (
    <Form.Check
      defaultChecked
      style={{ marginBottom: 15 }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const {
          target: { checked },
        } = event
        if (checked === true) {
          props.onUpdate(
            props.inputConfig.default!.map((fileName) => {
              if (props.inputConfig.inputType === InputType.NUMBER) {
                return fileName
              }

              if (props.inputConfig.inputType === InputType.SELECT) {
                return fileName
              }

              return getAssetFromString(fileName)
            }),
          )
        } else {
          props.onUpdate(undefined)
        }
      }}
      type="checkbox"
      id="default-checkbox"
      label={`Use default (${props.inputConfig.default}) for ${props.inputConfig.label}`}
    />
  )
}

export default DefaultCheckbox
