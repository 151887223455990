import _ from 'lodash'
import { VideoType } from 'src/constants/types'
import { localeInput, speechRecognitionTypeInput } from './standard'
import { InputType } from './types'
import {
  formatAssetsToStrings,
  formatAssetToString,
  getExtension,
  partitionImageTypesIncludingWebp,
  removeExtension,
} from './utils'

export const getTapSpeakFormRegs = (formState: any) => {
  const isSpeakingActivity = formState.variation === 'speak'
  return {
    locale: localeInput,
    speechRecognitionType: speechRecognitionTypeInput,
    background: {
      label: 'Background image',
      inputType: InputType.IMAGE,
    },
    variation: {
      label: 'Tappy Speak Variation',
      inputType: InputType.SELECT,
      options: [
        { label: 'Speak', value: 'speak' },
        { label: 'No Speak', value: 'no speak' },
      ],
    },
    ...(isSpeakingActivity
      ? {
          timeoutInSeconds: {
            label: 'Timeout Time (in seconds)',
            inputType: InputType.NUMBER,
            default: ['4'],
          },
          timeoutVideo: {
            label: 'Timeout Video',
            inputType: InputType.VIDEO,
          },
          errorVideo: {
            label: 'Error Video',
            inputType: InputType.VIDEO,
          },
        }
      : {}),

    question: {
      label: 'Question',
      inputType: InputType.REPEATABLE_GROUP,
      repeatableInputConfig: {
        childConfig: {
          unit: {
            label: 'Unit (image/video)',
            inputType: InputType.ASSET,
          },
          prompt: {
            label: 'Prompt (optional for video units)',
            inputType: InputType.AUDIO,
          },
          ...(isSpeakingActivity
            ? {
                fullMatch: {
                  label: 'Correct answer',
                  inputType: InputType.TEXT_INPUT,
                },
                accept: { label: 'Accept', inputType: InputType.AUDIO },
              }
            : {}),
        },
        quantity: 4,
        maximum: 4,
      },
    },
    audio: {
      label: 'Game Audio',
      inputType: InputType.GROUP,
      groupInputConfig: {
        childConfig: {
          teacher_outro_congratulations: {
            label: 'Teacher outro congratulations',
            inputType: InputType.AUDIO,
          },
          intro_audio: {
            label: 'Intro audio',
            inputType: InputType.AUDIO,
            default: ['audio/sfx_incorrect_bloop.mp3'],
          },
          correct_audio: {
            label: 'Correct audio',
            inputType: InputType.AUDIO,
            default: ['audio/sfx_ting.mp3'],
          },
          present_appear_sound: {
            label: 'Present appear audio',
            inputType: InputType.AUDIO,
            default: ['audio/sfx_whistle_in.mp3'],
          },

          outro_audio: {
            label: 'Outro audio',
            inputType: InputType.AUDIO,
            default: ['audio/sfx_celebration_cheer.mp3'],
          },
        },
      },
    },
  }
}

export const tapSpeakGenerateJson = (formState: Record<any, any>) => {
  const presentImages = [
    'image/object-present-yellow-and_blue-tappy-speak',
    'image/object-present-red-and-green-tappy-speak',
    'image/object-present-blue-and-red-tappy-speak',
    'image/object-present-green-and-yellow-tappy-speak',
  ]

  const presentBurstAnimations = [
    'animation/anim_present_opening_yellow_and_blue',
    'animation/anim_present_opening_red_and_green',
    'animation/anim_present_opening_blue_and_red',
    'animation/anim_present_opening_green_and_yellow',
  ]

  const cardColours = ['#F4BE37', '#D25858', '#5FB9DA', '#5CB16B']

  const isSpeakingActivity = formState.variation === 'speak'

  const unitAudioMap: any = {}

  const questions = Object.keys(formState.question).map(
    (questionKey, questionIndex) => {
      const question = formState.question[questionKey]

      const unitImage = question.unit[0]

      const presentNumber = questionIndex + 1

      // Makes it possible for video questions not to have a prompt
      unitAudioMap[`prompt_audio_${presentNumber}`] = question.prompt
        ? formatAssetsToStrings(question.prompt)
        : []

      if (isSpeakingActivity) {
        unitAudioMap[`accept_audio_${presentNumber}`] = formatAssetsToStrings(
          question.accept,
        )
      }

      const presentImage = presentImages[questionIndex % presentImages.length]
      const presentAnimation =
        presentBurstAnimations[questionIndex % presentBurstAnimations.length]

      const cardColour = cardColours[questionIndex % cardColours.length]

      const outputQuestion = {
        unitToDisplay: {
          name: removeExtension(unitImage.name),
          image: formatAssetToString(unitImage),
          type: getExtension(unitImage.name),
        },
        prompt: `prompt_audio_${presentNumber}`,
        fullMatch: question.fullMatch,
        present: {
          name: `present_${presentNumber}`,
          image: presentImage,
          type: 'svg',
        },
        revealState: {
          name: `present_burst_${presentNumber}`,
          image: presentAnimation,
          type: 'json',
        },
        cardColour,
      }

      if (isSpeakingActivity) {
        return {
          ...outputQuestion,
          accept: `accept_audio_${presentNumber}`,
        }
      }

      return outputQuestion
    },
  )

  const generalAudioMap: any = {}
  Object.keys(formState.audio.audio1).forEach((audioKey) => {
    const audio = formState.audio.audio1[audioKey]
    generalAudioMap[audioKey] = formatAssetsToStrings(audio)
  })

  const unitImages = Object.values(formState.question).map(
    (question: any) => question.unit[0],
  )

  const [svgUnitImages, jsonUnitImages, mp4UnitImages, webpUnitImages] =
    partitionImageTypesIncludingWebp(unitImages)

  const unitSvgs = formatAssetsToStrings(svgUnitImages)
  const unitJsons = formatAssetsToStrings(jsonUnitImages)
  const webps = formatAssetsToStrings(webpUnitImages)

  const extraSpeakingActivitySvgs = isSpeakingActivity
    ? [
        'image/ui-star-grey-tappy-speak',
        'image/ui-star-with-white-outline-tappy-speak',
      ]
    : []

  const svgs = [
    formatAssetToString(formState.background[0]),
    ...unitSvgs,
    ...presentImages,
    ...extraSpeakingActivitySvgs,
  ]

  const jsons = [
    'animation/anim_star_grow_and_explode',
    ...presentBurstAnimations,
    ...unitJsons,
  ]

  const allVideoFiles = isSpeakingActivity
    ? [formState.errorVideo[0], formState.timeoutVideo[0], ...mp4UnitImages]
    : mp4UnitImages

  const mp4s = formatAssetsToStrings(allVideoFiles)
  const assetMap = allVideoFiles.reduce(
    (acc, v) => ({
      ...acc,
      [formatAssetToString(v)]: removeExtension(v.streamingUrl),
    }),
    {},
  )

  const speakingOnlyConfigOptions = isSpeakingActivity
    ? {
        errorVideoUrl: formatAssetToString(formState.errorVideo[0]),
        timeoutVideoUrl: formatAssetToString(formState.timeoutVideo[0]),
        timeoutInSeconds: Number(formState.timeoutInSeconds),
      }
    : {}

  return {
    locale: formState.locale || 'en-US',
    speechRecognitionType: formState.speechRecognitionType,
    gameType: 'TAPPY_SPEAK',
    hasReward: false,
    repeatTappableQuestion: true,
    autoScaleJsonAssets: true,
    isSpeakingActivity,
    background: {
      main: formatAssetToString(formState.background[0]),
    },
    questions,
    ...speakingOnlyConfigOptions,
    videoType: VideoType.STREAMING,
    assetMap,
    audio: {
      ...generalAudioMap,
      ...unitAudioMap,
      incorrect_audio: ['audio/sfx_incorrect_bloop'],
      present_bursting: ['audio/sfx_incorrect_bloop'],
      sfx_whoosh: ['audio/sfx_whoosh'],
      star_entering_slot: ['audio/sfx_incorrect_bloop'],
      star_growing_sound: ['audio/sfx_whistle_in'],
      star_exploding: ['audio/sfx_burst'],
      confetti_bursting_out: ['audio/sfx_firework'],
      pre_star_drumroll: ['audio/sfx_drumRoll'],
    },
    images: {
      svgs,
      webps,
    },
    animations: {
      jsons,
    },
    ...(mp4s.length > 0 ? { videos: { mp4s } } : {}),
  }
}
