import React, { useEffect, useState } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import styled from 'styled-components'

const ErrorMessage = styled.div`
  font-size: 11px;
  color: red;
`

interface Props {
  audioUrls: string[]
  width?: string
  height?: string
}

const AudioPlayer = (props: Props) => {
  const [audioQueue, setAudioQueue] = useState<string[]>([])
  const [currentAudioUrl, setCurrentAudioUrl] = useState('')
  const [autoPlay, setAutoPlay] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setAudioQueue(props.audioUrls)

    if (props.audioUrls.length === 0) {
      setErrorMessage('Cannot load audio. Please contact tech.')
      return
    }

    setErrorMessage('')
    setCurrentAudioUrl(props.audioUrls[0])
  }, [props.audioUrls])

  const setAudioState = (audioUrls: string[], playNext: boolean) => {
    setAudioQueue(audioUrls)
    setCurrentAudioUrl(audioUrls[0])
    setAutoPlay(playNext)
  }

  const onClipFinished = () => {
    const [_, ...remainingUrls] = audioQueue

    if (remainingUrls.length === 0) {
      setAudioState(props.audioUrls, false)

      return
    }

    if (remainingUrls[0] === '') {
      setErrorMessage('Cannot load audio. Please contact tech.')
      return
    }

    setAudioState(remainingUrls, true)
  }

  return (
    <>
      <ReactAudioPlayer
        style={{
          width: props.width ? props.width : '',
          height: props.height ? props.height : '',
        }}
        autoPlay={autoPlay}
        src={currentAudioUrl.replace(/^http:\/\//i, 'https://')}
        controls
        controlsList={props.audioUrls.length > 1 ? 'nodownload' : ''}
        onEnded={onClipFinished}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  )
}

export default AudioPlayer
