import _ from 'lodash'
import { VideoType } from 'src/constants/types'
import { localeInput } from './standard'
import { InputType } from './types'
import { removeExtension, removeSpacesAndLowercase } from './utils'

export const getSmartConversationFormReqs = (formState: any) => ({
  hasGenericError: {
    label: 'Does this SC use a generic error video for all units?',
    inputType: InputType.SELECT,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  hasIntroVideo: {
    label: 'Does this SC have an intro video before the first prompt?',
    inputType: InputType.SELECT,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  locale: localeInput,
  item: {
    label: 'Item',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      childConfig: {
        name: {
          label: 'Name for this item',
          inputType: InputType.TEXT_INPUT,
        },
        promptVideo: {
          label: 'Prompt video',
          inputType: InputType.VIDEO,
        },
        correctVideo: {
          label: 'Correct video',
          inputType: InputType.VIDEO,
        },
        recordAfterSeconds: {
          label:
            'Record after seconds (how many seconds into the prompt video should we start recording?)',
          inputType: InputType.TEXT_INPUT,
        },
        stopRecordAfterSeconds: {
          label:
            'After we start recording, how many seconds should we record for before timing out',
          inputType: InputType.TEXT_INPUT,
        },
        fullMatch: {
          label: 'Expected answer (what should the child say)',
          inputType: InputType.TEXT_INPUT,
        },
        ...(formState.hasGenericError === 'false'
          ? {
              errorVideo: {
                label: 'Error video',
                inputType: InputType.VIDEO,
              },
              errorRecordAfterSeconds: {
                label:
                  'Error record after seconds (how many seconds into the error video should we start recording?)',
                inputType: InputType.TEXT_INPUT,
              },
            }
          : {}),
        skillMeasurements: {
          label: 'Skill measurements',
          inputType: InputType.SKILL_MEASUREMENTS,
        },
      },
      quantity: 1,
      maximum: 4,
    },
  },
  timeoutVideo: {
    label: 'Timeout video (same for all items)',
    inputType: InputType.VIDEO,
  },
  ...(formState.hasGenericError === 'true'
    ? {
        errorVideo: {
          label: 'Error video (same for all items)',
          inputType: InputType.VIDEO,
        },
      }
    : {}),
  ...(formState.hasIntroVideo === 'true'
    ? {
        introVideo: {
          label: 'Intro video',
          inputType: InputType.VIDEO,
        },
      }
    : {}),
  unimportantWords: {
    label: 'Unimportant words (comma-separated, e.g. "an,this,the")',
    inputType: InputType.TEXT_INPUT,
  },
})

export const smartConversationGenerateJson = (formState: Record<any, any>) => {
  const items = Object.values(formState.item).map((item: any) => {
    const formattedName = removeSpacesAndLowercase(item.name)
    return {
      videoId: formattedName,
      fullMatch: item.fullMatch.trim(),
      recordAfterSeconds: Number(item.recordAfterSeconds),
      skillMeasurements: [item.skillMeasurements],
      stopRecordAfterSeconds: Number(item.stopRecordAfterSeconds),
      ...(formState.hasGenericError === 'false'
        ? {
            errorRecordAfterSeconds: Number(item.errorRecordAfterSeconds),
          }
        : {}),
    }
  })
  // Create an assetMap with the entire asset object as an intermediary step
  const assetMapWithFullAssets = Object.values(formState.item).reduce(
    (acc: any, item: any) => {
      const formattedName = removeSpacesAndLowercase(item.name)
      return {
        ...acc,
        [`what-is-${formattedName}`]: item.promptVideo[0],
        [`label-correct-${formattedName}`]: item.correctVideo[0],
        ...(formState.hasGenericError === 'false'
          ? {
              [`label-error-${formattedName}`]: item.errorVideo[0],
            }
          : {}),
      }
    },
    {
      timeout: formState.timeoutVideo[0],
      ...(formState.hasGenericError === 'true'
        ? {
            error: formState.errorVideo[0],
          }
        : {}),
      ...(formState.hasIntroVideo === 'true'
        ? {
            intro: formState.introVideo[0],
          }
        : {}),
    },
  ) as { [s: string]: unknown }

  // Check whether all the videos being used have streaming urls
  const allVideosHaveStreaming = Object.values(assetMapWithFullAssets).every(
    (asset: any) => asset.streamingUrl,
  )

  const finalAssetMap = Object.keys(assetMapWithFullAssets).reduce(
    (acc, key) => {
      const assetForKey: any = assetMapWithFullAssets[key]
      return {
        ...acc,
        [key]: allVideosHaveStreaming
          ? removeExtension(assetForKey.streamingUrl)
          : removeExtension(assetForKey.name),
      }
    },
    {},
  )
  return {
    data: {
      locale: formState.locale || 'en-US',
      gameId: 'label_items',
      config: {
        items,
        baseVideoUrl: 'n/a (uses assetMap)',
        idleVideo: null,
        hasGenericError: formState.hasGenericError === 'true',
        hasIntroVideo: formState.hasIntroVideo === 'true',
        hasEndVideo: false,
        hasWaitingVideo: false,
        unimportantWords: formState.unimportantWords
          .split(',')
          .map((w: string) => w.trim()),
        videoType: allVideosHaveStreaming ? VideoType.STREAMING : VideoType.AMS,
      },
    },
    assets: [],
    assetMap: finalAssetMap,
  }
}
