import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useAuth0 } from '@auth0/auth0-react'

import ComponentIds from 'src/constants/componentIds'
import { postForm, putForm } from '../api/api'
import { Asset, AssetType, Tag } from '../constants/types'
import { validateForm, ValidationErrors } from './formValidation'
import { BASE_URL } from '../constants/env'
import theme from '../theme'

interface AddAssetFormProps {
  onSubmit: Function
}

const ErrorMessage = styled.p`
  font-size: ${theme.fontSizes.s};
  color: red;
  margin-top: ${theme.spacing.s};
`

const DescriptiveLabel = styled.p`
  font-style: italic;
`

const InputCol = styled(Col)`
  margin-top: ${theme.spacing.s};
`

const AddAssetForm = (props: AddAssetFormProps) => {
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})
  const { getAccessTokenSilently } = useAuth0()
  const initAsset: Asset = {
    id: 0,
    name: '',
    type: AssetType.IMAGE,
    tags: [],
    searchTerm: '',
  }

  const [asset, setAsset] = useState(initAsset)
  const [uploadError, setUploadError] = useState('')
  const [shouldShowModal, setShouldShowModal] = useState(false)

  const inputRef = useRef<any>()

  const handleTypeChange = (e: any) => {
    setValidationErrors({})
    const { name, value } = e.target
    setAsset({ ...asset, [name]: value })
  }

  const handleTagsChange = (tags: Tag[]) => {
    setValidationErrors({})
    setAsset({ ...asset, tags })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidationErrors({})
    if (!e.target.files) {
      return
    }

    setAsset({ ...asset, file: e.target.files[0] })
  }

  const handleSearchTermChange = (e: any) => {
    setValidationErrors({})
    const { value } = e.target
    setAsset({ ...asset, searchTerm: value })
  }

  const handleSubmit = async (e: any) => {
    setUploadError('')
    e.preventDefault()
    const errors = validateForm(asset)

    const hasValidationErrors = Object.values(errors).length > 0

    if (hasValidationErrors) {
      setValidationErrors(errors)
      return
    }

    if (asset.type && asset.file) {
      const token = await getAccessTokenSilently()
      const data = new FormData()
      data.append('file', asset.file)
      data.append('name', asset.file.name)
      data.append('type', asset.type)
      data.append('tags', JSON.stringify(asset.tags))
      data.append('searchTerm', asset.searchTerm)

      const response = await postForm(token, `${BASE_URL}/assets`, data)

      if (response && response.error && response.message) {
        setUploadError(response.message)

        if (response.message === 'asset already exists') {
          setShouldShowModal(true)
        }
      }

      props.onSubmit()
    }
  }

  const handleUpdateAsset = async () => {
    const token = await getAccessTokenSilently()

    if (asset.type && asset.file) {
      const data = new FormData()
      data.append('file', asset.file)
      data.append('name', asset.file.name)
      data.append('type', asset.type)
      data.append('tags', JSON.stringify(asset.tags))
      data.append('searchTerm', asset.searchTerm)

      await putForm(token, `${BASE_URL}/assets`, data)
    }
  }

  return (
    <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <Form.Group as={Row} controlId="formFile">
        <Form.Label column sm="2">
          Type
        </Form.Label>
        <InputCol sm="4">
          <Form.Control
            as="select"
            onChange={handleTypeChange}
            value={asset.type}
            name="type"
          >
            <option value="image">Image</option>
            <option value="animation">Animation</option>
            <option value="audio">Audio</option>
            <option value="video">Video</option>
          </Form.Control>
        </InputCol>
      </Form.Group>
      <Form.Group as={Row} controlId="formFile">
        <Form.Label column sm="2">
          File
        </Form.Label>
        <InputCol sm="4">
          <Form.File>
            <Form.File.Input
              data-testid={ComponentIds.COMPONENT_FILE_INPUT}
              id="formFile"
              onChange={handleFileChange}
              ref={inputRef}
            />
          </Form.File>
          {Boolean(validationErrors.file) && (
            <ErrorMessage>{validationErrors.file}</ErrorMessage>
          )}
        </InputCol>
        <Col sm="6">
          <DescriptiveLabel>
            Make sure the file has a descriptive name, lowercase, with
            underscores instead of spaces e.g. &quot;red_bucket.jpg&quot;
          </DescriptiveLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formTags">
        <Form.Label column sm="2">
          Tags
        </Form.Label>
        <InputCol sm="4">
          <TagsInput value={asset.tags} onChange={handleTagsChange} />
          {Boolean(validationErrors.tags) && (
            <ErrorMessage>{validationErrors.tags}</ErrorMessage>
          )}
        </InputCol>
        <Col sm="6">
          <DescriptiveLabel>
            Tags should include any themes or categories applicable to this
            asset e.g. &quot;space&quot;, &quot;kitchen&quot;, &quot;tools&quot;
          </DescriptiveLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="addSearchTerm">
        <Form.Label column sm="2">
          Search term
        </Form.Label>
        <InputCol sm="4">
          <Form.Control
            placeholder="Add search term"
            onChange={handleSearchTermChange}
            value={asset.searchTerm}
            name="search term"
          />
          {Boolean(validationErrors.searchTerm) && (
            <ErrorMessage>{validationErrors.searchTerm}</ErrorMessage>
          )}
        </InputCol>
        <Col sm="6">
          <DescriptiveLabel>
            Search term should be a descriptive name for the asset e.g.
            &quot;small queen&quot; for an image of a small queen or &quot;i can
            see&quot; for an audio file of those words
          </DescriptiveLabel>
        </Col>
      </Form.Group>

      <Button type="submit" variant="primary">
        Add asset
      </Button>
      {Boolean(uploadError) && (
        <ErrorMessage>Error uploading asset: {uploadError}</ErrorMessage>
      )}
      <Modal centered show={shouldShowModal}>
        <Modal.Header closeButton onClick={() => setShouldShowModal(false)} />
        <Modal.Body>
          <p>
            An asset already exists with this name. Would you like to replace
            it?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setUploadError('')
              setShouldShowModal(false)
            }}
          >
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShouldShowModal(false)
              setUploadError('')
              handleUpdateAsset()
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  )
}

export default AddAssetForm
