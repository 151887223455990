import React from 'react'
import { Switch } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import styled from 'styled-components'

import { NavBar, Footer, Loading } from './components'
import { Home, CreateGame } from './views'
import ProtectedRoute from './auth/protected-route'
import CreateLesson from './views/create-lesson/CreateLessonOverview'
import DeployLesson from './views/deploy-lesson/DeployLesson'

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const App = () => {
  const { isLoading } = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  return (
    <AppContainer id="app">
      <NavBar />
      <div className="container flex-grow-1">
        <Switch>
          <ProtectedRoute path="/" exact component={Home} />
          <ProtectedRoute path="/create-game" exact component={CreateGame} />
          <ProtectedRoute
            path="/create-lesson"
            exact
            component={CreateLesson}
          />
          <ProtectedRoute
            path="/deploy-lesson"
            exact
            component={DeployLesson}
          />
        </Switch>
      </div>
      <Footer />
    </AppContainer>
  )
}

export default App
