/* eslint-disable jsx-quotes */
import React from 'react'
import Badge from 'react-bootstrap/Badge'
import Lottie from 'react-lottie'
import ReactAudioPlayer from 'react-audio-player'
import ReactPlayer from 'react-player'

import { Asset, AssetType, Tag } from '../../constants/types'

export interface RenderFunction {
  ({
    cell: {
      row: { original },
    },
  }: {
    cell: {
      row: { original: Asset }
    }
  }): any
}

export const renderDateCell: RenderFunction = ({
  cell: {
    row: { original: asset },
  },
}) => (asset.createdAt ? asset.createdAt.toLocaleDateString() : '')

export const renderFileCell: RenderFunction = ({
  cell: {
    row: { original: asset },
  },
}) => {
  let render = <></>

  if (asset.url) {
    if (asset.type === AssetType.IMAGE) {
      render = (
        <img
          alt=""
          style={{ width: 80, height: 80 }}
          key={asset.uuid}
          src={asset.url}
        />
      )
    }

    if (asset.type === AssetType.AUDIO) {
      render = <ReactAudioPlayer src={asset.url} controls />
    }

    if (asset.type === AssetType.VIDEO) {
      render = <ReactPlayer width={320} height={180} url={asset.url} controls />
    }

    if (asset.type === AssetType.ANIMATION) {
      render = (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
            // @ts-ignore
            path: asset.url,
          }}
          height={100}
          width={100}
        />
      )
    }
  }

  return render
}

export const renderTagsCell: RenderFunction = ({
  cell: {
    row: { original: asset },
  },
}) => {
  if (!asset.tags) return <></>

  return asset.tags.map((tag: Tag, index: number) => (
    <Badge style={{ marginRight: 5 }} key={`tag-${index}`} variant="primary">
      {tag}
    </Badge>
  ))
}
