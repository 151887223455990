import React, { FormEvent, useContext, useEffect, useState } from 'react'

import { Button, Col, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'

import Heading from 'src/components/Heading'
import { FormReqs, InputType } from 'src/gameSchemas/types'
import { Asset } from 'src/constants/types'
import {
  getInitialFormState,
  getQueryParams,
  hydrateFormState,
  isFormComplete,
} from 'src/gameSchemas/utils'
import { useSuggestions } from '../utils/hooks'
import { GameTypeSelect } from '../create-game/GameSetupForm'
import { RvgConfigContext, RvgConfigContextType } from '../create-game/context'
import RepeatableFormGroupList from './RepeatableFormGroupList'
import InputConfigFormGroup from './InputConfigFormGroup'

export const StyledButton = styled(Button)`
  display: block;
  margin-bottom: 8px;
`

const FormContainer = styled(Form)`
  margin-bottom: 16px;
  .react-tagsinput {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
`

interface Props {
  formReqs: FormReqs
  /**
   * getFormReqs allows for dynamically changing the content of the form based on earlier answers
   */
  getFormReqs?: (formState: Record<string, any>) => FormReqs
  generateJson: (data: any) => object
}

const INCLUDE_ALL_PROPERTIES = null
const INDENT_2_SPACES = 2

const CreateGameForm = (props: Props) => {
  const { suggestions, getSuggestions } = useSuggestions()
  const { gameSetup, setGameSetup } = useContext(
    RvgConfigContext,
  ) as RvgConfigContextType
  const [formState, setFormState] = useState<Record<string, any>>({})
  const [copied, setCopied] = useState<boolean>(false)
  const [jsonError, setJsonError] = useState<string>('')
  const [parsingError, setParsingError] = useState<string | null>(null)

  const finalFormReqs = props.getFormReqs
    ? props.getFormReqs(formState)
    : props.formReqs

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    try {
      const json = props.generateJson(formState)
      await navigator.clipboard.writeText(
        JSON.stringify(json, INCLUDE_ALL_PROPERTIES, INDENT_2_SPACES),
      )
      setCopied(true)
      setJsonError('')
      // After showing success message, hide it again after 10 seconds so that they can re-generate
      // the json and receive responsive feedback again
      setTimeout(() => {
        setCopied(false)
      }, 5000)
    } catch (e: any) {
      setCopied(false)
      setJsonError(e.message)
    }
  }
  useEffect(() => {
    // Populate form with defaults (reset if game type changes)
    const initialFormState = getInitialFormState(finalFormReqs)
    setFormState(initialFormState)
  }, [gameSetup?.gameType])

  useEffect(() => {
    // Populate form from query params
    const params = getQueryParams()
    if (params.formState) {
      try {
        const parsedFormState = JSON.parse(params.formState)
        const hydratedFormState = hydrateFormState(parsedFormState)
        setFormState((formState) => ({
          ...formState,
          ...hydratedFormState,
        }))
      } catch (e: any) {
        setParsingError(e.message || 'Error parsing query params!')
      }
    }
  }, [])

  return (
    <FormContainer onSubmit={(e: any) => handleSubmit(e)}>
      {parsingError && <h1 style={{ color: 'red' }}>{parsingError}</h1>}
      <Heading as="h2">Change game type</Heading>
      <Row>
        <Col sm="6">
          <GameTypeSelect gameSetup={gameSetup} setGameSetup={setGameSetup} />
        </Col>
      </Row>
      <Heading as="h2">Add game info</Heading>
      {Object.keys(finalFormReqs).map((key) => {
        const handleFormStateUpdate = (
          value: string | number | Asset[] | Record<string, any> | undefined,
        ) => {
          setFormState((formState) => ({
            ...formState,
            [key]: value,
          }))
        }

        const inputConfig = finalFormReqs[key]
        if (
          inputConfig.inputType === InputType.REPEATABLE_GROUP ||
          inputConfig.inputType === InputType.GROUP
        ) {
          return (
            <RepeatableFormGroupList
              key={key}
              repeatableKey={key}
              inputConfig={inputConfig}
              setFormState={setFormState}
              onUpdate={(childState) =>
                setFormState((currentFormState) => ({
                  ...currentFormState,
                  [key]: childState,
                }))
              }
              suggestions={suggestions}
              getSuggestions={getSuggestions}
              headingLevel="h2"
              includeLine
              localFormState={formState[key] || {}}
            />
          )
        }
        return (
          <InputConfigFormGroup
            key={key}
            formId={key}
            formState={formState}
            setFormState={setFormState}
            inputConfig={inputConfig}
            onChange={handleFormStateUpdate}
            value={formState[key]}
            suggestions={suggestions}
            getSuggestions={getSuggestions}
          />
        )
      })}
      <StyledButton
        type="submit"
        disabled={!isFormComplete(finalFormReqs, formState)}
      >
        Copy config to clipboard
      </StyledButton>
      {copied && 'Copied to clipboard ✨✨✨'}
      {jsonError && (
        <p style={{ color: 'red' }}>Error generating JSON! 😬 {jsonError}</p>
      )}
    </FormContainer>
  )
}

export default CreateGameForm
