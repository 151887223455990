const spacing = {
  xs: '4px',
  s: '8px',
  m: '16px',
  l: '24px',
}

const fontSizes = {
  xs: '12px',
  s: '14px',
  m: '18px',
  l: '24px',
}

export default { fontSizes, spacing }
