import { AssetType, IAsset, GameType } from 'src/constants/types'
import { introAudioInput } from 'src/gameSchemas/standard'
import { FormReqs, InputType } from 'src/gameSchemas/types'
import GameSchema from './gameSchema'
import { formatAssetForOutput, formatAssetToString } from './utils'

const supportedLetters = [
  'a',
  'ai',
  'all',
  'ar',
  'are',
  'b',
  'be',
  'c',
  'ch',
  'ck',
  'd',
  'e',
  'ear',
  'ee',
  'er',
  'f',
  'ff',
  'g',
  'go',
  'h',
  'he',
  'her',
  'i',
  'igh',
  'j',
  'k',
  'l',
  'll',
  'm',
  'me',
  'my',
  'n',
  'ng',
  'no',
  'o',
  'oa',
  'oi',
  'oo',
  'ow',
  'p',
  'q',
  'qu',
  'r',
  's',
  'sh',
  'she',
  'ss',
  't',
  'th',
  'the',
  'they',
  'to',
  'u',
  'ur',
  'ure',
  'v',
  'w',
  'was',
  'we',
  'x',
  'y',
  'you',
  'z',
  'zz',
  'capital_i',
]

export const tracingPhonicsFormReqs: FormReqs = {
  background: {
    label: 'Background image',
    inputType: InputType.IMAGE,
    default: ['image/background-pattern-blue.svg'],
  },
  intro_audio: introAudioInput,
  outro_audio: {
    label: 'Outro audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_end_celebration.mp3'],
  },
  correct_audio: {
    label: 'Correct audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_correct_answer.mp3'],
  },
  letter: {
    label: 'Letter',
    inputType: InputType.SELECT,
    options: supportedLetters.map((letter) => ({
      label: letter,
      value: letter,
    })),
  },
  letterAudio: {
    label: 'Letter audio',
    inputType: InputType.AUDIO,
  },
  endUnit: {
    label: 'End unit',
    inputType: InputType.GROUP,
    groupInputConfig: {
      childConfig: {
        image: {
          label: 'Image',
          inputType: InputType.ASSET,
        },
        audio: { label: 'Audio', inputType: InputType.AUDIO },
      },
    },
  },
}

export const generateTracingPhonicsJson = (formState: Record<any, any>) => {
  const endUnit = formatAssetForOutput(formState.endUnit.endUnit1.image[0])

  const gameSchema = new GameSchema(GameType.TRACING_PHONICS, formState)

  const letterSvgAsset: IAsset = {
    name: `tracing_phonics_letter_${formState.letter}.svg`,
    type: AssetType.IMAGE,
  }

  const letterAnimationAsset: IAsset = {
    name: `tracing_phonics_letter_${formState.letter}.json`,
    type: AssetType.ANIMATION,
  }

  gameSchema.addAsset(letterSvgAsset)
  gameSchema.addAsset(letterAnimationAsset)
  gameSchema.addAudioFromAssets('letter_audio', formState.letterAudio)
  gameSchema.addAudioFromAssets(
    'end_unit_audio',
    formState.endUnit.endUnit1.audio,
  )
  gameSchema.addAsset(formState.endUnit.endUnit1.image[0])

  const letter = {
    svgName: formatAssetToString(letterSvgAsset),
    animationName: formatAssetToString(letterAnimationAsset),
  }

  return gameSchema.generate({
    letter,
    endUnit,
  })
}
