import { SpeechRecognitionType } from 'src/constants/types'
import { FormReqs, InputConfig, InputType } from './types'
import {
  formatAssetToString,
  formatAssetsToStrings,
  removeExtension,
  partitionImageTypes,
} from './utils'

export const introAudioInput = {
  label: 'Intro audio',
  inputType: InputType.AUDIO,
  default: ['audio/sfx_incorrect_bloop.mp3'],
}

export const outroAudioInput = {
  label: 'Outro audio',
  inputType: InputType.AUDIO,
  default: ['audio/sfx_end_celebration.mp3'],
}

export const correctAudioInput = {
  label: 'Correct audio',
  inputType: InputType.AUDIO,
  default: ['audio/sfx_ting.mp3'],
}

export const incorrectAudioInput = {
  label: 'Incorrect audio',
  inputType: InputType.AUDIO,
  default: ['audio/sfx_incorrect_bloop.mp3'],
}

export const speechRecognitionTypeInput: InputConfig = {
  label: 'Speech Recognition Type',
  inputType: InputType.SELECT,
  options: [
    { label: 'Words and phrases (default)', value: SpeechRecognitionType.WORD },
    { label: 'Phonics', value: SpeechRecognitionType.PHONEME },
  ],
  default: [SpeechRecognitionType.WORD],
}

export const standardAudioFormReqs: FormReqs = {
  intro_audio: introAudioInput,
  outro_audio: outroAudioInput,
}

export const standardFormReqs: FormReqs = {
  background: {
    label: 'Background image',
    inputType: InputType.IMAGE,
    default: ['image/background-pattern-blue.svg'],
  },
  ...standardAudioFormReqs,
}

export const cardInput = {
  label: 'Card Design',
  inputType: InputType.GROUP,
  groupInputConfig: {
    childConfig: {
      backFace: {
        label: 'Back face',
        inputType: InputType.IMAGE,
        default: ['image/ui_card_back_question_mark_blue.svg'],
      },
      frontFace: {
        label: 'Front face',
        inputType: InputType.IMAGE,
        default: ['image/ui_card_front_question_mark_blue.svg'],
      },
    },
  },
}

export const localeInput = {
  label: 'Language (optional)',
  inputType: InputType.SELECT,
  options: [
    { label: 'English', value: 'en-US' },
    { label: 'Simplified Chinese', value: 'zh-CN' },
    { label: 'Spanish', value: 'es-ES' },
    { label: 'French', value: 'fr-FR' },
  ],
  isOptional: true,
}

export const generateStandardAudio = (
  formState: Record<keyof typeof standardFormReqs, any>,
) => {
  const audio: any = {}

  if (formState.intro_audio) {
    audio.intro_audio = formatAssetsToStrings(formState.intro_audio)
  }

  if (formState.outro_audio) {
    audio.outro_audio = formatAssetsToStrings(formState.outro_audio)
  }

  if (formState.correct_audio) {
    audio.correct_audio = formatAssetsToStrings(formState.correct_audio)
  }

  if (formState.incorrect_audio) {
    audio.incorrect_audio = formatAssetsToStrings(formState.incorrect_audio)
  }

  return {
    ...audio,
  }
}

const getFormattedImagesFromState = (
  formReqs: any,
  formState: Record<keyof typeof standardFormReqs, any>,
) => {
  const svgs: string[] = []
  const jsons: string[] = []

  const [svgsFromLevel, jsonsFromLevel] = getFormattedImagesFromLevel(
    formReqs,
    formState,
  )
  svgs.push(...svgsFromLevel)
  jsons.push(...jsonsFromLevel)

  const { svgs: svgsFromGroup, jsons: jsonsFromGroup } =
    getFormattedImagesFromGroup(formReqs, formState)

  svgs.push(...svgsFromGroup)
  jsons.push(...jsonsFromGroup)

  return { svgs, jsons }
}

const getFormattedImagesFromGroup = (
  formReqs: any,
  formState: Record<keyof typeof standardFormReqs, any>,
) => {
  const svgs: string[] = []
  const jsons: string[] = []

  const groupKeys = Object.keys(formState).filter(
    (inputKey) =>
      formReqs[inputKey].inputType === InputType.REPEATABLE_GROUP ||
      formReqs[inputKey].inputType === InputType.GROUP,
  )

  groupKeys.map((groupKey) => {
    const groupChildKeys = Object.keys(formState[groupKey])

    groupChildKeys.forEach((childKey) => {
      const groupChildState = formState[groupKey][childKey]

      const { inputType } = formReqs[groupKey]

      const groupChildReqs =
        inputType === InputType.REPEATABLE_GROUP
          ? formReqs[groupKey].repeatableInputConfig.childConfig
          : formReqs[groupKey].groupInputConfig.childConfig

      const { svgs: stateSvgs, jsons: stateJsons } =
        getFormattedImagesFromState(groupChildReqs, groupChildState)

      svgs.push(...stateSvgs)
      jsons.push(...stateJsons)
    })

    return groupChildKeys
  })

  return { svgs, jsons }
}

const getFormattedImagesFromLevel = (
  formReqs: any,
  formState: Record<keyof typeof standardFormReqs, any>,
) => {
  const images = Object.keys(formReqs)
    .filter((inputKey) => formReqs[inputKey].inputType === InputType.IMAGE)
    .map((imageInputKey) => formState[imageInputKey][0])

  return partitionImageTypes(images)
}

export const generateStandardAssetCacheOutput = (
  formReqs: any,
  formState: Record<keyof typeof standardFormReqs, any>,
) => {
  const assetMap: any = {}

  const keys: any = {}

  const mp4s: string[] = []

  const { svgs, jsons } = getFormattedImagesFromState(formReqs, formState)

  Object.keys(formReqs)
    .filter((inputKey) => formReqs[inputKey].inputType === InputType.VIDEO)
    .forEach((videoInputKey) => {
      assetMap[videoInputKey] = removeExtension(
        formState[videoInputKey][0].streamingUrl,
      )

      keys[videoInputKey] = videoInputKey

      mp4s.push(formatAssetToString(formState[videoInputKey][0]))
    })

  const assets = { ...keys }

  if (svgs.length > 0) {
    assets.images = { svgs }
  }
  if (jsons.length > 0) {
    assets.animations = { jsons }
  }

  if (Object.keys(assetMap).length > 0) {
    assets.assetMap = assetMap
  }

  if (mp4s.length > 0) {
    assets.videos = { mp4s }
  }

  return assets
}

export const generateStandardJson = (
  formState: Record<keyof typeof standardFormReqs, any>,
) => {
  const audio = generateStandardAudio(formState)

  return {
    hasReward: false,
    autoScaleJsonAssets: true,
    background: {
      main: formatAssetToString(formState.background[0]),
    },
    audio,
    images: {
      svgs: [formatAssetToString(formState.background[0])],
    },
  }
}
