import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { InputConfig } from 'src/gameSchemas/types'

const NumberFormGroup = (props: {
  formId: string
  inputConfig: InputConfig
  onChange: (value: number) => void
  value: number
}) => (
  <Form.Group as={Row} controlId={props.formId}>
    <Form.Label column sm="2">
      {props.inputConfig.label}
    </Form.Label>
    <Col sm="10">
      <Form.Control
        type="number"
        placeholder="Type number here"
        onChange={(e: React.ChangeEvent<any>) => {
          props.onChange(e.target.value)
        }}
        value={props.value}
        name={props.formId}
      />
    </Col>
  </Form.Group>
)

export default NumberFormGroup
