import { generateStandardJson, standardFormReqs } from './standard'
import {
  formatAssetForOutput,
  formatAssetToString,
  formatAssetsToStrings,
  removeExtension,
} from './utils'
import { InputType } from './types'

const getUnitNames = (formState: any) => {
  if (!formState.unit) {
    return []
  }

  const unitNames: { label: string; value: string }[] = []

  Object.values(formState.unit).forEach((unit: any) => {
    if (unit.image && unit.image[0]) {
      const unitImageName = unit.image[0].name
      unitNames.push({
        label: removeExtension(unitImageName),
        value: removeExtension(unitImageName),
      })
    }
  })

  return unitNames
}

export const getThisOrThatFormReqs = (formState: any) => ({
  ...standardFormReqs,
  teacherBoxBackground: {
    label: 'Teacher box background image (optional)',
    inputType: InputType.IMAGE,
    isOptional: true,
  },
  unit: {
    label: 'Unit',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      childConfig: {
        image: { label: 'Image', inputType: InputType.IMAGE },
      },
      quantity: 2,
      maximum: 4,
    },
  },
  question: {
    label: 'Question',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      quantity: 2,
      maximum: 2,
      childConfig: {
        character: {
          label: 'Character',
          inputType: InputType.SELECT,
          options: [
            { label: 'Teacher Hannah', value: 'teacher_hannah' },
            { label: 'Teacher Luke', value: 'teacher_luke' },
          ],
        },
        prompt: {
          label: 'Prompt',
          inputType: InputType.AUDIO,
        },
        accept: {
          label: 'Accept',
          inputType: InputType.AUDIO,
        },
        reject: {
          label: 'Reject',
          inputType: InputType.AUDIO,
        },
        correctAnswer: {
          label: 'Correct Answer',
          inputType: InputType.REPEATABLE_GROUP,
          repeatableInputConfig: {
            quantity: 1,
            maximum: 6,
            childConfig: {
              answer: {
                label: 'Unit',
                inputType: InputType.SELECT,
                options: getUnitNames(formState),
              },
              quantity: {
                label: 'Quantity',
                inputType: InputType.NUMBER,
                default: ['1'],
              },
            },
          },
        },
      },
    },
  },
  correct_audio: {
    label: 'Correct audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_ting.mp3'],
  },
  incorrect_audio: {
    label: 'Incorrect audio',
    inputType: InputType.AUDIO,
    default: ['audio/sfx_incorrect_bloop.mp3'],
  },
})

export const thisOrThatCheckGenerateJson = (formState: Record<any, any>) => {
  const standardConfig = generateStandardJson(formState)

  const units = Object.values(formState.unit).map((unit: any) => unit.image)

  const unitsFormatted = units.map((unit: any) => formatAssetForOutput(unit[0]))

  const unitImages = units.map((unit: any) => formatAssetToString(unit[0]))

  const characterAudioFiles: Record<string, string[]> = {}

  const questions = Object.values(formState.question).map(
    (question: any, questionIndex) => {
      const correctAnswers = Object.values(question.correctAnswer).map(
        (correctAnswer: any) => ({
          name: correctAnswer.answer,
          quantity: correctAnswer.quantity || 1,
        }),
      )

      let character = {
        idleState: 'animation/anim_teacher_hannah_idle',
        speakingState: 'animation/anim_teacher_hannah_speaking',
        lookDownState: 'animation/anim_teacher_hannah_looking_down',
        lookDownExcitedState:
          'animation/anim_teacher_hannah_looking_down_and_excited',
        shakingHeadState: 'animation/anim_teacher_hannah_sad_shaking_head',
        celebrationState: 'animation/anim_teacher_hannah_celebrating',
      }

      if (question.character === 'teacher_luke') {
        character = {
          idleState: 'animation/anim_teacher_luke_idle',
          speakingState: 'animation/anim_teacher_luke_speaking',
          lookDownState: 'animation/anim_teacher_luke_looking_down',
          lookDownExcitedState:
            'animation/anim_teacher_luke_looking_down_and_excited',
          shakingHeadState: 'animation/anim_teacher_luke_sad_shaking_head',
          celebrationState: 'animation/anim_teacher_luke_celebrating',
        }
      }

      const prompt = `prompt_audio_${questionIndex + 1}`
      const accept = `accept_audio_${questionIndex + 1}`
      const reject = `reject_audio_${questionIndex + 1}`
      characterAudioFiles[prompt] = formatAssetsToStrings(question.prompt)
      characterAudioFiles[accept] = formatAssetsToStrings(question.accept)
      characterAudioFiles[reject] = formatAssetsToStrings(question.reject)
      return {
        correctAnswers,
        character,
        prompt,
        accept,
        reject,
      }
    },
  )

  let characterAnimations: string[] = []

  questions.forEach((question) => {
    characterAnimations = [
      ...characterAnimations,
      ...Object.values(question.character),
    ]
  })

  const svgs = [...standardConfig.images.svgs, ...unitImages]

  if (
    formState.teacherBoxBackground &&
    formState.teacherBoxBackground.length > 0
  ) {
    svgs.push(formatAssetToString(formState.teacherBoxBackground[0]))
  }

  const jsons = [...characterAnimations]

  return {
    ...standardConfig,
    gameType: 'THIS_OR_THAT_CHECK',
    audio: {
      ...standardConfig.audio,
      correct_audio: formatAssetsToStrings(formState.correct_audio),
      incorrect_audio: formatAssetsToStrings(formState.incorrect_audio),
      ...characterAudioFiles,
      outro_celebration_audio: ['audio/sfx_firework'],
      units_land_in_tray_audio: ['audio/sfx_incorrect_bloop'],
      units_outro_audio: ['audio/sfx_incorrect_bloop'],
    },
    units: unitsFormatted,
    questions,
    ...(formState.teacherBoxBackground &&
    formState.teacherBoxBackground.length > 0
      ? {
          teacherBoxBackground: formatAssetForOutput(
            formState.teacherBoxBackground[0],
          ),
        }
      : {}),

    images: {
      svgs,
    },
    animations: {
      jsons,
    },
  }
}
