import _ from 'lodash'
import { InputType, FormReqs } from './types'
import {
  combineSearchTerms,
  formatAssetToString,
  formatAssetsToStrings,
  getExtension,
  partitionImageTypes,
  removeExtension,
} from './utils'

export const tapRevealFormReqs: FormReqs = {
  background: {
    label: 'Background image',
    inputType: InputType.IMAGE,
    default: ['image/blue_jungle_background.svg'],
  },
  foreground: {
    label: 'Foreground image',
    inputType: InputType.IMAGE,
    default: ['image/jungle_foreground.svg'],
  },
  unit1Image: {
    label: 'Unit 1 image',
    inputType: InputType.IMAGE,
  },
  unit1AcceptAudio: {
    label: 'Unit 1 Audio',
    inputType: InputType.AUDIO,
  },
  unit1SkillMeasurements: {
    label: 'Unit 1 skill measurements',
    inputType: InputType.SKILL_MEASUREMENTS,
  },
  unit2Image: {
    label: 'Unit 2 image',
    inputType: InputType.IMAGE,
  },
  unit2AcceptAudio: {
    label: 'Unit 2 Audio',
    inputType: InputType.AUDIO,
  },
  unit2SkillMeasurements: {
    label: 'Unit 2 skill measurements',
    inputType: InputType.SKILL_MEASUREMENTS,
  },
  unit3Image: {
    label: 'Unit 3 image',
    inputType: InputType.IMAGE,
  },
  unit3AcceptAudio: {
    label: 'Unit 3 Audio',
    inputType: InputType.AUDIO,
  },
  unit3SkillMeasurements: {
    label: 'Unit 3 skill measurements',
    inputType: InputType.SKILL_MEASUREMENTS,
  },
  unit4Image: {
    label: 'Unit 4 image',
    inputType: InputType.IMAGE,
  },
  unit4AcceptAudio: {
    label: 'Unit 4 Audio',
    inputType: InputType.AUDIO,
  },
  unit4SkillMeasurements: {
    label: 'Unit 4 skill measurements',
    inputType: InputType.SKILL_MEASUREMENTS,
  },
}

export const tapRevealGenerateJson = (
  formState: Record<keyof typeof tapRevealFormReqs, any>,
) => {
  const unitImages = [
    formState.unit1Image[0],
    formState.unit2Image[0],
    formState.unit3Image[0],
    formState.unit4Image[0],
  ]
  const [images, jsons] = partitionImageTypes(unitImages)
  return {
    gameType: 'LABEL',
    hasReward: false,
    repeatTappableQuestion: true,
    autoScaleJsonAssets: true,
    units: [
      {
        name: removeExtension(formState.unit1Image[0].name),
        image: formatAssetToString(formState.unit1Image[0]),
        type: getExtension(formState.unit1Image[0].name),
        accept: combineSearchTerms(formState.unit1AcceptAudio),
        skillMeasurements: [formState.unit1SkillMeasurements],
      },
      {
        name: removeExtension(formState.unit2Image[0].name),
        image: formatAssetToString(formState.unit2Image[0]),
        type: getExtension(formState.unit2Image[0].name),
        accept: combineSearchTerms(formState.unit2AcceptAudio),
        skillMeasurements: [formState.unit2SkillMeasurements],
      },
      {
        name: removeExtension(formState.unit3Image[0].name),
        image: formatAssetToString(formState.unit3Image[0]),
        type: getExtension(formState.unit3Image[0].name),
        accept: combineSearchTerms(formState.unit3AcceptAudio),
        skillMeasurements: [formState.unit3SkillMeasurements],
      },
      {
        name: removeExtension(formState.unit4Image[0].name),
        image: formatAssetToString(formState.unit4Image[0]),
        type: getExtension(formState.unit4Image[0].name),
        accept: combineSearchTerms(formState.unit4AcceptAudio),
        skillMeasurements: [formState.unit4SkillMeasurements],
      },
    ],
    background: {
      main: formatAssetToString(formState.background[0]),
    },
    foreground: formatAssetToString(formState.foreground[0]),
    audio: {
      [combineSearchTerms(formState.unit1AcceptAudio)]: formatAssetsToStrings(
        formState.unit1AcceptAudio,
      ),
      [combineSearchTerms(formState.unit2AcceptAudio)]: formatAssetsToStrings(
        formState.unit2AcceptAudio,
      ),
      [combineSearchTerms(formState.unit3AcceptAudio)]: formatAssetsToStrings(
        formState.unit3AcceptAudio,
      ),
      [combineSearchTerms(formState.unit4AcceptAudio)]: formatAssetsToStrings(
        formState.unit4AcceptAudio,
      ),
      correct_audio: ['audio/sfx_incorrect_bloop'],
      intro_audio: ['audio/sfx_incorrect_bloop'],
      outro_audio: ['audio/sfx_celebration_cheer'],
    },
    images: {
      svgs: [
        formatAssetToString(formState.background[0]),
        formatAssetToString(formState.foreground[0]),
        ...images,
      ],
    },
    animations: {
      jsons,
    },
  }
}
