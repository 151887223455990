import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { InputConfig } from 'src/gameSchemas/types'

const SelectFormGroup = (props: {
  formId: string
  /** If this SelectFormGroup is within a repeatable group,
   * repeatableGroupIndices is an array of integers representing which index of the group we are in.
   * This can be used to load options for this SelectFormGroup which are
   * specific to that repeatable group.
   * For example, let's say we have a question repeatable group,
   * each one containg a SelectFormGroup and a list of units.
   * If we wanted SelectFormGroup to display the units of the question it is in,
   * then we can use repeatableGroupIndices to work out whether we are in
   * question 1, question 2, etc.
   * This is an array because we could have multiple levels of nesting.
   * The first element in the array is the highest parent,
   * the last element in the array is the direct parent of this SelectFormGroup.   */
  repeatableGroupIndices?: number[]
  inputConfig: InputConfig
  onChange: (value: string) => void
  value: string
}) => {
  if (!props.inputConfig.options) {
    throw new Error('No options provided for Select input config')
  }

  const options =
    typeof props.inputConfig.options === 'function' &&
    props.repeatableGroupIndices !== undefined
      ? props.inputConfig.options(props.repeatableGroupIndices)
      : props.inputConfig.options

  if (!options) {
    throw new Error('Invalid options provided for Select input config')
  }

  return (
    <Form.Group as={Row} controlId={props.formId}>
      <Form.Label column sm="2">
        {props.inputConfig.label}
      </Form.Label>
      <Col sm="10">
        <Form.Control
          as="select"
          onChange={(e) => props.onChange(e.target.value)}
          value={props.value}
          name={props.formId}
        >
          <option value="">--Choose an option--</option>
          {(options as { label: string; value: string }[]).map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </Form.Control>
        {props.inputConfig.description && (
          <div
            style={{
              fontSize: '10pt',
              fontStyle: 'italic',
              marginTop: 10,
              color: 'grey',
            }}
          >
            {props.inputConfig.description}
          </div>
        )}
      </Col>
    </Form.Group>
  )
}
export default SelectFormGroup
