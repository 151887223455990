import React from 'react'
import AuthenticationButton from './AuthenticationButton'

const AuthNav = () => (
  <div className="navbar-nav ml-auto">
    <AuthenticationButton />
  </div>
)

export default AuthNav
