import React, { useState } from 'react'
import JSONPretty from 'react-json-pretty'

import { AutoSuggestFormGroup } from 'src/forms/AutoSuggestFormGroup'
import { removeExtension } from 'src/gameSchemas/utils'
import { Asset, AssetType, VideoType } from '../../constants/types'
import Heading from '../../components/Heading'
import { useSuggestions } from '../utils/hooks'

const makeVideoConfig = (video: Asset[]) => {
  if (video[0].streamingUrl) {
    return {
      folder: `${removeExtension(video[0].streamingUrl)}`,
      type: VideoType.STREAMING,
      isLandscape: true,
    }
  }
  return {
    folder: `video/${removeExtension(video[0].name)}`,
    type: 'AMS',
    isLandscape: true,
  }
}

const VideoForm = () => {
  const [video, setVideo] = useState<Asset[] | null>(null)
  const { suggestions, getSuggestions } = useSuggestions()

  const json = (video && video[0] && makeVideoConfig(video)) || null

  return (
    <>
      <Heading as="h2">Select video</Heading>
      <AutoSuggestFormGroup
        maxTags={1}
        controlId="addVideo"
        label="Video"
        assetTypes={[AssetType.VIDEO]}
        suggestions={suggestions}
        onFetchSuggestions={({ query, assetTypes }) =>
          getSuggestions({
            query,
            assetTypes,
          })
        }
        onSelectedSuggestion={(result) => {
          setVideo(result)
        }}
        selectedAssets={video}
      />
      <JSONPretty id="json-pretty" data={json} />
    </>
  )
}

export default VideoForm
