import React, { FormEvent, useContext, useState } from 'react'
import styled from 'styled-components'
import JSONPretty from 'react-json-pretty'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'

import { AutoSuggestFormGroup } from 'src/forms/AutoSuggestFormGroup'
import Heading from 'src/components/Heading'
import Line from 'src/components/Line'
import { useSuggestions } from 'src/views/utils/hooks'
import { generateLessonOverviewJson } from 'src/views/utils/helpers'
import {
  AssetType,
  LessonOverviewAssets,
  LessonOverviewAssetType,
} from 'src/constants/types'
import { TitledAudioPlayer } from 'src/views/previews/TitledAudioPlayer'
import { TitledImagePreview } from 'src/views/previews/TitledImagePreview'

import ContextProvider, {
  LessonConfigContext,
  LessonConfigContextType,
} from './context'

const UnitPreviewContainer = styled.div``

const StyledButton = styled(Button)`
  display: block;
  margin-bottom: 8px;
`

const Preview = ({ assets }: { assets: LessonOverviewAssets }) => (
  <UnitPreviewContainer>
    <TitledAudioPlayer title="Audio" assets={assets.audioAssets} />
    <TitledImagePreview
      title="Image"
      asset={assets.imageAsset ? assets.imageAsset[0] : undefined}
    />
  </UnitPreviewContainer>
)

const LessonForm = () => {
  const { words, addAnotherAsset, sentences, onSetConfig } = useContext(
    LessonConfigContext,
  ) as LessonConfigContextType
  const { suggestions, getSuggestions } = useSuggestions()
  const [json, setJson] = useState<object | null>(null)

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setJson(generateLessonOverviewJson({ words, sentences }))
  }

  const handleTextChange = (
    e: React.ChangeEvent<any>,
    index: number,
    assets: LessonOverviewAssets,
  ) => {
    const { value } = e.target

    onSetConfig(
      {
        ...assets,
        text: value,
      },
      index,
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Heading as="h2">Words</Heading>
      <Line />
      {Object.keys(words).map((wordKey) => {
        const index = Number(wordKey)
        const word = words[Number(wordKey)]
        return (
          <Row key={wordKey}>
            <Col>
              <Form.Group as={Row} controlId="word-text">
                <Form.Label column sm="4">
                  Word
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    placeholder="Enter the word"
                    onChange={(e) => handleTextChange(e, index, word)}
                    value={word.text}
                    name="word-text"
                  />
                </Col>
              </Form.Group>
              <AutoSuggestFormGroup
                label="Audio"
                controlId="word-audio"
                assetTypes={[AssetType.AUDIO]}
                suggestions={suggestions}
                onFetchSuggestions={({ query, assetTypes }) =>
                  getSuggestions({
                    query,
                    assetTypes,
                  })
                }
                onSelectedSuggestion={(selectedAudioAssets) =>
                  onSetConfig(
                    {
                      ...word,
                      audioAssets: selectedAudioAssets,
                    },
                    index,
                  )
                }
                selectedAssets={word.audioAssets}
              />
              <AutoSuggestFormGroup
                label="Image"
                controlId="word-image"
                assetTypes={[AssetType.IMAGE, AssetType.ANIMATION]}
                suggestions={suggestions}
                onFetchSuggestions={({ query, assetTypes }) =>
                  getSuggestions({
                    query,
                    assetTypes,
                  })
                }
                onSelectedSuggestion={(selectedImageAssets) => {
                  onSetConfig(
                    {
                      ...word,
                      imageAsset: selectedImageAssets,
                    },
                    index,
                  )
                }}
                selectedAssets={word.imageAsset ? word.imageAsset : []}
              />
            </Col>
            <Col>
              <Preview assets={word} key={wordKey} />
            </Col>
          </Row>
        )
      })}
      <Row>
        <StyledButton
          variant="link"
          onClick={() => {
            addAnotherAsset(LessonOverviewAssetType.WORD)
          }}
        >
          + Add another word
        </StyledButton>
      </Row>

      <Heading as="h2">Sentences</Heading>
      <Line />
      {Object.keys(sentences).map((sentenceKey) => {
        const index = Number(sentenceKey)
        const sentence = sentences[Number(sentenceKey)]
        return (
          <Row key={sentenceKey}>
            <Col>
              <Form.Group as={Row} controlId="sentence-text">
                <Form.Label column sm="4">
                  Sentence
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    placeholder="Enter the sentence"
                    onChange={(e) => handleTextChange(e, index, sentence)}
                    value={sentence.text}
                    name="sentence-text"
                  />
                </Col>
              </Form.Group>
              <AutoSuggestFormGroup
                label="Audio"
                controlId="sentence-audio"
                assetTypes={[AssetType.AUDIO]}
                suggestions={suggestions}
                onFetchSuggestions={({ query, assetTypes }) =>
                  getSuggestions({
                    query,
                    assetTypes,
                  })
                }
                onSelectedSuggestion={(selectedAudioAssets) =>
                  onSetConfig(
                    {
                      ...sentence,
                      audioAssets: selectedAudioAssets,
                    },
                    index,
                  )
                }
                selectedAssets={sentence.audioAssets}
              />
            </Col>
            <Col>
              <Preview assets={sentence} key={sentenceKey} />
            </Col>
          </Row>
        )
      })}
      <Row>
        <StyledButton
          variant="link"
          onClick={() => {
            addAnotherAsset(LessonOverviewAssetType.SENTENCE)
          }}
        >
          + Add another sentence
        </StyledButton>
      </Row>

      <StyledButton type="submit" disabled={!words[0].text}>
        Generate Config
      </StyledButton>
      <JSONPretty id="json-pretty" data={json} />
    </Form>
  )
}

const CreateLessonOverview = () => (
  <ContextProvider>
    <Container>
      <LessonForm />
    </Container>
  </ContextProvider>
)

export default CreateLessonOverview
