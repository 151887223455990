import React from 'react'
import { Asset, AssetType } from 'src/constants/types'
import { InputConfig, InputType } from 'src/gameSchemas/types'
import { StyledButton } from './CreateGameForm'
import RepeatableFormGroup from './RepeatableFormGroup'

interface RepeatableFormGroupListProps {
  repeatableKey: string
  setFormState: React.Dispatch<React.SetStateAction<{}>>
  inputConfig: InputConfig
  repeatableGroupIndices?: number[]
  suggestions: Asset[]
  getSuggestions: ({
    query,
    assetTypes,
  }: {
    query: string
    assetTypes: AssetType[]
  }) => Promise<void>
  onUpdate: (childState: Record<string, any>) => void
  headingLevel: 'h1' | 'h2' | 'h3'
  includeLine: boolean
  localFormState: Record<string, any>
}

const RepeatableFormGroupList = (props: RepeatableFormGroupListProps) => {
  const {
    setFormState,
    inputConfig,
    suggestions,
    getSuggestions,
    onUpdate,
    repeatableKey,
    headingLevel,
    includeLine,
    localFormState,
    repeatableGroupIndices,
  } = props

  const groupConfig =
    inputConfig.repeatableInputConfig || inputConfig.groupInputConfig

  if (!groupConfig) {
    return null
  }

  const isRepeatable = inputConfig.inputType === InputType.REPEATABLE_GROUP

  const maximum = isRepeatable ? inputConfig.repeatableInputConfig!.maximum : 1

  const { childConfig } = groupConfig

  const shouldShowAddButton = Object.keys(localFormState).length < maximum
  return (
    <>
      {Object.keys(localFormState).map((currentChildKey, index) => {
        const childIndex = index + 1

        const header = isRepeatable
          ? `${inputConfig.label} ${childIndex}`
          : inputConfig.label

        return (
          <RepeatableFormGroup
            key={`${repeatableKey}${childIndex}`}
            repeatableKey={repeatableKey}
            repeatableGroupIndices={[
              ...(repeatableGroupIndices || []),
              childIndex,
            ]}
            index={childIndex}
            header={header}
            config={childConfig}
            localFormState={localFormState || {}}
            onUpdate={(childState) => {
              const updatedState = {
                ...localFormState,
                [`${repeatableKey}${childIndex}`]: childState,
              }
              onUpdate(updatedState)
            }}
            suggestions={suggestions}
            getSuggestions={getSuggestions}
            setFormState={setFormState}
            includeLine={includeLine}
            headingLevel={headingLevel}
          />
        )
      })}
      {shouldShowAddButton ? (
        <StyledButton
          variant="link"
          onClick={() => {
            const repeatableCount = Object.keys(localFormState).length
            const updatedState = {
              ...localFormState,
              [`${repeatableKey}${repeatableCount + 1}`]: {},
            }
            onUpdate(updatedState)
            return updatedState
          }}
        >
          Add another {inputConfig.label.toLowerCase()}
        </StyledButton>
      ) : null}
    </>
  )
}

export default RepeatableFormGroupList
