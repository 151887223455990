import React from 'react'
import { Asset, AssetType } from 'src/constants/types'
import { InputConfig, InputType } from 'src/gameSchemas/types'
import { Col, Row } from 'react-bootstrap'
import ImageFormGroup from './ImageFormGroup'
import TextFormGroup from './TextFormGroup'
import VideoFormGroup from './VideoFormGroup'
import RepeatableFormGroupList from './RepeatableFormGroupList'
import SkillMeasurementsFormGroup from './SkillMeasurementsFormGroup'
import { TitledAudioPlayer } from '../previews/TitledAudioPlayer'
import AudioFormGroup from './AudioFormGroup'
import DefaultCheckbox from './DefaultCheckbox'
import { TitledImagePreview } from '../previews/TitledImagePreview'
import SelectFormGroup from './SelectFormGroup'
import AssetFormGroup from './AssetFormGroup'
import NumberFormGroup from './NumberFormGroup'

interface InputConfigFormGroupProps {
  formId: string
  formState: Record<string, any>
  inputConfig: InputConfig
  // If this is part of a repeatable group, what is the index of that group?
  repeatableGroupIndices?: number[]
  value: string | Asset[]
  onChange: (
    value: string | number | Asset[] | Record<string, any> | undefined,
  ) => void
  suggestions: Asset[]
  getSuggestions: ({
    query,
    assetTypes,
  }: {
    query: string
    assetTypes: AssetType[]
  }) => Promise<void>
  setFormState: React.Dispatch<React.SetStateAction<{}>>
}

const InputConfigFormGroup = (props: InputConfigFormGroupProps) => {
  if (props.inputConfig.inputType === InputType.TEXT_INPUT) {
    const value = props.value as string
    return <TextFormGroup {...props} onChange={props.onChange} value={value} />
  }

  if (props.inputConfig.inputType === InputType.NUMBER) {
    const value = props.value as unknown as number
    return (
      <>
        <NumberFormGroup {...props} onChange={props.onChange} value={value} />
        {props.inputConfig.default && (
          <DefaultCheckbox {...props} onUpdate={props.onChange} />
        )}
      </>
    )
  }

  if (props.inputConfig.inputType === InputType.IMAGE) {
    const value = props.value as Asset[]
    return (
      <Row>
        <Col>
          <ImageFormGroup
            {...props}
            onSelect={props.onChange}
            value={props.formState[props.formId] as Asset[]}
          />
          {props.inputConfig.default && (
            <DefaultCheckbox {...props} onUpdate={props.onChange} />
          )}
        </Col>
        <Col>
          <TitledImagePreview
            title={props.inputConfig.label}
            asset={value && value[0]}
          />
        </Col>
      </Row>
    )
  }

  if (props.inputConfig.inputType === InputType.AUDIO) {
    return (
      <Row>
        <Col>
          <AudioFormGroup
            {...props}
            onSelect={props.onChange}
            value={props.formState[props.formId] as Asset[]}
          />
          {props.inputConfig.default && (
            <DefaultCheckbox {...props} onUpdate={props.onChange} />
          )}
        </Col>
        <Col>
          <TitledAudioPlayer
            title={props.inputConfig.label}
            assets={props.formState[props.formId] as Asset[]}
          />
        </Col>
      </Row>
    )
  }

  if (props.inputConfig.inputType === InputType.VIDEO) {
    const value = props.value as Asset[]
    return (
      <>
        <VideoFormGroup {...props} onSelect={props.onChange} value={value} />
        {props.inputConfig.default && (
          <DefaultCheckbox {...props} onUpdate={props.onChange} />
        )}
      </>
    )
  }

  if (props.inputConfig.inputType === InputType.ASSET) {
    const value = props.value as Asset[]

    const isVideoAsset =
      value && value.length > 0 && value[0].type === AssetType.VIDEO

    return (
      <Row>
        <Col sm={isVideoAsset ? '12' : '6'}>
          <AssetFormGroup
            {...props}
            onSelect={props.onChange}
            value={props.formState[props.formId] as Asset[]}
          />
          {props.inputConfig.default && (
            <DefaultCheckbox {...props} onUpdate={props.onChange} />
          )}
          {props.inputConfig.description && (
            <div
              style={{
                fontSize: '10pt',
                fontStyle: 'italic',
                marginTop: 10,
                color: 'grey',
              }}
            >
              {props.inputConfig.description}
            </div>
          )}
        </Col>
        {!isVideoAsset && (
          <Col>
            <TitledImagePreview
              title={props.inputConfig.label}
              asset={value && value[0]}
            />
          </Col>
        )}
      </Row>
    )
  }

  if (props.inputConfig.inputType === InputType.SKILL_MEASUREMENTS) {
    return (
      <Row>
        <Col sm="6">
          <SkillMeasurementsFormGroup
            {...props}
            onChange={props.onChange}
            value={props.formState[props.formId] as Record<string, any>}
          />
        </Col>
      </Row>
    )
  }

  if (
    props.inputConfig.inputType === InputType.REPEATABLE_GROUP ||
    props.inputConfig.inputType === InputType.GROUP
  ) {
    return (
      <RepeatableFormGroupList
        {...props}
        localFormState={props.formState[props.formId] || {}}
        repeatableKey={props.formId}
        repeatableGroupIndices={props.repeatableGroupIndices}
        inputConfig={props.inputConfig}
        onUpdate={props.onChange}
        setFormState={props.setFormState}
        includeLine={false}
        headingLevel="h3"
      />
    )
  }

  if (props.inputConfig.inputType === InputType.SELECT) {
    const value = props.value as string
    return <SelectFormGroup {...props} value={value} />
  }

  return null
}

export default InputConfigFormGroup
