import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Line from 'src/components/Line'
import { SkillMeasurementType } from 'src/constants/types'
import { InputConfig } from 'src/gameSchemas/types'

const SkillMeasurementsFormGroup = (props: {
  formId: string
  inputConfig: InputConfig
  value?: Record<string, any>
  onChange: (childState: Record<string, any>) => void
}) => {
  const currentSkillMeasurement = props.value || {}

  return (
    <>
      <Form.Label column sm="4">
        {props.inputConfig.label}
      </Form.Label>
      <Form.Group as={Row} controlId={props.formId}>
        <Form.Label column sm="4">
          Skill
        </Form.Label>
        <Col sm="8">
          <Form.Control
            placeholder="Add skill e.g. 'the [noun] is [adj]'"
            onChange={(e) =>
              props.onChange({
                ...currentSkillMeasurement,
                skill: e.target.value,
              })
            }
            value={currentSkillMeasurement.skill || ''}
            name="skill"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId={props.formId}>
        <Form.Label column sm="4">
          Type
        </Form.Label>
        <Col sm="8">
          <Form.Control
            as="select"
            onChange={(e) =>
              props.onChange({
                ...currentSkillMeasurement,
                measurementType: e.target.value,
              })
            }
            value={currentSkillMeasurement.measurementType || ''}
            name="measurement type"
          >
            <option value="">--Choose an option--</option>
            <option value={SkillMeasurementType.COPIED}>Copied</option>
            <option value={SkillMeasurementType.SELECTED}>Selected</option>
            <option value={SkillMeasurementType.RESPONDED_WITH}>
              Responded with
            </option>
            <option value={SkillMeasurementType.EXPOSED_TO}>Exposed to</option>
          </Form.Control>
        </Col>
      </Form.Group>
      <Line />
    </>
  )
}

export default SkillMeasurementsFormGroup
