import React from 'react'
import { Asset, AssetType } from 'src/constants/types'
import { AutoSuggestFormGroup } from 'src/forms/AutoSuggestFormGroup'
import { InputConfig } from 'src/gameSchemas/types'

interface AudioFormGroupProps {
  formId: string
  inputConfig: InputConfig
  suggestions: Asset[]
  getSuggestions: ({
    query,
    assetTypes,
  }: {
    query: string
    assetTypes: AssetType[]
  }) => Promise<void>
  onSelect: (audio: Asset[]) => void
  value: Asset[] | null
}

const AudioFormGroup = (props: AudioFormGroupProps) => (
  <AutoSuggestFormGroup
    controlId={props.formId}
    label={props.inputConfig.label}
    assetTypes={[AssetType.AUDIO]}
    suggestions={props.suggestions}
    onFetchSuggestions={({ query, assetTypes }) =>
      props.getSuggestions({
        query,
        assetTypes,
      })
    }
    onSelectedSuggestion={props.onSelect}
    selectedAssets={props.value}
  />
)

export default AudioFormGroup
