import fetch from 'node-fetch'

export const get = async (token: string, url: string) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('GET request failed ')
    }

    const responseData = await response.json()
    return responseData
  } catch (error) {
    return null
  }
}

export const postForm = async (token: string, url: string, body: any = {}) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return null
  }
}

export const putForm = async (token: string, url: string, body: any = {}) => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return null
  }
}

export const post = async (token: string, url: string, body: any = {}) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    if (!response.ok) {
      throw new Error('POST request failed ')
    }
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return null
  }
}
