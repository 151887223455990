const learningAPIHostnames: { [key: string]: string } = {
  PRODUCTION: 'https://learning-api.lingumi.com',
  STAGING: 'https://learning-api-dev.lingumi.com',
  DEVELOPMENT: 'http://localhost:8080',
}

const chinaLearningAPIHostnames: { [key: string]: string } = {
  PRODUCTION: 'https://learning-api.lingumi.com.cn',
  STAGING: 'https://learning-api-dev.lingumi.com.cn',
  DEVELOPMENT: 'http://localhost:8080',
}

export const BASE_URL =
  learningAPIHostnames[process.env.REACT_APP_ENV || 'DEVELOPMENT']

export const CHINA_BASE_URL =
  chinaLearningAPIHostnames[process.env.REACT_APP_ENV || 'DEVELOPMENT']
