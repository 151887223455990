import { Asset, VideoType } from 'src/constants/types'
import { FormReqs, InputType } from './types'
import {
  formatAssetsToStrings,
  formatAssetToString,
  getExtension,
  partitionImageTypesIncludingWebp,
  removeExtension,
} from './utils'

export const tapVideoFormReqs: FormReqs = {
  outroVideo: {
    label: 'Outro Video',
    inputType: InputType.VIDEO,
  },
  variation: {
    label: 'Tappy Video Variation',
    inputType: InputType.SELECT,
    options: [
      { label: 'Normal', value: 'NORMAL' },
      { label: 'Full Screen Button', value: 'FULL_SCREEN_BUTTON' },
    ],
  },
  question: {
    label: 'Question',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      childConfig: {
        name: {
          label: 'Name',
          inputType: InputType.TEXT_INPUT,
        },
        unitImage: {
          label: 'Unit Image',
          inputType: InputType.REPEATABLE_GROUP,
          repeatableInputConfig: {
            childConfig: {
              image: {
                label: 'Image',
                inputType: InputType.IMAGE,
              },
            },
            quantity: 1,
            maximum: 4,
          },
        },
        video: {
          label: 'Video',
          inputType: InputType.VIDEO,
        },
        correctAnswer: {
          label:
            'Correct answer (needs to match unit image file name exactly including case)',
          inputType: InputType.TEXT_INPUT,
        },
      },
      quantity: 1,
      maximum: 10,
    },
  },
}

export const tapVideoGenerateJson = (
  formState: Record<keyof typeof tapVideoFormReqs, any>,
) => {
  const questions = Object.keys(formState.question).map((questionKey) => {
    const question = formState.question[questionKey]

    const unitsToDisplay = Object.keys(question.unitImage).map(
      (unitImageKey) => {
        const unitImage = question.unitImage[unitImageKey].image[0]
        return {
          name: removeExtension(unitImage.name),
          image: formatAssetToString(unitImage),
          type: getExtension(unitImage.name),
        }
      },
    )

    return {
      name: question.name,
      unitsToDisplay,
      video: formatAssetToString(question.video[0]),
      correctAnswer: question.correctAnswer,
    }
  })

  const unitImages: Asset[] = []

  Object.keys(formState.question).forEach((questionKey) => {
    const question = formState.question[questionKey]

    Object.keys(question.unitImage).forEach((unitImageKey) => {
      const unitImage = question.unitImage[unitImageKey].image[0]
      unitImages.push(unitImage)
    })
  })

  const questionVideoFiles = Object.keys(formState.question).map(
    (questionKey) => {
      const question = formState.question[questionKey]
      return question.video[0]
    },
  )

  const [svgUnitImages, jsonUnitImages, _mp4UnitImages, webpUnitImages] =
    partitionImageTypesIncludingWebp(unitImages)

  const svgs = formatAssetsToStrings(svgUnitImages)
  const jsons = formatAssetsToStrings(jsonUnitImages)
  const webps = formatAssetsToStrings(webpUnitImages)

  const allVideoFiles = [formState.outroVideo[0], ...questionVideoFiles]
  const videos = formatAssetsToStrings(allVideoFiles)
  const assetMap = allVideoFiles.reduce(
    (acc, v) => ({
      ...acc,
      [formatAssetToString(v)]: removeExtension(v.streamingUrl),
    }),
    {},
  )

  return {
    gameType: 'TAPPY_VIDEO',
    hasReward: false,
    repeatTappableQuestion: true,
    autoScaleJsonAssets: true,
    variation: formState.variation || 'NORMAL',
    outroVideo: videos[0],
    questions,
    audio: {
      correct_audio: ['audio/sfx_correct_answer_ding'],
      incorrect_audio: ['audio/sfx_incorrect_bloop'],
    },
    images: { svgs, webps },
    animations: { jsons },
    videos: { mp4s: [...videos] },
    background: {
      main: '',
      topLeft: '',
      bottomRight: '',
    },
    videoType: VideoType.STREAMING,
    assetMap,
  }
}
