import { Asset, GameType } from 'src/constants/types'
import {
  correctAudioInput,
  incorrectAudioInput,
  introAudioInput,
  outroAudioInput,
} from 'src/gameSchemas/standard'
import { InputConfig, InputType } from 'src/gameSchemas/types'
import GameSchema from './gameSchema'
import { formatAssetForOutput, removeExtension } from './utils'

const getUnitNames = (formState: any) => {
  if (!formState.unit) {
    return []
  }

  const unitNames: { label: string; value: string }[] = []

  Object.values(formState.unit).forEach((unit: any, index: number) => {
    if (unit.image && unit.image[0]) {
      const unitImageName = formatUnitName(unit, index)
      unitNames.push({
        label: removeExtension(unit.image[0].name),
        value: unitImageName,
      })
    }
  })

  return unitNames
}

export const getSegmentDragDropFormReqs = (
  formState: Record<string, any>,
): Record<string, InputConfig> => ({
  background: {
    label: 'Background image',
    inputType: InputType.IMAGE,
    default: ['image/background-pattern-blue.svg'],
  },
  unit: {
    label: 'Unit',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      quantity: 2,
      maximum: 5,
      childConfig: {
        image: {
          label: 'Image',
          inputType: InputType.IMAGE,
        },
        audio: {
          label: 'Audio',
          inputType: InputType.AUDIO,
        },
      },
    },
  },
  questions: {
    label: 'Question',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      quantity: 1,
      maximum: 1,
      childConfig: {
        dropzones: {
          label: 'Dropzone',
          inputType: InputType.REPEATABLE_GROUP,
          repeatableInputConfig: {
            quantity: 2,
            maximum: 5,
            childConfig: {
              preselected: {
                label: 'Preselected',
                inputType: InputType.SELECT,
                options: [
                  { label: 'No', value: 'no' },
                  ...getUnitNames(formState),
                ],
                default: ['no'],
              },
              acceptedUnits: {
                label: 'Accepted unit',
                inputType: InputType.REPEATABLE_GROUP,
                repeatableInputConfig: {
                  quantity: 1,
                  maximum: 5,
                  childConfig: {
                    name: {
                      label: 'Unit',
                      inputType: InputType.SELECT,
                      options: getUnitNames(formState),
                    },
                  },
                },
              },
            },
          },
        },
        prompt: {
          label: 'Prompt Audio',
          inputType: InputType.AUDIO,
        },
        wordRevealAudio: {
          label: 'Word Reveal Audio',
          inputType: InputType.AUDIO,
        },
        endUnit: {
          label: 'End Unit',
          inputType: InputType.GROUP,
          groupInputConfig: {
            childConfig: {
              image: { label: 'Image', inputType: InputType.ASSET },
              audio: { label: 'Audio', inputType: InputType.AUDIO },
            },
          },
        },
      },
    },
  },

  intro_audio: introAudioInput,
  outro_audio: outroAudioInput,
  correct_audio: correctAudioInput,
  incorrect_audio: incorrectAudioInput,
})

interface SegmentDragDropDropZone {
  preselected: string | undefined
  acceptedUnits: Record<string, { name: string }>
}

export interface SegmentDragDropUnit {
  image: Asset[]
  audio: Asset[]
}
export interface SegmentDragDropQuestion {
  prompt: Asset[]
  dropzones: Record<string, SegmentDragDropDropZone>
  wordRevealAudio: Asset[]
  endUnit: {
    endUnit1: {
      image: Asset[]
      audio: Asset[]
    }
  }
}

export interface SegmentDragDropFormState {
  background: Asset[]
  // eslint-disable-next-line camelcase
  intro_audio: Asset[]
  // eslint-disable-next-line camelcase
  outro_audio: Asset[]
  // eslint-disable-next-line camelcase
  correct_audio: Asset[]
  // eslint-disable-next-line camelcase
  incorrect_audio: Asset[]
  unit: Record<string, SegmentDragDropUnit>
  questions: Record<string, SegmentDragDropQuestion>
}

const formatUnitName = (unit: SegmentDragDropUnit, index: number) =>
  `unit${index + 1}_${removeExtension(unit.image[0].name)}`

export const generateSegmentDragDropJson = (
  formState: SegmentDragDropFormState,
) => {
  const units = Object.values(formState.unit).map(
    (unit: SegmentDragDropUnit, index: number) => {
      /* Create a unit name which is unique for this set of units
      (this allows the same asset to be used multiple times if the teacher so wishes) */
      const unitName = formatUnitName(unit, index)

      const unitAsset = formatAssetForOutput(unit.image[0], unitName)

      const audio = `unit_audio_${index + 1}`

      return { ...unitAsset, audio }
    },
  )

  const questions = Object.values(formState.questions).map(
    (question: SegmentDragDropQuestion, index) => {
      const prompt = `prompt_audio_${index + 1}`

      const wordRevealAudio = `word_reveal_audio_${index + 1}`

      const dropzones = Object.values(question.dropzones).map(
        (dropzone: SegmentDragDropDropZone) => {
          const acceptedUnits = Object.values(dropzone.acceptedUnits)

          return {
            accepted: acceptedUnits,
            itemName:
              dropzone.preselected !== 'no' ? dropzone.preselected : undefined,
          }
        },
      )

      const endUnitAsset = formatAssetForOutput(
        question.endUnit.endUnit1.image[0],
      )

      const endUnitAudio = `end_unit_audio_${index + 1}`

      return {
        prompt,
        wordRevealAudio,
        dropzones,
        endUnit: {
          ...endUnitAsset,
          audio: endUnitAudio,
        },
      }
    },
  )

  const gameSchema = new GameSchema(GameType.SEGMENT_DRAG_DROP, formState)

  gameSchema.addAudioFromString('drop_audio', ['audio/sfx_drop'])
  gameSchema.addAudioFromString('confetti_burst_audio', ['audio/sfx_firework'])

  Object.values(formState.unit).forEach((unit: SegmentDragDropUnit, index) => {
    gameSchema.addAsset(unit.image[0])

    gameSchema.addAudioFromAssets(`unit_audio_${index + 1}`, unit.audio)
  })

  Object.values(formState.questions).forEach((question, index) => {
    gameSchema.addAudioFromAssets(`prompt_audio_${index + 1}`, question.prompt)

    gameSchema.addAudioFromAssets(
      `word_reveal_audio_${index + 1}`,
      question.wordRevealAudio,
    )

    gameSchema.addAsset(question.endUnit.endUnit1.image[0])

    gameSchema.addAudioFromAssets(
      `end_unit_audio_${index + 1}`,
      question.endUnit.endUnit1.audio,
    )
  })

  return gameSchema.generate({
    units,
    questions,
  })
}
