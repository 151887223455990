export enum InputType {
  TEXT_INPUT = 'TEXT_INPUT',
  NUMBER = 'NUMBER',
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  ASSET = 'ASSET',
  SKILL_MEASUREMENTS = 'SKILL_MEASUREMENTS',
  REPEATABLE_GROUP = 'REPEATABLE_GROUP',
  SELECT = 'SELECT',
  GROUP = 'GROUP',
}

export enum LingumiCharacters {
  BOO = 'Boo',
  PEEK = 'Peek',
  HUSH = 'Hush',
  YUM = 'Yum',
}

interface GroupInputConfig {
  childConfig: Record<string, InputConfig>
}

interface RepeatableGroupInputConfig extends GroupInputConfig {
  quantity: number
  maximum: number
}

export interface InputConfig {
  label: string
  description?: string
  inputType: InputType
  default?: string[]
  groupInputConfig?: GroupInputConfig
  repeatableInputConfig?: RepeatableGroupInputConfig
  options?:
    | { label: string; value: string }[]
    | ((repeatableGroupIndices: number[]) => { label: string; value: string }[])
  isOptional?: boolean
}

export type FormReqs = Record<string, InputConfig>
