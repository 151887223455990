import { assetFileType, AssetType, GameType } from 'src/constants/types'
import { generateStandardJson, standardFormReqs } from './standard'
import { InputType, LingumiCharacters } from './types'
import {
  formatAssetsToStrings,
  formatQuestionAudioFiles,
  formatQuestionUnitsWithAudio,
} from './utils'

export const getCatchDragLabelFormReqs = {
  ...standardFormReqs,
  timer: {
    label: 'Timer (seconds)',
    inputType: InputType.NUMBER,
    default: ['30'],
  },
  character: {
    label: 'Character',
    inputType: InputType.SELECT,
    options: [
      { label: LingumiCharacters.BOO, value: LingumiCharacters.BOO },
      { label: LingumiCharacters.PEEK, value: LingumiCharacters.PEEK },
      { label: LingumiCharacters.YUM, value: LingumiCharacters.YUM },
      { label: LingumiCharacters.HUSH, value: LingumiCharacters.HUSH },
    ],
  },
  questions: {
    label: 'Unit to be caught',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      quantity: 1,
      maximum: 1,
      childConfig: {
        unit: {
          label: 'Unit',
          inputType: InputType.IMAGE,
        },
        promptAudio: {
          label: 'Prompt Audio',
          inputType: InputType.AUDIO,
        },
        unitAudio: {
          label: 'Unit Audio',
          inputType: InputType.AUDIO,
        },
      },
    },
  },
}

export const generateCatchDragLabelGenerateJson = (
  formState: Record<any, any>,
) => {
  const standardConfig = generateStandardJson(formState)

  const questions = formatQuestionUnitsWithAudio(formState.questions)

  const unitSvgs = Object.values(formState.questions)
    .map((question: any) => question.unit)
    .map(formatAssetsToStrings)
    .flat()

  const questionAudioFiles = formatQuestionAudioFiles(formState.questions)

  const character: { idleState: { image: string; type: string } } = {
    idleState: {
      image: `animation/anim_${formState.character.toLowerCase()}_idle_holding_basket`,
      type: assetFileType[AssetType.ANIMATION],
    },
  }

  const jsons = Object.values(character).map((c) => c.image)

  return {
    gameType: GameType.CATCH_DRAG_LABEL,
    ...standardConfig,
    timer: Number(formState.timer),
    questions,
    character,
    audio: {
      ...standardConfig.audio,
      ...questionAudioFiles,
    },
    images: {
      svgs: [...unitSvgs, ...standardConfig.images.svgs],
    },
    animations: {
      jsons,
    },
  }
}
