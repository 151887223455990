import _ from 'lodash'
import { Asset, AssetType, IAsset } from 'src/constants/types'
import { FormReqs, InputType } from './types'

export const removeExtension = (image: string) =>
  image.slice(0, image.lastIndexOf('.'))

export const getExtension = (image: string) =>
  image.slice(image.lastIndexOf('.') + 1)

export const combineSearchTerms = (assets: Asset[]) =>
  assets.map((a: Asset) => a.searchTerm).join(' ')

export const formatAssetToString = (asset: IAsset) =>
  `${asset.type}/${removeExtension(asset.name)}`

export const formatAssetsToStrings = (assets: Asset[]) =>
  assets.map((asset) => formatAssetToString(asset))

export const formatAssetForOutput = (asset: Asset, name?: string) => ({
  name: name || removeExtension(asset.name),
  image: formatAssetToString(asset),
  type: getExtension(asset.name),
})

export const formatQuestionUnitsWithAudio = (questions: any) =>
  Object.values(questions).map((question: any) => {
    const units = question.unit.map((u: any, index: number) => {
      const unit = formatAssetForOutput(u)
      return {
        ...unit,
        prompt: `prompt${index + 1}Audio`,
        audio: `unit${index + 1}Audio`,
      }
    })
    return { units }
  })

export const formatQuestionAudioFiles = (questions: any) => {
  const questionAudioFiles: Record<string, string[]> = {}

  Object.values(questions)
    .map((question: any) => question.unitAudio)
    .map((audio: any) =>
      audio.forEach((a: any, index: number) => {
        questionAudioFiles[`unit${index + 1}Audio`] =
          formatAssetsToStrings(audio)
      }),
    )

  Object.values(questions)
    .map((question: any) => question.promptAudio)
    .map((audio: any) =>
      audio.forEach((a: any, index: number) => {
        questionAudioFiles[`prompt${index + 1}Audio`] =
          formatAssetsToStrings(audio)
      }),
    )

  return questionAudioFiles
}

export const partitionImageTypes = (images: Asset[]) =>
  _.partition(
    images.map((image) => formatAssetToString(image)),
    (imageString: string) => imageString.includes('image/'),
  )

export const partitionImageTypesIncludingWebp = (assets: Asset[]) => {
  const svgAssets = assets.filter((asset: Asset) =>
    asset.name.toLowerCase().includes('svg'),
  )

  const jsonAssets = assets.filter((asset: Asset) =>
    asset.name.includes('json'),
  )

  const mp4Assets = assets.filter((asset: Asset) => asset.name.includes('mp4'))

  const webpAssets = assets.filter((asset: Asset) =>
    asset.name.includes('webp'),
  )
  return [svgAssets, jsonAssets, mp4Assets, webpAssets]
}

export const removeSpacesAndLowercase = (s: string) =>
  s.trim().toLowerCase().replace(/_/g, '_')

export const getTypeFromString = (name: string) => {
  if (name.includes('animation/')) {
    return AssetType.ANIMATION
  }
  if (name.includes('image/')) {
    return AssetType.IMAGE
  }
  if (name.includes('audio/')) {
    return AssetType.AUDIO
  }
  if (name.includes('video/')) {
    return AssetType.VIDEO
  }
  throw new Error('Unexpected default string')
}

/** takes a string like 'image/dog.svg' and creates an asset object from it */
export const getAssetFromString = (s: string) => {
  const type = getTypeFromString(s)
  return {
    name: s.slice(s.indexOf('/') + 1),
    type,
    tags: [],
    url: `https://ams.lingumi.com/${s}`,
    searchTerm: '',
  }
}

/** takes an object with values for a form, and replaces the strings with asset objects */
export const hydrateFormState = (formStateValues: Object): Object => {
  const entries = Object.entries(formStateValues)
  return entries.reduce((acc, entry) => {
    const [key, value] = entry
    if (Array.isArray(value)) {
      return {
        ...acc,
        [key]: value.map((s: string) => getAssetFromString(s)),
      }
    }
    if (typeof value === 'object') {
      return {
        ...acc,
        [key]: hydrateFormState(value),
      }
    }
    return acc
  }, {})
}

/** takes a set of form reqs and returns an initial formstate with
 * 1) all defaults populated
 * 2) nested repeatable groups populated
 */
export const getInitialFormState = (formReqs: Object): Object => {
  const entries = Object.entries(formReqs)
  return entries.reduce((acc, entry) => {
    const [key, value] = entry

    if (value.inputType === InputType.NUMBER) {
      return { ...acc, [key]: value.default[0] }
    }

    if (Array.isArray(value.default)) {
      if (value.inputType === InputType.SELECT) {
        return { ...acc, [key]: value.default[0] }
      }

      return {
        ...acc,
        [key]: value.default.map((s: string) => getAssetFromString(s)),
      }
    }

    if (value.inputType === InputType.GROUP) {
      const groupFormState = getInitialFormState(
        value.groupInputConfig.childConfig,
      )
      const newState = {
        ...acc,
        [key]: {
          [`${key}1`]: groupFormState,
        },
      }
      return newState
    }
    if (value.inputType === InputType.REPEATABLE_GROUP) {
      const { quantity } = value.repeatableInputConfig
      const initialLocalState = _.range(1, quantity + 1).reduce((acc, i) => {
        const repeatableGroupState = getInitialFormState(
          value.repeatableInputConfig.childConfig,
        )
        return {
          ...acc,
          [`${key}${i}`]: repeatableGroupState,
        }
      }, {})
      const newState = {
        ...acc,
        [key]: initialLocalState,
      }
      return newState
    }
    return acc
  }, {})
}

export const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  // @ts-ignore
  const params = Object.fromEntries(urlSearchParams.entries())
  return params
}

export const isFormComplete = (
  formReqs: FormReqs,
  formState: Record<string, any>,
): boolean =>
  Object.keys(formReqs).every(
    (key) => Boolean(formState[key]) || Boolean(formReqs[key].isOptional),
  )
