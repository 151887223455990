import { Asset, AssetType, IAsset, GameType } from 'src/constants/types'
import { formatAssetsToStrings, formatAssetToString } from './utils'

interface GameSchemaFormState {
  background?: Asset[]
  // eslint-disable-next-line camelcase
  intro_audio?: Asset[]
  // eslint-disable-next-line camelcase
  outro_audio?: Asset[]
  // eslint-disable-next-line camelcase
  correct_audio?: Asset[]
  // eslint-disable-next-line camelcase
  incorrect_audio?: Asset[]
}
class GameSchema {
  gameType: GameType
  assets: IAsset[]
  audioAssets: Record<string, string[]>
  backgroundAsset: IAsset | undefined

  constructor(gameType: GameType, formState?: GameSchemaFormState) {
    this.gameType = gameType
    this.assets = []
    this.audioAssets = {}

    if (formState) {
      if (formState.background) {
        this.addBackgroundAsset(formState.background[0])
      }

      if (formState.intro_audio) {
        this.addAudioFromAssets('intro_audio', formState.intro_audio)
      }

      if (formState.outro_audio) {
        this.addAudioFromAssets('outro_audio', formState.outro_audio)
      }
      if (formState.correct_audio) {
        this.addAudioFromAssets('correct_audio', formState.correct_audio)
      }

      if (formState.incorrect_audio) {
        this.addAudioFromAssets('incorrect_audio', formState.incorrect_audio)
      }
    }
  }

  addBackgroundAsset = (asset: Asset) => {
    this.backgroundAsset = asset
    this.addAsset(asset)
  }

  addAsset = (asset: IAsset) => {
    this.assets.push(asset)
  }

  addAudioFromAssets = (key: string, assets: Asset[]) => {
    this.audioAssets[key] = formatAssetsToStrings(assets)
  }

  addAudioFromString = (key: string, audio: string[]) => {
    this.audioAssets[key] = audio
  }

  generate = (extraProperties?: Record<string, any>) => {
    const svgs: string[] = []
    const jsons: string[] = []
    const webps: string[] = []
    const mp4s: string[] = []

    this.assets.forEach((asset) => {
      const formattedName = formatAssetToString(asset)
      if (
        asset.type === AssetType.IMAGE &&
        asset.name.toLowerCase().endsWith('.svg')
      ) {
        svgs.push(formattedName)
      }

      if (
        asset.type === AssetType.IMAGE &&
        asset.name.toLowerCase().endsWith('.webp')
      ) {
        webps.push(formattedName)
      }

      if (
        asset.type === AssetType.ANIMATION &&
        asset.name.toLowerCase().endsWith('.json')
      ) {
        jsons.push(formattedName)
      }

      if (
        asset.type === AssetType.VIDEO &&
        asset.name.toLowerCase().endsWith('.mp4')
      ) {
        mp4s.push(formattedName)
      }
    })

    const assets: {
      images?: { svgs?: string[]; webps?: string[] }
      animations?: { jsons: string[] }
      videos?: { mp4s: string[] }
    } = {}

    if (svgs.length > 0) {
      assets.images = { svgs }
    }

    if (webps.length > 0) {
      assets.images = { ...assets.images, webps }
    }

    if (jsons.length > 0) {
      assets.animations = { jsons }
    }

    if (mp4s.length > 0) {
      assets.videos = { mp4s }
    }

    const background = this.backgroundAsset
      ? { background: { main: formatAssetToString(this.backgroundAsset) } }
      : {}

    const audio =
      Object.keys(this.audioAssets).length > 0
        ? {
            audio: {
              ...this.audioAssets,
            },
          }
        : {}

    return {
      gameType: this.gameType,
      hasReward: false,
      autoScaleJsonAssets: true,
      ...background,
      ...assets,
      ...audio,
      ...extraProperties,
    }
  }
}

export default GameSchema
