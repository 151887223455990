import React from 'react'
import {
  LessonOverviewMap,
  LessonOverviewAssets,
  LessonOverviewAssetType,
} from 'src/constants/types'

export type LessonConfigContextType = {
  words: LessonOverviewMap
  sentences: LessonOverviewMap
  onSetConfig: (assets: LessonOverviewAssets, index: number) => void
  addAnotherAsset: (type: LessonOverviewAssetType) => void
}

export const LessonConfigContext =
  React.createContext<LessonConfigContextType | null>(null)

const intitalWordState = {
  type: LessonOverviewAssetType.WORD,
  text: '',
  audioAssets: [],
  imageAsset: [],
}

const initialSentenceState = {
  type: LessonOverviewAssetType.SENTENCE,
  text: '',
  audioAssets: [],
}

export default ({ children }: React.PropsWithChildren<any>) => {
  const [words, setWords] = React.useState<LessonOverviewMap>({
    0: intitalWordState,
  })
  const [sentences, setSentences] = React.useState<LessonOverviewMap>({
    0: initialSentenceState,
  })

  const onSetConfig = (assets: LessonOverviewAssets, index: number) => {
    switch (assets.type) {
      case LessonOverviewAssetType.SENTENCE:
        setSentences({ ...sentences, [index]: assets })
        break
      case LessonOverviewAssetType.WORD:
        setWords({ ...words, [index]: assets })
        break
      default:
        break
    }
  }

  const addAnotherAsset = (type: LessonOverviewAssetType) => {
    let nextIndex: number
    switch (type) {
      case LessonOverviewAssetType.SENTENCE:
        nextIndex = Object.keys(sentences).length + 1

        setSentences({
          ...sentences,
          ...{ [nextIndex]: initialSentenceState },
        })
        break
      case LessonOverviewAssetType.WORD:
        nextIndex = Object.keys(words).length + 1

        setWords({ ...words, ...{ [nextIndex]: intitalWordState } })
        break
      default:
        break
    }
  }

  const store: LessonConfigContextType = {
    words,
    sentences,
    addAnotherAsset,
    onSetConfig,
  }

  return (
    <LessonConfigContext.Provider value={store}>
      {children}
    </LessonConfigContext.Provider>
  )
}
