import React, { FormEvent, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import JSONPretty from 'react-json-pretty'

import {
  loadVehiclesFormReqs,
  loadVehiclesGenerateJson,
} from 'src/gameSchemas/loadVehicles'
import {
  tapRevealFormReqs,
  tapRevealGenerateJson,
} from 'src/gameSchemas/tapReveal'
import {
  tapCheckCircleChoicesFormReqs,
  tapCheckCircleChoicesGenerateJson,
} from 'src/gameSchemas/tapCheckCircleChoices'
import {
  labelTransformFormReqs,
  labelTransformGenerateJson,
} from 'src/gameSchemas/labelTransform'
import {
  tapVideoFormReqs,
  tapVideoGenerateJson,
} from 'src/gameSchemas/tapVideo'
import {
  getSmartConversationFormReqs,
  smartConversationGenerateJson,
} from 'src/gameSchemas/smartConversationLabel'
import {
  getThisOrThatFormReqs,
  thisOrThatCheckGenerateJson,
} from 'src/gameSchemas/thisOrThatCheck'
import { getQueryParams } from 'src/gameSchemas/utils'
import {
  getTapSpeakFormRegs,
  tapSpeakGenerateJson,
} from 'src/gameSchemas/tapSpeak'
import {
  generateJsonVideoPracticeCardFlip,
  videoPracticeCardFlipFormReqs,
} from 'src/gameSchemas/videoPracticeCardFlip'
import {
  cardFlipDragCheckFormReqs,
  generateJsonCardFlipDragCheck,
} from 'src/gameSchemas/cardFlipDragCheck'
import {
  generatePronunciationScoreSpeakJson,
  getPronunciationScoreSpeakFormReqs,
} from 'src/gameSchemas/pronunciationScoreSpeak'
import {
  getCatchDragLabelFormReqs,
  generateCatchDragLabelGenerateJson,
} from 'src/gameSchemas/catchDragLabel'
import {
  generateVideoDuetJson,
  videoDuetFormReqs,
} from 'src/gameSchemas/videoDuet'
import {
  generateWhackAMoleJson,
  whackAMoleFormReqs,
} from 'src/gameSchemas/whackAMole'
import {
  dragDropInteractiveStoryFormReqs,
  generateDragDropInteractiveStoryJson,
} from 'src/gameSchemas/dragDropInteractiveStory'
import {
  generateZipperPhonicsJson,
  zipperPhonicsFormReqs,
} from 'src/gameSchemas/zipperPhonics'
import {
  generateSegmentDragDropJson,
  getSegmentDragDropFormReqs,
} from 'src/gameSchemas/segmentDragDrop'
import {
  generateTracingPhonicsJson,
  tracingPhonicsFormReqs,
} from 'src/gameSchemas/tracingPhonics'
import {
  getCrossTheBridgeFormReqs,
  generateCrossTheBridgeJson,
} from 'src/gameSchemas/crossTheBridge'
import ContextProvider, {
  RvgConfigContext,
  RvgConfigContextType,
} from './context'
import {
  ActivityType,
  GameSetup,
  GameType,
  UnitMap,
} from '../../constants/types'
import { generateRVGJson } from '../utils/helpers'
import GameSetupForm from './GameSetupForm'
import Heading from '../../components/Heading'
import { TitledImagePreview } from '../previews/TitledImagePreview'
import { TitledAudioPlayer } from '../previews/TitledAudioPlayer'
import VideoForm from './VideoForm'
import CreateGameForm from '../create-game-2/CreateGameForm'

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`

const StyledButton = styled(Button)`
  display: block;
  margin-bottom: 8px;
`

const GameSetupPreview = ({ gameSetup }: { gameSetup: GameSetup | null }) => {
  if (!gameSetup) {
    return null
  }

  const audios = [
    { title: 'Intro sound', asset: gameSetup.introSound },
    { title: 'Outro sound', asset: gameSetup.outroSound },
    { title: 'Accept sound', asset: gameSetup.acceptSound },
    { title: 'Reject sound', asset: gameSetup.rejectSound },
  ]

  return (
    <>
      <StyledRow>
        <Col>
          <TitledImagePreview
            title="Canvas"
            asset={gameSetup.gameCanvas}
            height={140}
            width={300}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          <TitledImagePreview title="Top left" asset={gameSetup.topLeftImage} />
        </Col>
        <Col>
          <TitledImagePreview
            title="Bottom right"
            asset={gameSetup.bottomRightImage}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          {audios.map((audio) => (
            <TitledAudioPlayer
              title={audio.title}
              assets={[audio.asset]}
              key={audio.title}
            />
          ))}
        </Col>
      </StyledRow>
    </>
  )
}

const GameForm = () => {
  const { units, gameSetup, setGameSetup } = useContext(
    RvgConfigContext,
  ) as RvgConfigContextType
  const [json, setJson] = useState<object | null>(null)
  useEffect(() => {
    const params = getQueryParams()
    if (params.gameType) {
      setGameSetup((gameSetup) => ({
        ...gameSetup,
        gameType: params.gameType,
      }))
    }
  }, [])

  const handleSubmit = async (e: FormEvent, units: UnitMap) => {
    e.preventDefault()
    setJson(generateRVGJson(units, gameSetup))
  }

  if (gameSetup?.gameType === GameType.LABEL_TRANSFORM) {
    return (
      <CreateGameForm
        formReqs={labelTransformFormReqs}
        generateJson={labelTransformGenerateJson}
      />
    )
  }
  if (gameSetup?.gameType === GameType.LOAD_VEHICLES) {
    return (
      <CreateGameForm
        formReqs={loadVehiclesFormReqs}
        generateJson={loadVehiclesGenerateJson}
      />
    )
  }
  if (gameSetup?.gameType === GameType.TAP_REVEAL) {
    return (
      <CreateGameForm
        formReqs={tapRevealFormReqs}
        generateJson={tapRevealGenerateJson}
      />
    )
  }
  if (gameSetup?.gameType === GameType.TAP_VIDEO) {
    return (
      <>
        <CreateGameForm
          formReqs={tapVideoFormReqs}
          generateJson={tapVideoGenerateJson}
        />
      </>
    )
  }
  if (gameSetup?.gameType === GameType.CIRCLE_CHOICES) {
    return (
      <CreateGameForm
        formReqs={tapCheckCircleChoicesFormReqs}
        generateJson={tapCheckCircleChoicesGenerateJson}
      />
    )
  }

  if (gameSetup?.gameType === GameType.SMART_CONVERSATION) {
    return (
      <CreateGameForm
        formReqs={{}}
        getFormReqs={getSmartConversationFormReqs}
        generateJson={smartConversationGenerateJson}
      />
    )
  }
  if (gameSetup?.gameType === GameType.TAP_SPEAK) {
    return (
      <>
        <CreateGameForm
          formReqs={{}}
          getFormReqs={getTapSpeakFormRegs}
          generateJson={tapSpeakGenerateJson}
        />
      </>
    )
  }

  if (gameSetup?.gameType === GameType.THIS_OR_THAT_DRAG_CHECK) {
    return (
      <CreateGameForm
        formReqs={{}}
        getFormReqs={getThisOrThatFormReqs}
        generateJson={thisOrThatCheckGenerateJson}
      />
    )
  }

  if (gameSetup?.gameType === GameType.CARD_FLIP_VIDEO_CHECK) {
    return (
      <CreateGameForm
        formReqs={videoPracticeCardFlipFormReqs}
        generateJson={generateJsonVideoPracticeCardFlip}
      />
    )
  }

  if (gameSetup?.gameType === GameType.CARD_FLIP_DRAG_CHECK) {
    return (
      <CreateGameForm
        formReqs={cardFlipDragCheckFormReqs}
        generateJson={generateJsonCardFlipDragCheck}
      />
    )
  }

  if (gameSetup?.gameType === GameType.PRONUNCIATION_SCORE_SPEAK) {
    return (
      <CreateGameForm
        formReqs={getPronunciationScoreSpeakFormReqs}
        generateJson={generatePronunciationScoreSpeakJson}
      />
    )
  }

  if (gameSetup?.gameType === GameType.CATCH_DRAG_LABEL) {
    return (
      <CreateGameForm
        formReqs={getCatchDragLabelFormReqs}
        generateJson={generateCatchDragLabelGenerateJson}
      />
    )
  }

  if (gameSetup?.gameType === GameType.VIDEO_DUET) {
    return (
      <CreateGameForm
        formReqs={videoDuetFormReqs}
        generateJson={generateVideoDuetJson}
      />
    )
  }

  if (gameSetup?.gameType === GameType.WHACK_A_MOLE) {
    return (
      <CreateGameForm
        formReqs={whackAMoleFormReqs}
        generateJson={generateWhackAMoleJson}
      />
    )
  }

  if (gameSetup?.gameType === GameType.INTERACTIVE_STORY_DRAG) {
    return (
      <CreateGameForm
        formReqs={dragDropInteractiveStoryFormReqs}
        generateJson={generateDragDropInteractiveStoryJson}
      />
    )
  }

  if (gameSetup?.gameType === GameType.ZIPPER_PHONICS) {
    return (
      <CreateGameForm
        formReqs={zipperPhonicsFormReqs}
        generateJson={generateZipperPhonicsJson}
      />
    )
  }

  if (gameSetup?.gameType === GameType.SEGMENT_DRAG_DROP) {
    return (
      <CreateGameForm
        formReqs={{}}
        getFormReqs={getSegmentDragDropFormReqs}
        generateJson={generateSegmentDragDropJson}
      />
    )
  }

  if (gameSetup?.gameType === GameType.TRACING_PHONICS) {
    return (
      <CreateGameForm
        formReqs={tracingPhonicsFormReqs}
        generateJson={generateTracingPhonicsJson}
      />
    )
  }

  if (gameSetup?.gameType === GameType.CROSS_THE_BRIDGE) {
    return (
      <CreateGameForm
        formReqs={{}}
        getFormReqs={getCrossTheBridgeFormReqs}
        generateJson={generateCrossTheBridgeJson}
      />
    )
  }

  return (
    <Form onSubmit={(e: any) => handleSubmit(e, units)}>
      <Row>
        <Col>
          <GameSetupForm />
        </Col>
        <Col>
          <GameSetupPreview gameSetup={gameSetup} />
        </Col>
      </Row>
      <StyledButton type="submit" disabled={!units[1].image.length}>
        Generate Config
      </StyledButton>
      <JSONPretty id="json-pretty" data={json} />
    </Form>
  )
}

const CreateGame = () => {
  const [activityType, setActivityType] = useState<ActivityType>(
    ActivityType.RVG,
  )
  const formattedActivityType =
    activityType === ActivityType.RVG ? 'RVG' : 'Video'
  return (
    <ContextProvider>
      <Container>
        <Heading as="h1">{formattedActivityType} Creator</Heading>
        <StyledButton
          onClick={() =>
            setActivityType(
              activityType === ActivityType.RVG
                ? ActivityType.VIDEO
                : ActivityType.RVG,
            )
          }
        >
          Switch to {activityType === ActivityType.RVG ? 'Video' : 'RVG'}
        </StyledButton>
        {activityType === ActivityType.RVG ? <GameForm /> : <VideoForm />}
      </Container>
    </ContextProvider>
  )
}

export default CreateGame
