/* eslint-disable comma-dangle */
import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { Asset, AssetType } from '../constants/types'
import { BASE_URL } from '../constants/env'
import { get } from '../api/api'
import columns from './AssetTable/columns'
import Table from './AssetTable'

const parseRawAssets = (assets: Array<any>): Array<Asset> =>
  assets.map(
    (asset: any): Asset => ({
      ...asset,
      createdAt: new Date(Date.parse(asset.createdAt)),
    }),
  )

export interface FetchData {
  (
    pageSize: number,
    pageIndex: number,
    searchTerm?: string,
    assetTypes?: Array<AssetType>,
  ): Promise<void>
}

const Assets = () => {
  const columnDefinitions = React.useMemo(() => columns, [])
  const [data, setData] = useState<Asset[]>([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)

  const { getAccessTokenSilently } = useAuth0()

  const fetchData: FetchData = async (
    pageSize,
    pageIndex,
    searchTerm = '',
    assetTypes = [],
  ) => {
    const token = await getAccessTokenSilently()

    setLoading(true)

    const searchUrl = new URL('/assets', BASE_URL)

    searchUrl.searchParams.set('page_size', pageSize.toString())
    searchUrl.searchParams.set('page_number', pageIndex.toString())

    if (searchTerm) {
      searchUrl.searchParams.set('search_term', searchTerm)
    }

    if (assetTypes) {
      assetTypes.forEach((type: AssetType) =>
        searchUrl.searchParams.append('asset_type', type),
      )
    }

    const response = await get(token, searchUrl.toString())

    if (!response || !response.assets) {
      setLoading(false)
      return
    }

    const assets = parseRawAssets(response.assets)

    setData(assets)
    setPageCount(response.pageCount)
    setLoading(false)
  }

  return (
    <Table
      columns={columnDefinitions}
      data={data}
      fetchData={fetchData}
      loading={loading}
      controlledPageCount={pageCount}
    />
  )
}

export default Assets
