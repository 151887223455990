import { assetFileType, AssetType, GameType } from 'src/constants/types'
import { generateStandardJson, standardFormReqs } from './standard'
import { FormReqs, InputType } from './types'
import {
  formatAssetsToStrings,
  formatQuestionAudioFiles,
  formatQuestionUnitsWithAudio,
} from './utils'

export const whackAMoleFormReqs: FormReqs = {
  ...standardFormReqs,
  timer: {
    label: 'Timer (seconds)',
    inputType: InputType.NUMBER,
    default: ['30'],
  },
  questions: {
    label: 'Unit',
    inputType: InputType.REPEATABLE_GROUP,
    repeatableInputConfig: {
      quantity: 1,
      maximum: 1,
      childConfig: {
        unit: {
          label: 'Unit',
          inputType: InputType.IMAGE,
        },
        promptAudio: {
          label: 'Prompt Audio',
          inputType: InputType.AUDIO,
        },
        unitAudio: {
          label: 'Unit Audio',
          inputType: InputType.AUDIO,
        },
      },
    },
  },
}

export const generateWhackAMoleJson = (formState: Record<any, any>) => {
  const standardConfig = generateStandardJson(formState)
  const unitSvgs = Object.values(formState.questions)
    .map((question: any) => question.unit)
    .map(formatAssetsToStrings)
    .flat()
  const questionUnits = formatQuestionUnitsWithAudio(formState.questions)
  const questionAudioFiles = formatQuestionAudioFiles(formState.questions)

  return {
    gameType: GameType.WHACK_A_MOLE,
    ...standardConfig,
    timer: Number(formState.timer),
    questions: questionUnits,
    characters: [
      {
        idleState: {
          image: 'animation/anim_yum_idle_with_helmet',
          type: assetFileType[AssetType.ANIMATION],
        },
        tappedState: {
          image: 'animation/anim_yum_eyes_closed_with_helmet',
          type: assetFileType[AssetType.ANIMATION],
        },
      },
      {
        idleState: {
          image: 'animation/anim_boo_idle_with_helmet',
          type: assetFileType[AssetType.ANIMATION],
        },
        tappedState: {
          image: 'animation/anim_boo_eyes_closed_with_helmet',
          type: assetFileType[AssetType.ANIMATION],
        },
      },
      {
        idleState: {
          image: 'animation/anim_hush_idle_with_helmet',
          type: assetFileType[AssetType.ANIMATION],
        },
        tappedState: {
          image: 'animation/anim_hush_eyes_closed_with_helmet',
          type: assetFileType[AssetType.ANIMATION],
        },
      },
      {
        idleState: {
          image: 'animation/anim_peek_idle_with_helmet',
          type: assetFileType[AssetType.ANIMATION],
        },
        tappedState: {
          image: 'animation/anim_peek_eyes_closed_with_helmet',
          type: assetFileType[AssetType.ANIMATION],
        },
      },
    ],
    audio: {
      ...standardConfig.audio,
      ...questionAudioFiles,
    },
    images: {
      svgs: [...unitSvgs, ...standardConfig.images.svgs],
    },
    animations: {
      jsons: [
        'animation/anim_yum_idle_with_helmet',
        'animation/anim_boo_idle_with_helmet',
        'animation/anim_hush_idle_with_helmet',
        'animation/anim_peek_idle_with_helmet',
        'animation/anim_yum_eyes_closed_with_helmet',
        'animation/anim_boo_eyes_closed_with_helmet',
        'animation/anim_hush_eyes_closed_with_helmet',
        'animation/anim_peek_eyes_closed_with_helmet',
      ],
    },
  }
}
