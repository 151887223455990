import { Asset, AssetType } from 'src/constants/types'

export interface ValidationErrors {
  type?: string
  file?: string
  searchTerm?: string
  tags?: string
}

export const validateForm = (asset: Asset) => {
  const errors: ValidationErrors = {}
  if (!asset.file) {
    errors.file = 'Please upload a file'
  } else {
    let hasFileError = false
    switch (asset.type) {
      case AssetType.IMAGE:
        if (
          !asset.file?.type.includes('image/webp') &&
          !asset.file?.type.includes('image/svg')
        ) {
          hasFileError = true
        }
        break
      case AssetType.ANIMATION:
        if (!asset.file?.type.includes('application/json')) {
          hasFileError = true
        }
        break
      case AssetType.AUDIO:
        if (!asset.file?.type.includes('audio/mpeg')) {
          hasFileError = true
        }
        break
      case AssetType.VIDEO:
        if (!asset.file?.type.includes('video/mp4')) {
          hasFileError = true
        }
        break
      default:
        break
    }
    if (hasFileError) {
      errors.file = 'Please upload a valid file for this asset type'
    }
  }

  if (!asset.tags || asset.tags.length === 0) {
    errors.tags = 'Please add at least one tag'
  }

  if (!asset.searchTerm) {
    errors.searchTerm = 'Please add a search term'
  }

  return errors
}
